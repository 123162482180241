import React, { Component } from 'react';
import ReactWOW from 'react-wow';

import checkIcon from '../../assets/imgs/ui/check.svg';

import UiAlert from '../../components/ui/modals/UiAlert.js';
import UiLoader from '../../components/ui/modals/UiLoader';

import UiBtnGreen from '../../components/ui/buttons/UiBtnGreen.js';
import UiBtnGreenOutline from '../../components/ui/buttons/UiBtnGreenOutline.js';
import UiBtnPink from '../../components/ui/buttons/UiBtnPink.js';
import UiBtnPinkOutline from '../../components/ui/buttons/UiBtnPinkOutline.js';
import UiCheckList from '../../components/ui/forms/UiCheckList.js';
import UiDinnerCard from '../../components/ui/cards/UiDinnerCard';
import UiFormInput from '../../components/ui/forms/UiFormInput.js';
import UiFooter from '../../components/ui/footer/UiFooter.js';
import UiHeader from '../../components/ui/header/UiHeader.js';
import UiPlansTab from '../../components/ui/tabs/UiPlansTab.js';
import UiTabPlanCard from '../../components/ui/cards/UiTabPlanCard.js';
import UiTextArea from '../../components/ui/forms/UiTextArea.js';

import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import html2canvas from 'html2canvas';

import { en } from '../../i18n/en.js';
import { ru } from '../../i18n/ru.js';
import { ch } from '../../i18n/ch.js';
import { translateObject } from '../../i18n/locale.js';

import { formatDate, getDay, formatDateDdMm, getWeekTitleByNum, getWeekByNum, getFormatedCurrentDate, formatDateYMD } from '../../components/common/Date';
import { loadUser, saveUser, getOrdersUsers, changeDeliveryType, changePay } from '../../services/Users';
import { sendKitchen } from "../../services/Orders";
import { retrieveData } from '../../services/Storage';


class CourierOrdersPage extends Component {



  state = {
    addNewPlanShow: false,
    calorieNum1Active: false,
    calorieNum2Active: false,
    calorieNum3Active: false,
    calorieNum4Active: false,
    calorieNum5Active: false,

    tabIndex: 0,
    selectedPrintProgramIndex: 1,
    user: {
      type: 0
    },
    currentDate: new Date(),
    currentDate2: new Date(),
    excludeDaysArr: [],
    ordersList: [],
    ordersList2: [],
    sortedOrders: [],
    ordersApiList: [],
  }

  constructor(props) {
    super(props);
    let _lang = retrieveData("ikLocale");
    if (_lang == null) _lang = 'ch';
    if (_lang == 'ch') global.locale = ch;
    if (_lang == 'ru') global.locale = ru;
    if (_lang == 'en') global.locale = en;
    this.setState({ lang: _lang });



  }

  componentDidMount() {
    this._load();
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > 180) this.setState({ showMenuBar: true }); else this.setState({ showMenuBar: false });
    });
  }

  _hasChange(_arr, _id) {
    let f = null;
    _arr.map((item) => {
      if (item.order_id == _id) f = item;
    })
    return f;
  }

  _hasChangeDelivery(_arr) {
    let _date = formatDateYMD(this.state.currentDate);
    let f = null;
    _arr.map((item) => {
      if (item.day == _date) f = item;
    })
    return f;
  }


  _hasChangeRep(_arr, _id) {
    let f = null;
    _arr.map((item) => {
      if (item.aid == _id) f = item.item;
    })
  }

  _changeType(_order_id, _curier_id, _type) {
    let _msg = "";
    if (_type == 2) {
      _msg = " Dobrý den! Vaše objednávka od iKitchen je již na cestě. Doručíme Vám do 30 minut! 🙂\r\n \r\nS přátelskými pozdravy, ikitchen.cz\r\ntel.: +420 222 744 130\r\nemail: info@ikitchen.cz";
    } else if (_type == 3) {
      _msg = global.locale.сour_msg_2;
    }

    if (window.confirm("Вы уверены что хотите изменить статус выполнения?")) {
      changeDeliveryType(this.state.user.api_token, _order_id, _curier_id, formatDate(this.state.currentDate), _type, _msg).then((res) => {
        console.log(res);
        this._load();
      })
    }
  }

  _changePay(_order_id, _type) {


    if (window.confirm("Вы уверены что хотите изменить статус оплаты?")) {
      changePay(this.state.user.api_token, _order_id, _type).then((res) => {
        console.log(res);
        this._load();
      })
    }


  }


  _route(_deliv, _id, _item) {
    let dest = (this._hasChange(_deliv, _id) ? this._hasChange(_deliv, _id).city : _item.user.city
    ) + "," + (this._hasChange(_deliv, _id) ? this._hasChange(_deliv, _id).street : _item.user.street);
    window.open("https://www.google.com/maps/dir/Current+Location/" + dest, '_blank');
  }

  _sendToKitchen() {
    this.setState({ loader: true });
    sendKitchen(this.state.ordersApiList).then((val) => {
      this.setState({ loader: false });
      alert("Data sended successful");
      console.log(val)
    })
  }

  _printKitchenList(_val) {
    this.setState({ selectedPrintProgramIndex: _val, showPKL: true }, () => {

      html2canvas(document.getElementById("xlsTableList1"), { useCORS: true, allowTaint: true }).then(function (canvas) {
        var pdf = new jsPDF('l', 'mm', [297, 210]);
        var wid;
        var hgt;
        document.body.appendChild(canvas);
        var ctx = canvas.getContext('2d');
        var imgData = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
        var hratio = hgt / wid
        var width = pdf.internal.pageSize.width;
        var height = width * hratio;

        pdf.addImage(imgData, 'PNG', 5, 5, width - 5, height - 5);
        pdf.save('sample1.pdf');
      });
      html2canvas(document.getElementById("xlsTableList2"), { useCORS: true, allowTaint: true }).then(function (canvas) {
        var pdf = new jsPDF('l', 'mm', [297, 210]);
        var wid;
        var hgt;
        document.body.appendChild(canvas);
        var ctx = canvas.getContext('2d');
        var imgData = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
        var hratio = hgt / wid
        var width = pdf.internal.pageSize.width;
        var height = width * hratio;

        pdf.addImage(imgData, 'PNG', 5, 5, width - 5, height - 5);
        pdf.save('sample2.pdf');
      });
      html2canvas(document.getElementById("xlsTableList3"), { useCORS: true, allowTaint: true }).then(function (canvas) {
        var pdf = new jsPDF('l', 'mm', [297, 210]);
        var wid;
        var hgt;
        document.body.appendChild(canvas);
        var ctx = canvas.getContext('2d');
        var imgData = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
        var hratio = hgt / wid
        var width = pdf.internal.pageSize.width;
        var height = width * hratio;

        pdf.addImage(imgData, 'PNG', 5, 5, width - 5, height - 5);
        pdf.save('sample3.pdf');
      });
      html2canvas(document.getElementById("xlsTableList4"), { useCORS: true, allowTaint: true }).then(function (canvas) {
        var pdf = new jsPDF('l', 'mm', [297, 210]);
        var wid;
        var hgt;
        document.body.appendChild(canvas);
        var ctx = canvas.getContext('2d');
        var imgData = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
        var hratio = hgt / wid
        var width = pdf.internal.pageSize.width;
        var height = width * hratio;

        pdf.addImage(imgData, 'PNG', 5, 5, width - 5, height - 5);
        pdf.save('sample4.pdf');
      });


      setTimeout(() => this.setState({ showPKL: false }), 5000)
    })


  }

  _printKitchen(_val) {
    var progs = [
      "Balance",
      "Vegan",
      "Anti-age",
      "Low Carb"
    ];

    this.setState({ selectedPrintProgramIndex: _val, showXLS: true }, () => {

      setTimeout(() => {

        let table = "xlsTableMain", name = "xlsTableMain", filename = progs[_val - 1];
        let uri = 'data:application/vnd.ms-excel;base64,',
          template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><title></title><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
          base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }, format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }

        if (!table.nodeType) table = document.getElementById(table)
        var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML.replace(/<span>/g, '<span style="mso-rotate:90;">') }

        var link = document.createElement('a');
        link.download = filename;
        link.href = uri + base64(format(template, ctx));
        link.click();

        this.setState({ showXLS: false })
      }, 1000);


    })

    //new window.TableExport(document.getElementById("xlsTable"));


  }

  _print() {
    /*
     var str = "";
     var td = "";
     var i = 0;
     var ix = 0;
     this.state.ordersList.map((item, index) => {
       ix++;
       i++;
       td = td + '<td WIDTH="33%" height="100%"> <table style="border: 1px solid;  width: 63.5mm; font-size: 22px; height: 29.6mm; margin: 0; padding 0;"> <tr><td><b>'+ item.id +'</b> - '+item.program.title_ch+' </td></tr> <tr><td>'+ item.user.lname +' </td></tr> <tr><td>'+ item.user.street +' </td></tr> </table> </td>';
       td = td + '<td WIDTH="33%" height="100%"> <table style="border: 1px solid;  width: 63.5mm; font-size: 22px; height: 29.6mm;  margin: 0; padding 0;"> <tr><td><b>'+ item.id +'</b> - '+item.program.title_ch+' </td></tr> <tr><td>'+ item.user.lname +' </td></tr> <tr><td>'+ item.user.street +' </td></tr> </table> </td>';
       td = td + '<td WIDTH="33%" height="100%"> <table style="border: 1px solid;  width: 63.5mm; font-size: 22px; height: 29.6mm;  margin: 0; padding 0;"> <tr><td><b>'+ item.id +'</b> - '+item.program.title_ch+' </td></tr> <tr><td>'+ item.user.lname +' </td></tr> <tr><td>'+ item.user.street +' </td></tr> </table> </td>';
       str = str + '<tr>' + td + '</tr>'
       td = "";
 
     })
     var divContents = '<table  WIDTH="100%">'+str+'</table>' ;
     var printWindow = window.open('', '', 'height=400,width=800');
     printWindow.document.write('<html><head> <style> body {margin: 0; padding: 0;} </style><title>DIV Contents</title>');
     printWindow.document.write('</head><body >');
     printWindow.document.write(divContents);
     printWindow.document.write('</body></html>');
     printWindow.document.close();
     printWindow.print();*/

    var pdf = new jsPDF('p', 'mm', 'a4');
    // source can be HTML-formatted string, or a reference
    // to an actual DOM element from which the text will be scraped.

    pdf.addFont(require("../../assets/fonts/OpenSans-Regular.ttf"), "OpenSans", "normal");
    pdf.setFont("OpenSans"); // set font
    pdf.setFontSize(11);

    var arr = [];
    var arr2 = [];
    this.state.ordersList.map((item, index) => {
      /*
      arr.push([
        item.id + " - " + item.program.title_ch + ", " + item.calories.title_en + '\r\n' + item.user.lname + '\r\n' + item.user.street,
        item.id + " - " + item.program.title_ch + ", " + item.calories.title_en + '\r\n' + item.user.lname + '\r\n' + item.user.street,
        item.id + " - " + item.program.title_ch + ", " + item.calories.title_en + '\r\n' + item.user.lname + '\r\n' + item.user.street,
      ]);
      */
      arr.push([
        item.id + " - " + item.program.title_ch + ", " + item.calories.title_en,
        item.id + " - " + item.program.title_ch + ", " + item.calories.title_en,
        item.id + " - " + item.program.title_ch + ", " + item.calories.title_en,
      ]);
      arr2.push([
        '\r\n' + item.user.lname + '\r\n' + item.user.street,
        '\r\n' + item.user.lname + '\r\n' + item.user.street,
        '\r\n' + item.user.lname + '\r\n' + item.user.street]);
    })

    pdf.autoTable({

      theme: 'plain',
      fontSize: 11,
      margin: {
        top: 16,
        left: 7,
        right: 7,
        bottom: 0,
      },
      rowPageBreak: 'avoid',
      styles: {
        overflow: 'linebreak',
        lineColor: [0, 0, 0],
        lineWidth: 0,
        minCellWidth: 63.5,
        minCellHeight: 30,
        font: "OpenSans",
        cellPadding: { left: 5, top: 2, right: 5 },
        valign: 'middle'
      },
      body: arr2,
      didDrawCell: (data) => {
        if (data.section === 'body') {

          if (arr[data.row.index]) {
            pdf.setFont("OpenSans", "bold");
            pdf.text(arr[data.row.index][0], data.cell.x + 5, data.cell.y + 10)
            pdf.setFont("OpenSans", "normal");
          }

        }
      },
    })

    pdf.save('Tickets.pdf');

  }

  _downloadCSV(fileName, mimeType) {
    // Example data given in question text
    var data = [];

    this.state.ordersList.map((item, index) => {
      let arr = [];
      if (
        (this.state.tabIndex == 1 && item.deliviries_users.length > 0) ||
        (this.state.tabIndex == 0 && item.deliviries_users.length == 0)
      ) {

        arr.push(item.id);
        arr.push(item.current_date + " " + item.user.fttime);
        arr.push(item.user.city + " , " + item.user.street);
        arr.push(item.user.name + " " + item.user.lname);
        arr.push(item.user.comment);
        arr.push(item.list[0].dinner1 ? this._hasChangeRep(item.replacments, item.list[0].dinner1[0].id) ? this._hasChangeRep(item.replacments, item.list[0].dinner1[0].id).title_ru : (item.list[0].dinner1[0].title_ru) : null);
        arr.push(item.list[0].dinner2 ? this._hasChangeRep(item.replacments, item.list[0].dinner2[0].id) ? this._hasChangeRep(item.replacments, item.list[0].dinner2[0].id).title_ru : (item.list[0].dinner2[0].title_ru) : null);
        arr.push(item.list[0].dinner3 ? this._hasChangeRep(item.replacments, item.list[0].dinner3[0].id) ? this._hasChangeRep(item.replacments, item.list[0].dinner3[0].id).title_ru : (item.list[0].dinner3[0].title_ru) : null);
        arr.push(item.list[0].dinner4 ? this._hasChangeRep(item.replacments, item.list[0].dinner4[0].id) ? this._hasChangeRep(item.replacments, item.list[0].dinner4[0].id).title_ru : (item.list[0].dinner4[0].title_ru) : null);
        arr.push(item.list[0].dinner5 ? this._hasChangeRep(item.replacments, item.list[0].dinner5[0].id) ? this._hasChangeRep(item.replacments, item.list[0].dinner5[0].id).title_ru : (item.list[0].dinner5[0].title_ru) : null);
        arr.push(item.additionals ? item.additionals.length > 0 ? (item.additionals[0].item.title_ru + " +" + item.additionals[0].item.price + " р") : null : null);
        arr.push(item.price);
        arr.push(item.is_pay == 0 ? global.locale.сour_not_payed : global.locale.ord_payed);


        data.push(arr);
      }
    })

    // Building the CSV from the Data two-dimensional array
    // Each column is separated by ";" and new line "\n" for next row
    var content = '';
    data.forEach(function (infoArray, index) {
      var dataString = infoArray.join(';');
      content += index < data.length ? dataString + '\n' : dataString;
    });

    // The download function takes a CSV string, the filename and mimeType as parameters
    // Scroll/look down at the bottom of this snippet to see how download is called

    var a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if (navigator.msSaveBlob) { // IE10
      navigator.msSaveBlob(new Blob([content], {
        type: mimeType
      }), fileName);
    } else if (URL && 'download' in a) { //html5 A[download]
      a.href = URL.createObjectURL(new Blob([content], {
        type: mimeType
      }));
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      window.location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }


  }

  _load() {

    let _excludeDaysArr = [];
    for (let i = -30; i < 40; i++) {
      var date = new Date();
      date.setDate(date.getDate() + i);
      if ((0 == getDay(date) || 2 == getDay(date) || 4 == getDay(date))) {

      } else {
        _excludeDaysArr.push(date);
      }
    }
    let _currentDate = this.state.currentDate;
    /*
    if ((0 == getDay(date) || 2 == getDay(date) || 4 == getDay(date))) {

    } else {
      _currentDate.setDate(_currentDate.getDate() + 1);
    }
    */
    this.setState({ currentDate: _currentDate, excludeDaysArr: _excludeDaysArr });

    let _user = loadUser();
    this.setState({ user: _user });
    if (_user == null) this.props.history.push("/login");
    this.setState({ loader: true });
    let _date = new Date(this.state.currentDate.getTime());
    _date.setDate(_date.getDate() + 1);
    console.log(formatDate(_currentDate), formatDate(_date));
    getOrdersUsers(_user.api_token, formatDate(_date)).then((res) => {
      console.log(res);
      if (res.success) {
        let _list = [];
        res.response.map((item) => {
          if (item.program_size == 1) {
            if (new Date(item.date).getDay() != 0 && new Date(item.date).getDay() != 6) {
              _list.push(item);
            }
          } else {
            _list.push(item);
          }

        });



        _list.map((item) => {
          item['current_date'] = formatDate(_date);
        });
        this.setState({ ordersList: _list })

        let _currentDate2 = _date;

        _currentDate2.setDate(_currentDate2.getDate() + 1);
        this.setState({ currentDate2: _currentDate2 })

        getOrdersUsers(_user.api_token, formatDate(_currentDate2)).then((resources) => {
          if (resources.success) {
            let _arr = _list;

            let _list2 = [];
            resources.response.map((item) => {
              if (item.program_size == 1) {
                if (new Date(item.date).getDay() != 0 && new Date(item.date).getDay() != 6) {
                  _list2.push(item);
                }
              } else {
                _list2.push(item);
              }

            });

            _list2.map((item) => {
              item['current_date'] = formatDate(_currentDate2);
            });


            this.setState({ ordersList2: _list2 })
            _list = _arr.concat(_list2);

            //make list for ikitchen api
            let ordersApi = [];
            _list.map((item) => {
              if (item.additionals.length > 0) {
                ordersApi.push({
                  Image: item.additionals[0].item.image,
                  ArticleNo: item.additionals[0].item.outer_id,
                  ArticleName: item.additionals[0].item.title_ch,
                  Quantity: 1,
                  UnitPrice: item.additionals[0].item.price,
                })
              }
              item.list.map((dinner) => {
                if (dinner.dinner1) {
                  if (this._hasChangeRep(item.replacments, dinner.dinner1[0].id)) {
                    ordersApi.push({
                      Image: this._hasChangeRep(item.replacments, dinner.dinner1[0].id).image,
                      ArticleNo: this._hasChangeRep(item.replacments, dinner.dinner1[0].id).outer_id,
                      ArticleName: this._hasChangeRep(item.replacments, dinner.dinner1[0].id).title_ch,
                      Quantity: 1,
                      UnitPrice: this._hasChangeRep(item.replacments, dinner.dinner1[0].id).price,
                    })
                  } else {
                    ordersApi.push({
                      Image: dinner.dinner1[0].image,
                      ArticleNo: dinner.dinner1[0].outer_id,
                      ArticleName: dinner.dinner1[0].title_ch,
                      Quantity: 1,
                      UnitPrice: dinner.dinner1[0].price,
                    })
                  }

                }
                if (dinner.dinner2) {
                  if (this._hasChangeRep(item.replacments, dinner.dinner2[0].id)) {
                    ordersApi.push({
                      Image: this._hasChangeRep(item.replacments, dinner.dinner2[0].id).image,
                      ArticleNo: this._hasChangeRep(item.replacments, dinner.dinner2[0].id).outer_id,
                      ArticleName: this._hasChangeRep(item.replacments, dinner.dinner2[0].id).title_ch,
                      Quantity: 1,
                      UnitPrice: this._hasChangeRep(item.replacments, dinner.dinner2[0].id).price,
                    })
                  } else {
                    ordersApi.push({
                      Image: dinner.dinner2[0].image,
                      ArticleNo: dinner.dinner2[0].outer_id,
                      ArticleName: dinner.dinner2[0].title_ch,
                      Quantity: 1,
                      UnitPrice: dinner.dinner2[0].price,
                    })
                  }
                }
                if (dinner.dinner3) {
                  if (this._hasChangeRep(item.replacments, dinner.dinner3[0].id)) {
                    ordersApi.push({
                      Image: this._hasChangeRep(item.replacments, dinner.dinner3[0].id).image,
                      ArticleNo: this._hasChangeRep(item.replacments, dinner.dinner3[0].id).outer_id,
                      ArticleName: this._hasChangeRep(item.replacments, dinner.dinner3[0].id).title_ch,
                      Quantity: 1,
                      UnitPrice: this._hasChangeRep(item.replacments, dinner.dinner3[0].id).price,
                    })
                  } else {
                    ordersApi.push({
                      Image: dinner.dinner3[0].image,
                      ArticleNo: dinner.dinner3[0].outer_id,
                      ArticleName: dinner.dinner3[0].title_ch,
                      Quantity: 1,
                      UnitPrice: dinner.dinner3[0].price,
                    })
                  }
                }
                if (dinner.dinner4) {
                  if (this._hasChangeRep(item.replacments, dinner.dinner4[0].id)) {
                    ordersApi.push({
                      Image: this._hasChangeRep(item.replacments, dinner.dinner4[0].id).image,
                      ArticleNo: this._hasChangeRep(item.replacments, dinner.dinner4[0].id).outer_id,
                      ArticleName: this._hasChangeRep(item.replacments, dinner.dinner4[0].id).title_ch,
                      Quantity: 1,
                      UnitPrice: this._hasChangeRep(item.replacments, dinner.dinner4[0].id).price,
                    })
                  } else {
                    ordersApi.push({
                      Image: dinner.dinner4[0].image,
                      ArticleNo: dinner.dinner4[0].outer_id,
                      ArticleName: dinner.dinner4[0].title_ch,
                      Quantity: 1,
                      UnitPrice: dinner.dinner4[0].price,
                    })
                  }
                }
                if (dinner.dinner5) {
                  if (this._hasChangeRep(item.replacments, dinner.dinner5[0].id)) {
                    ordersApi.push({
                      Image: this._hasChangeRep(item.replacments, dinner.dinner5[0].id).image,
                      ArticleNo: this._hasChangeRep(item.replacments, dinner.dinner5[0].id).outer_id,
                      ArticleName: this._hasChangeRep(item.replacments, dinner.dinner5[0].id).title_ch,
                      Quantity: 1,
                      UnitPrice: this._hasChangeRep(item.replacments, dinner.dinner5[0].id).price,
                    })
                  } else {
                    ordersApi.push({
                      Image: dinner.dinner5[0].image,
                      ArticleNo: dinner.dinner5[0].outer_id,
                      ArticleName: dinner.dinner5[0].title_ch,
                      Quantity: 1,
                      UnitPrice: dinner.dinner5[0].price,
                    })
                  }
                }


              })
            });

            /* === */
            _list.sort(function (a, b) {
              var x = a['id']; var y = b['id'];
              return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
            //table sort
            var payedArr = [];
            _list.map((item) => {
              //if (item.is_pay == 1) {
              payedArr.push(item);
              //}
            })


            var result = payedArr.reduce(function (r, a) {
              r[a.program.title_ru] = r[a.program.title_ru] || [];
              r[a.program.title_ru].push(a);
              return r;
            }, Object.create(null));
            console.log(result);
            result = Object.keys(result).map((key) => result[key]);
            var _res = [];
            result.map((item) => {
              var i = item.reduce(function (r, a) {
                r[a.calories.title_ru] = r[a.calories.title_ru] || [];
                r[a.calories.title_ru].push(a);
                return r;
              }, Object.create(null));
              i = Object.keys(i).map((key) => i[key]);
              _res.push(i);
            })

            _res.map((item) => {
              item.map((item2) => {
                item2.map((item3) => {
                  if (item3.count == 2) {
                    let newItem = { ...item3 };
                    newItem.id = (newItem.id + "#2");
                    item2.push(newItem);
                  }
                  if (item2.count == 3) {
                    let newItem = { ...item3 };
                    newItem.id = (newItem.id + "#2");
                    item2.push(item);
                    let newItem2 = { ...item3 };
                    newItem2.id = (newItem2.id + "#3");
                    item2.push(newItem2);
                  }
                  if (item3.count == 4) {
                    let newItem = { ...item3 };
                    newItem.id = (newItem.id + "#2");
                    item2.push(item);
                    let newItem2 = { ...item3 };
                    newItem2.id = (newItem2.id + "#3");
                    item2.push(newItem2);
                    let newItem3 = { ...item3 };
                    newItem3.id = (newItem3.id + "#4");
                    item2.push(newItem3);
                  }
                })
              })
            })

            console.log(_res);

            this.setState({ loader: false, ordersApiList: ordersApi, sortedOrders: _res })
          }
        })




        {/*
               _currentDate.setDate(_currentDate.getDate() + 1);
          getOrdersUsers(_user.api_token, formatDate(_currentDate)).then((res) => {
            if (res.success) {
              let _arr = this.state.ordersList;
              let _list = res.response;
              _list.map((item) => {
                item['current_date'] = formatDate(_currentDate);
              });
              _arr = _arr.concat(_list);

              _arr.sort(function (a, b) {
                var x = a['id']; var y = b['id'];
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
              });

              this.setState({ loader: false, ordersList: _arr })
            }
          })
        
        */}

      } else {
        saveUser(null);
        this.props.history.push("/login");
      }
    })
  }

  _getOrderCount(_prog, _cal) {
    var orders = 0;
    this.state.ordersList.map((item, index) => {
      if (item.program.id == _prog) {
        if (item.calories.title_en == _cal) {
          orders++;
        }
      }
    })
    return orders;
  }


  _renderOrdersComment(_prog, _cal) {
    var orders_comments = this.state.sortedOrders.map((item, index) => {
      if (item[0][0].program.id == _prog) {
        return item.map((item2, index2) => {
          if (item2[0].calories.title_en == _cal) {
            return (
              item2.map((item3, index3) => {
                if (item3.program_size == 0 ||
                  (item3.program_size == 1 && (new Date(item3.current_date).getDay() != 0 && new Date(item3.current_date).getDay() != 6))
                ) {
                  return (
                    <td style={{
                      borderWidth: 0,
                      borderRightWidth: 2,
                      width: "40px",
                      borderColor: (index3 > 0 && index3 < item2.length - 1) ? item2[index3 - 1].id == item3.id ? "#000" : "#dee2e6" : "#dee2e6"
                    }}>
                      <span>
                        {item3.exclude != "" ? item3.exclude : "Нет"}
                      </span>
                    </td>
                  )
                }
              })
            )
          }
        });
      }
    })
    return orders_comments;
  }

  _renderOrdersNums(_prog, _cal) {
    var orders_num = this.state.sortedOrders.map((item, index) => {
      if (item[0][0].program.id == _prog) {
        return item.map((item2, index2) => {
          if (item2[0].calories.title_en == _cal) {
            return (
              item2.map((item3, index3) => {
                if (item3.program_size == 0 ||
                  (item3.program_size == 1 && (new Date(item3.current_date).getDay() != 0 && new Date(item3.current_date).getDay() != 6))
                ) {
                  return (
                    <td key={index3} style={{ borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 2, borderColor: (index3 > 0 && index3 < item2.length - 1) ? item2[index3 - 1].id == item3.id ? "#000" : "#dee2e6" : "#dee2e6" }}>
                      <span style={{ color: "red" }}>{item3.id}</span>{"\n"}
                      ({getWeekTitleByNum(new Date(item3.current_date).getDay())} {formatDateDdMm(item3.current_date)})</td>
                  )
                }

              })
            )
          }
        });

      }

    })
    return orders_num;
  }

  _renderOrdersNumsLine(_prog, _cal) {

    let sortedArr = [];
    this.state.sortedOrders.map((item, index) => {
      if (item[0][0].program.id == _prog) {
        item.map((item2, index2) => {
          if (item2[0].calories.title_en == _cal) {

            item2.map((item3, index3) => {
              if (item3.program_size == 0 ||
                (item3.program_size == 1 && (new Date(item3.current_date).getDay() != 0 && new Date(item3.current_date).getDay() != 6))
              ) {
                sortedArr.push(item3);
              }

            })

          }
        });

      }

    })

    let result = sortedArr.reduce(function (r, a) {
      r[a.id] = r[a.id] || [];
      r[a.id].push(a);
      return r;
    }, Object.create(null));

    var orders_num = Object.values(result).map((item, index) => {

      return (
        <td key={index} colSpan={item.length} style={{ textAlign: "center", borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 1 }}>
          <span style={{ color: "red" }}>{item[0].id}</span>{"\n"}
        </td>
      )


    })


    return orders_num;
  }

  _renderOrdersDinner(_prog, _cal, _din) {
    var orders_din_5 = this.state.sortedOrders.map((item, index) => {
      if (item[0][0].program.id == _prog) {
        return item.map((item2, index2) => {


          if (item2[0].calories.title_en == _cal) {

            return (
              item2.map((item3, index3) => {

                if (item3.program_size == 0 ||
                  (item3.program_size == 1 && (new Date(item3.current_date).getDay() != 0 && new Date(item3.current_date).getDay() != 6))
                ) {

                  if (_din == 1 && item3.list.length > 0 && (item3.size == 0 || item3.size == 1 || item3.size == 2 || item3.size == 4)) {
                    return (
                      item3.list[0]['dinner' + _din] ? <td key={index3} style={{ borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 2, borderColor: (index3 > 0 && index3 < item2.length - 1) ? item2[index3 - 1].id == item3.id ? "#000" : "#dee2e6" : "#dee2e6" }}>  [ ] </td> : <td style={{ borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 2, borderColor: (index3 > 0 && index3 < item2.length - 1) ? item2[index3 - 1].id == item3.id ? "#000" : "#dee2e6" : "#dee2e6" }}>  ___ </td>
                    )
                  } else if (_din == 2 && item3.list.length > 0 && (item3.size == 0)) {
                    return (
                      item3.list[0]['dinner' + _din] ? <td key={index3} style={{ borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 2, borderColor: (index3 > 0 && index3 < item2.length - 1) ? item2[index3 - 1].id == item3.id ? "#000" : "#dee2e6" : "#dee2e6" }}>  [ ] </td> : <td style={{ borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 2, borderColor: (index3 > 0 && index3 < item2.length - 1) ? item2[index3 - 1].id == item3.id ? "#000" : "#dee2e6" : "#dee2e6" }}>  ___ </td>
                    )
                  } else if (_din == 3 && item3.list.length > 0 && (item3.size == 0 || item3.size == 1 || item3.size == 3 || item3.size == 4)) {
                    return (
                      item3.list[0]['dinner' + _din] ? <td key={index3} style={{ borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 2, borderColor: (index3 > 0 && index3 < item2.length - 1) ? item2[index3 - 1].id == item3.id ? "#000" : "#dee2e6" : "#dee2e6" }}>  [ ] </td> : <td style={{ borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 2, borderColor: (index3 > 0 && index3 < item2.length - 1) ? item2[index3 - 1].id == item3.id ? "#000" : "#dee2e6" : "#dee2e6" }}>  ___ </td>
                    )
                  } else if (_din == 4 && item3.list.length > 0 && (item3.size == 0)) {
                    return (
                      item3.list[0]['dinner' + _din] ? <td key={index3} style={{ borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 2, borderColor: (index3 > 0 && index3 < item2.length - 1) ? item2[index3 - 1].id == item3.id ? "#000" : "#dee2e6" : "#dee2e6" }}>  [ ] </td> : <td style={{ borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 2, borderColor: (index3 > 0 && index3 < item2.length - 1) ? item2[index3 - 1].id == item3.id ? "#000" : "#dee2e6" : "#dee2e6" }}>  ___ </td>
                    )
                  } else if (_din == 5 && item3.list.length > 0 && (item3.size == 0 || item3.size == 2 || item3.size == 3 || item3.size == 4)) {
                    return (
                      item3.list[0]['dinner' + _din] ? <td key={index3} style={{ borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 2, borderColor: (index3 > 0 && index3 < item2.length - 1) ? item2[index3 - 1].id == item3.id ? "#000" : "#dee2e6" : "#dee2e6" }}>  [ ] </td> : <td style={{ borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 2, borderColor: (index3 > 0 && index3 < item2.length - 1) ? item2[index3 - 1].id == item3.id ? "#000" : "#dee2e6" : "#dee2e6" }}>  ___ </td>
                    )
                  } else {
                    return <td style={{ borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 2, borderColor: (index3 > 0 && index3 < item2.length - 1) ? item2[index3 - 1].id == item3.id ? "#000" : "#dee2e6" : "#dee2e6" }}>  ___ </td>;
                  }
                }


              })
            )
          }
        });
      }
    })

    return orders_din_5;
  }

  _getCountVal(_prog, _cal, _day, _din) {
    var count = 0;
    if (_day == 1) {

      this.state.ordersList.map((item3, index) => {
        if (item3.calories.title_en == _cal) {
          if (item3.program.id == _prog) {

            if (item3.program_size == 0 ||
              (item3.program_size == 1 && (new Date(item3.current_date).getDay() != 0 && new Date(item3.current_date).getDay() != 6))
            ) {
              if (_din == 1 && item3.list.length > 0 && (item3.size == 0 || item3.size == 1 || item3.size == 2 || item3.size == 4)) {
                if (item3.list[0]['dinner' + _din]) count = count + item3.count;
              } else if (_din == 2 && item3.list.length > 0 && (item3.size == 0)) {
                if (item3.list[0]['dinner' + _din]) count = count + item3.count;
              } else if (_din == 3 && item3.list.length > 0 && (item3.size == 0 || item3.size == 1 || item3.size == 3 || item3.size == 4)) {
                if (item3.list[0]['dinner' + _din]) count = count + item3.count;
              } else if (_din == 4 && item3.list.length > 0 && (item3.size == 0)) {
                if (item3.list[0]['dinner' + _din]) count = count + item3.count;
              } else if (_din == 5 && item3.list.length > 0 && (item3.size == 0 || item3.size == 2 || item3.size == 3 || item3.size == 4)) {
                if (item3.list[0]['dinner' + _din]) count = count + item3.count;
              } else {

              }
            }


          }
        }

      })
    } else {
      this.state.ordersList2.map((item3, index) => {
        if (item3.calories.title_en == _cal) {
          if (item3.program.id == _prog) {

            if (item3.program_size == 0 ||
              (item3.program_size == 1 && (new Date(item3.current_date).getDay() != 0 && new Date(item3.current_date).getDay() != 6))
            ) {

              if (_din == 1 && item3.list.length > 0 && (item3.size == 0 || item3.size == 1 || item3.size == 2 || item3.size == 4)) {
                if (item3.list[0]['dinner' + _din]) count = count + item3.count;
              } else if (_din == 2 && item3.list.length > 0 && (item3.size == 0)) {
                if (item3.list[0]['dinner' + _din]) count = count + item3.count;
              } else if (_din == 3 && item3.list.length > 0 && (item3.size == 0 || item3.size == 1 || item3.size == 3 || item3.size == 4)) {
                if (item3.list[0]['dinner' + _din]) count = count + item3.count;
              } else if (_din == 4 && item3.list.length > 0 && (item3.size == 0)) {
                if (item3.list[0]['dinner' + _din]) count = count + item3.count;
              } else if (_din == 5 && item3.list.length > 0 && (item3.size == 0 || item3.size == 2 || item3.size == 3 || item3.size == 4)) {
                if (item3.list[0]['dinner' + _din]) count = count + item3.count;
              } else {

              }

            }
          }
        }

      })
    }
    return count;
  }

  _getCountCaloriesVal(_prog, _day, _cal) {
    var count = 0;



    if (_day == 1) {
      this.state.ordersList.map((item3, index) => {
        if (item3.program.id == _prog) {
          if (item3.calories.title_en == _cal) {
            if (item3.program_size == 0 ||
              (item3.program_size == 1 && (new Date(item3.current_date).getDay() != 0 && new Date(item3.current_date).getDay() != 6))
            ) {

              if (item3.list.length > 0 && (item3.size == 0 || item3.size == 1 || item3.size == 2 || item3.size == 4)) {
                count = count + item3.count;
              } else if (item3.list.length > 0 && (item3.size == 0)) {
                count = count + item3.count;
              } else if (item3.list.length > 0 && (item3.size == 0 || item3.size == 1 || item3.size == 3 || item3.size == 4)) {
                count = count + item3.count;
              } else if (item3.list.length > 0 && (item3.size == 0)) {
                count = count + item3.count;
              } else if (item3.list.length > 0 && (item3.size == 0 || item3.size == 2 || item3.size == 3 || item3.size == 4)) {
                count = count + item3.count;
              } else {

              }

            }
          }
        }

      })
    } else {
      this.state.ordersList2.map((item3, index) => {
        if (item3.program.id == _prog) {
          if (item3.calories.title_en == _cal) {
            if (item3.program_size == 0 ||
              (item3.program_size == 1 && (new Date(item3.current_date).getDay() != 0 && new Date(item3.current_date).getDay() != 6))
            ) {

              if (item3.list.length > 0 && (item3.size == 0 || item3.size == 1 || item3.size == 2 || item3.size == 4)) {
                count = count + item3.count;
              } else if (item3.list.length > 0 && (item3.size == 0)) {
                count = count + item3.count;
              } else if (item3.list.length > 0 && (item3.size == 0 || item3.size == 1 || item3.size == 3 || item3.size == 4)) {
                count = count + item3.count;
              } else if (item3.list.length > 0 && (item3.size == 0)) {
                count = count + item3.count;
              } else if (item3.list.length > 0 && (item3.size == 0 || item3.size == 2 || item3.size == 3 || item3.size == 4)) {
                count = count + item3.count;
              } else {

              }
            }
          }
        }
      })
    }


    return count;
  }

  renderXLS() {
    var progs = [
      "Balance",
      "Vegan",
      "Anti-age",
      "Low Carb"
    ]
    var ids = [this.state.selectedPrintProgramIndex];
    return ids.map((item) => {

      return (
        <table id={"xlsTable" + item}>
          <tr><td>
            <table className="table table-bordered"  >
              <tr>
                <th rowSpan="2">{formatDate(this.state.currentDate)} - {formatDate(this.state.currentDate2)}</th>
                <th colSpan="4">{progs[item - 1]}</th>
              </tr>
              <tr>
                <td>Loose weight</td>
                <td>Daily</td>
                <td>Fit</td>
                <td>Power</td>
              </tr>

              <tr>
                <td style={{ padding: 0 }}>
                  <table className="table" style={{ borderWidth: 0, marginBottom: 0 }}>
                    <tr style={{ height: 50 }}>
                      <td style={{ borderWidth: 0, borderBottomWidth: 1 }}> </td>
                    </tr>
                    <tr style={{ height: 100 }}>
                      <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>№ заказа</td>
                    </tr>
                    <tr style={{ height: 50 }}>
                      <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Завтрак</td>
                    </tr>
                    <tr style={{ height: 50 }}>
                      <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Перекус</td>
                    </tr>
                    <tr style={{ height: 50 }}>
                      <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Обед</td>
                    </tr>

                    <tr style={{ height: 50 }}>
                      <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Ужин</td>
                    </tr>
                    <tr style={{ height: 50 }}>
                      <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Десерт</td>
                    </tr>
                    <tr style={{ height: 250 }}>
                      <td style={{ borderWidth: 0 }}>Комментарий</td>
                    </tr>
                  </table>
                </td>
                <td style={{ padding: 0 }}>
                  {/* Loose weight */}
                  <table className="table" style={{ borderWidth: 0, marginBottom: 0 }}>

                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersNumsLine(item, "Lose weight")}
                    </tr>

                    <tr style={{ height: 100, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersNums(item, "Lose weight")}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Lose weight", 1)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Lose weight", 2)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Lose weight", 3)}
                    </tr>

                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Lose weight", 5)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Lose weight", 4)}
                    </tr>
                    <tr style={{ height: 250, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersComment(item, "Lose weight")}
                    </tr>
                  </table>
                </td>
                <td style={{ padding: 0 }}>
                  {/* Daily */}
                  <table className="table" style={{ borderWidth: 0, marginBottom: 0 }}>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersNumsLine(item, "Daily")}
                    </tr>

                    <tr style={{ height: 100, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersNums(item, "Daily")}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Daily", 1)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Daily", 2)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Daily", 3)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Daily", 5)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Daily", 4)}
                    </tr>
                    <tr style={{ height: 250, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersComment(item, "Daily")}
                    </tr>
                  </table>
                </td>
                <td style={{ padding: 0 }}>
                  {/* Fit */}
                  <table className="table" style={{ borderWidth: 0, marginBottom: 0 }}>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersNumsLine(item, "Fit")}
                    </tr>
                    <tr style={{ height: 100 }}>
                      {this._renderOrdersNums(item, "Fit")}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Fit", 1)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Fit", 2)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Fit", 3)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Fit", 5)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Fit", 4)}
                    </tr>
                    <tr style={{ height: 250, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersComment(item, "Fit")}
                    </tr>
                  </table>
                </td>
                <td style={{ padding: 0 }}>
                  {/* Power */}
                  <table className="table" style={{ borderWidth: 0, marginBottom: 0 }}>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersNumsLine(item, "Power")}
                    </tr>

                    <tr style={{ height: 100 }}>
                      {this._renderOrdersNums(item, "Power")}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Power", 1)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Power", 2)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Power", 3)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Power", 5)}
                    </tr>
                    <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersDinner(item, "Power", 4)}
                    </tr>
                    <tr style={{ height: 250, border: "1px solid #dee2e6" }}>
                      {this._renderOrdersComment(item, "Power")}
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>Итого заказов</td>
                <td>{this._getOrderCount(item, "Lose weight")}</td>
                <td>{this._getOrderCount(item, "Daily")}</td>
                <td>{this._getOrderCount(item, "Fit")}</td>
                <td>{this._getOrderCount(item, "Power")}</td>
              </tr>
            </table>
          </td></tr>
          <tr><td>
            <table className="table table-bordered">
              <tr>
                <td> </td>
                <td>
                  <table>
                    <tr>
                      <td>
                        <table style={{ borderWidth: 0, marginBottom: 0 }}>
                          <tr style={{ height: 50 }}>
                            <td> {progs[item - 1]} "Lose weight" </td> <td>1 день</td><td>2 день</td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Завтрак </td><td> {this._getCountVal(item, "Lose weight", 1, 1)}</td><td>  {this._getCountVal(item, "Lose weight", 2, 1)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td> Перекус</td><td> {this._getCountVal(item, "Lose weight", 1, 2)}</td><td>  {this._getCountVal(item, "Lose weight", 2, 2)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td> Обед</td><td> {this._getCountVal(item, "Lose weight", 1, 3)}</td><td>  {this._getCountVal(item, "Lose weight", 2, 3)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Ужин </td><td> {this._getCountVal(item, "Lose weight", 1, 5)}</td><td>  {this._getCountVal(item, "Lose weight", 2, 5)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Десерт </td><td> {this._getCountVal(item, "Lose weight", 1, 4)}</td><td>  {this._getCountVal(item, "Lose weight", 2, 4)} </td>
                          </tr>

                        </table>
                      </td>
                      <td></td>
                      <td>
                        <table style={{ borderWidth: 0, marginBottom: 0 }}>
                          <tr style={{ height: 50 }}>
                            <td> {progs[item - 1]} "Daily" </td><td>1 день</td><td>2 день</td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Завтрак </td><td> {this._getCountVal(item, "Daily", 1, 1)}</td><td>  {this._getCountVal(item, "Daily", 2, 1)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Перекус </td><td> {this._getCountVal(item, "Daily", 1, 2)}</td><td>  {this._getCountVal(item, "Daily", 2, 2)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td> Обед</td><td> {this._getCountVal(item, "Daily", 1, 3)}</td><td>  {this._getCountVal(item, "Daily", 2, 3)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Ужин </td><td> {this._getCountVal(item, "Daily", 1, 5)}</td><td>  {this._getCountVal(item, "Daily", 2, 5)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Десерт </td><td> {this._getCountVal(item, "Daily", 1, 4)}</td><td>  {this._getCountVal(item, "Daily", 2, 4)} </td>
                          </tr>

                        </table>
                      </td>
                      <td></td>
                      <td>
                        <table style={{ borderWidth: 0, marginBottom: 0 }}>
                          <tr style={{ height: 50 }}>
                            <td> {progs[item - 1]} "Fit" </td><td>1 день</td><td>2 день</td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Завтрак </td><td> {this._getCountVal(item, "Fit", 1, 1)}</td><td>  {this._getCountVal(item, "Fit", 2, 1)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td> Перекус</td><td> {this._getCountVal(item, "Fit", 1, 2)}</td><td>  {this._getCountVal(item, "Fit", 2, 2)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Обед </td><td> {this._getCountVal(item, "Fit", 1, 3)}</td><td>  {this._getCountVal(item, "Fit", 2, 3)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Ужин </td><td> {this._getCountVal(item, "Fit", 1, 5)}</td><td>  {this._getCountVal(item, "Fit", 2, 5)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Десерт </td><td> {this._getCountVal(item, "Fit", 1, 4)}</td><td>  {this._getCountVal(item, "Fit", 2, 4)} </td>
                          </tr>

                        </table>
                      </td>
                      <td></td>
                      <td>

                        <table style={{ borderWidth: 0, marginBottom: 0 }}>
                          <tr style={{ height: 50 }}>
                            <td> {progs[item - 1]} "Power" </td><td>1 день</td><td>2 день</td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Завтрак </td><td> {this._getCountVal(item, "Power", 1, 1)}</td><td>  {this._getCountVal(item, "Power", 2, 1)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Перекус </td><td> {this._getCountVal(item, "Power", 1, 2)}</td><td>  {this._getCountVal(item, "Power", 2, 2)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td> Обед </td><td> {this._getCountVal(item, "Power", 1, 3)}</td><td>  {this._getCountVal(item, "Power", 2, 3)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Ужин </td><td> {this._getCountVal(item, "Power", 1, 5)}</td><td>  {this._getCountVal(item, "Power", 2, 5)} </td>
                          </tr>
                          <tr style={{ height: 50 }}>
                            <td>Десерт </td><td> {this._getCountVal(item, "Power", 1, 4)}</td><td>  {this._getCountVal(item, "Power", 2, 4)} </td>
                          </tr>

                        </table>
                      </td>

                    </tr>
                  </table>
                </td>

              </tr>


              <tr>
                <td> </td>
                <td>
                  <table style={{ borderWidth: 0, marginBottom: 0 }}>
                    <tr style={{ height: 40 }}>
                      <td>В Lose weight </td><td> </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td>{formatDate(this.state.currentDate)}</td><td> {this._getCountCaloriesVal(item, 1, "Lose weight")}</td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td>{formatDate(this.state.currentDate2)}</td><td>  {this._getCountCaloriesVal(item, 2, "Lose weight")} </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td> <b>Всего</b> </td> <td>  {this._getCountCaloriesVal(item, 1, "Lose weight") + this._getCountCaloriesVal(item, 2, "Lose weight")} </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td>В Daily </td><td> </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td>{formatDate(this.state.currentDate)} </td><td> {this._getCountCaloriesVal(item, 1, "Daily")}</td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td>{formatDate(this.state.currentDate2)} </td><td>  {this._getCountCaloriesVal(item, 2, "Daily")} </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td><b>Всего</b></td> <td>  {this._getCountCaloriesVal(item, 1, "Daily") + this._getCountCaloriesVal(item, 2, "Daily")} </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td>В Fit </td><td> </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td>{formatDate(this.state.currentDate)} </td><td> {this._getCountCaloriesVal(item, 1, "Fit")}</td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td>{formatDate(this.state.currentDate2)} </td><td>  {this._getCountCaloriesVal(item, 2, "Fit")} </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td><b>Всего</b></td> <td>  {this._getCountCaloriesVal(item, 1, "Fit") + this._getCountCaloriesVal(item, 2, "Fit")} </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td>В Power </td><td> </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td>{formatDate(this.state.currentDate)} </td><td> {this._getCountCaloriesVal(item, 1, "Power")}</td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td>{formatDate(this.state.currentDate2)} </td> <td>  {this._getCountCaloriesVal(item, 2, "Power")} </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                      <td><b>Всего</b></td> <td>  {this._getCountCaloriesVal(item, 1, "Power") + this._getCountCaloriesVal(item, 2, "Power")} </td>
                    </tr>

                  </table>
                </td>
              </tr>


            </table>

          </td></tr>
        </table>
      )
    })

  }

  renderListXLS() {
    var progs = [
      "Balance",
      "Vegan",
      "Anti-age",
      "Low Carb"
    ]
    var ids = [this.state.selectedPrintProgramIndex];
    return ids.map((item) => {

      return (
        <div>
          <table className="table table-bordered" id={"xlsTableList1"} >
            <tr>
              <th rowSpan="2">{formatDate(this.state.currentDate)} - {formatDate(this.state.currentDate2)}</th>
              <th colSpan="4">{progs[item - 1]}</th>
            </tr>
            <tr>
              <td>Loose weight</td>
            </tr>
            <tr>
              <td style={{ padding: 0 }}>
                <table className="table" style={{ borderWidth: 0, marginBottom: 0 }}>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}> </td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>№ заказа</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Завтрак</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Перекус</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Обед</td>
                  </tr>

                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Ужин</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Десерт</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0 }}>Комментарий</td>
                  </tr>
                </table>
              </td>
              <td style={{ padding: 0 }}>
                {/* Loose weight */}
                <table className="table" style={{ borderWidth: 0, marginBottom: 0 }}>

                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersNumsLine(item, "Lose weight")}
                  </tr>

                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersNums(item, "Lose weight")}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Lose weight", 1)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Lose weight", 2)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Lose weight", 3)}
                  </tr>

                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Lose weight", 5)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Lose weight", 4)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersComment(item, "Lose weight")}
                  </tr>
                </table>
              </td>

            </tr>
          </table>

          <table className="table table-bordered" id={"xlsTableList2"}>
            <tr>
              <th rowSpan="2">{formatDate(this.state.currentDate)} - {formatDate(this.state.currentDate2)}</th>
              <th colSpan="4">{progs[item - 1]}</th>
            </tr>
            <tr>
              <td>Daily</td>
            </tr>
            <tr>
              <td style={{ padding: 0 }}>
                <table className="table" style={{ borderWidth: 0, marginBottom: 0 }}>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}> </td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>№ заказа</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Завтрак</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Перекус</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Обед</td>
                  </tr>

                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Ужин</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Десерт</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0 }}>Комментарий</td>
                  </tr>
                </table>
              </td>

              <td style={{ padding: 0 }}>
                {/* Daily */}
                <table className="table" style={{ borderWidth: 0, marginBottom: 0 }}>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersNumsLine(item, "Daily")}
                  </tr>

                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersNums(item, "Daily")}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Daily", 1)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Daily", 2)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Daily", 3)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Daily", 5)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Daily", 4)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersComment(item, "Daily")}
                  </tr>
                </table>
              </td>
            </tr>
          </table>



          <table className="table table-bordered" id={"xlsTableList3"}>
            <tr>
              <th rowSpan="2">{formatDate(this.state.currentDate)} - {formatDate(this.state.currentDate2)}</th>
              <th colSpan="4">{progs[item - 1]}</th>
            </tr>
            <tr>
              <td>Fit</td>
            </tr>
            <tr>
              <td style={{ padding: 0 }}>
                <table className="table" style={{ borderWidth: 0, marginBottom: 0 }}>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}> </td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>№ заказа</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Завтрак</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Перекус</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Обед</td>
                  </tr>

                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Ужин</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Десерт</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0 }}>Комментарий</td>
                  </tr>
                </table>
              </td>
              <td style={{ padding: 0 }}>
                {/* Fit */}
                <table className="table" style={{ borderWidth: 0, marginBottom: 0 }}>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersNumsLine(item, "Fit")}
                  </tr>
                  <tr style={{ height: 50 }}>
                    {this._renderOrdersNums(item, "Fit")}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Fit", 1)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Fit", 2)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Fit", 3)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Fit", 5)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Fit", 4)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersComment(item, "Fit")}
                  </tr>
                </table>
              </td>

            </tr>
          </table>



          <table className="table table-bordered" id={"xlsTableList4"}>
            <tr>
              <th rowSpan="2">{formatDate(this.state.currentDate)} - {formatDate(this.state.currentDate2)}</th>
              <th colSpan="4">{progs[item - 1]}</th>
            </tr>
            <tr>
              <td>Power</td>
            </tr>
            <tr>
              <td style={{ padding: 0 }}>
                <table className="table" style={{ borderWidth: 0, marginBottom: 0 }}>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}> </td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>№ заказа</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Завтрак</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Перекус</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Обед</td>
                  </tr>

                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Ужин</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0, borderBottomWidth: 1 }}>Десерт</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ borderWidth: 0 }}>Комментарий</td>
                  </tr>
                </table>
              </td>

              <td style={{ padding: 0 }}>
                {/* Power */}
                <table className="table" style={{ borderWidth: 0, marginBottom: 0 }}>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersNumsLine(item, "Power")}
                  </tr>

                  <tr style={{ height: 50 }}>
                    {this._renderOrdersNums(item, "Power")}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Power", 1)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Power", 2)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Power", 3)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Power", 5)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersDinner(item, "Power", 4)}
                  </tr>
                  <tr style={{ height: 50, border: "1px solid #dee2e6" }}>
                    {this._renderOrdersComment(item, "Power")}
                  </tr>
                </table>
              </td>
            </tr>
          </table>

        </div>



      )
    })

  }

  render() {

    var list = this.state.ordersList.map((item, index) => {

      if (
        (this.state.tabIndex == 1 && item.deliviries_users.length > 0) ||
        (this.state.tabIndex == 0 && item.deliviries_users.length == 0)
      ) {

        return (
          <tr key={index}>
            <td>#{item.id}</td>
            <td>
              {item.current_date}<br />
              {this._hasChangeDelivery(item.deliveries) ?
                this._hasChangeDelivery(item.deliveries).fttime
                :
                item.user ? item.user.fttime : null
              }
            </td>
            <td>
              {this._hasChangeDelivery(item.deliveries) ?
                this._hasChangeDelivery(item.deliveries).city
                :
                item.user ? item.user.city : null
              },
              {this._hasChangeDelivery(item.deliveries) ?
                this._hasChangeDelivery(item.deliveries).street
                :
                item.user ? item.user.street : null
              }
              <br />
              <UiBtnPink btnText={global.locale.сour_btn_bild_path} onClick={() => this._route(item.deliveries, item > 0 ? item.id : 0, item)} btnsm black />
            </td>
            <td>
              {item.user ?
                <div>
                  {item.user.name} {item.user.lname}
                  <br />
                  <a href={"tel:" + item.user.phone} >{item.user.phone}</a>
                  <br />
                  {item.user.comment}
                  <br />
                  {this._hasChangeDelivery(item.deliveries) ?
                    `Доп. комментарий: ${this._hasChangeDelivery(item.deliveries).comment}`
                    :
                    null
                  }
                </div>
                : null}

            </td>

            {item.list.length > 0 ?
              <td>
                { (item.type == 0 || item.type == 1 || item.type == 2 || item.type == 4 ) && item.list[0].dinner1 ?
                  this._hasChangeRep(item.replacments, item.list[0].dinner1[0].id) ?
                    this._hasChangeRep(item.replacments, item.list[0].dinner1[0].id).title_ru :
                    (item.list[0].dinner1[0].title_ru)
                  : null}
                {item.list[0].dinner1 ? <br /> : null}

                {(item.type == 0 ) && item.list[0].dinner2 ?
                  this._hasChangeRep(item.replacments, item.list[0].dinner2[0].id) ?
                    this._hasChangeRep(item.replacments, item.list[0].dinner2[0].id).title_ru :
                    (item.list[0].dinner2[0].title_ru)
                  : null}
                {item.list[0].dinner2 ? <br /> : null}

                {(item.type == 0 || item.type == 1 || item.type == 3 || item.type == 4 ) &&  item.list[0].dinner3 ?
                  this._hasChangeRep(item.replacments, item.list[0].dinner3[0].id) ?
                    this._hasChangeRep(item.replacments, item.list[0].dinner3[0].id).title_ru :
                    (item.list[0].dinner3[0].title_ru)
                  : null}
                {item.list[0].dinner3 ? <br /> : null}

                {(item.type == 0  ) &&  item.list[0].dinner4 ?
                  this._hasChangeRep(item.replacments, item.list[0].dinner4[0].id) ?
                    this._hasChangeRep(item.replacments, item.list[0].dinner4[0].id).title_ru :
                    (item.list[0].dinner4[0].title_ru)
                  : null}
                {item.list[0].dinner4 ? <br /> : null}

                {(item.type == 0 || item.type == 2 || item.type == 3 || item.type == 4 ) &&  item.list[0].dinner5 ?
                  this._hasChangeRep(item.replacments, item.list[0].dinner5[0].id) ?
                    this._hasChangeRep(item.replacments, item.list[0].dinner5[0].id).title_ru :
                    (item.list[0].dinner5[0].title_ru)
                  : null}
                {item.additionals ?
                  <div>
                    <br />
                    <b>{global.locale.сour_dop_dish}:</b>
                    <br />
                  </div>

                  : null}
                {item.additionals ?
                  item.additionals.length > 0 ? (item.additionals[0].item.title_ru + " +" + item.additionals[0].item.price + " р") : null
                  : null}

              </td>

              :
              <td>
              </td>

            }
            <td>{item.price}</td>
            <td>
              {item.is_pay == 0 ? <span>{global.locale.сour_not_payed}</span> : null}
              {item.is_pay == 1 ? <span>{global.locale.ord_payed}</span> : null}
            </td>
            <td>
              {item.is_pay == 0 ? <UiBtnGreen btnText={global.locale.ord_payed} onClick={() => this._changePay(item.id, 1)} btnsm black /> : null}
              {item.is_pay == 1 ? <UiBtnGreen btnText={global.locale.сour_not_payed} onClick={() => this._changePay(item.id, 0)} btnsm black /> : null}
              <br />
              <br />
              {item.deliviries_users.length == 0 ?
                <UiBtnGreen btnText={global.locale.сour_btn_take_in_delivery} onClick={() => this._changeType(item.id, this.state.user.id, 2)} btnsm black />
                :
                item.deliviries_users ?
                  item.deliviries_users[0].type == 2 ?
                    <UiBtnPink btnText={global.locale.сour_btn_delivered} onClick={() => this._changeType(item.id, this.state.user.id, 3)} btnsm black />
                    : global.locale.сour_btn_delivered
                  : null

              }
            </td>
          </tr>
        )
      }

    })



    return (
      <div ref={this.divPage} id="page">
        <UiLoader show={this.state.loader} />
        <UiAlert
          show={this.state.showAlert}
          title={this.state.alertTitle}
          text={this.state.alertText}
          callBack={() => this.setState({ showAlert: false })}
          leftBtnText="Ок"
        />
        <div className={"wrapper" + (this.state.showMenuBar ? ' scroll' : '')}>
          <div className="content">
            <UiHeader />
            <section className="section bg-gray orders-list">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="btn-wrap">
                      <div className="row">
                        <div className="col-md-2">
                          {this.state.tabIndex == 0 ?
                            <UiBtnGreen btnText={global.locale.сour_btn_list_oreders} block btnsm black onClick={() => this.setState({ tabIndex: 0 })} />
                            :
                            <UiBtnGreenOutline btnText={global.locale.сour_btn_list_oreders} block btnsm black onClick={() => this.setState({ tabIndex: 0 })} />
                          }
                        </div>
                        <div className="col-md-2">
                          {this.state.tabIndex == 1 ?
                            <UiBtnGreen btnText={global.locale.сour_btn_delivereds} block btnsm black onClick={() => this.setState({ tabIndex: 1 })} />
                            :
                            <UiBtnGreenOutline btnText={"В работе"} block btnsm black onClick={() => this.setState({ tabIndex: 1 })} />
                          }
                        </div>
                        {this.state.user.type == 0 || this.state.user.type == 1 ?
                          <div className="col-md-2">
                            <UiBtnGreen btnText="Download CSV" block btnsm black onClick={() => this._downloadCSV('dowload.csv', 'text/csv;encoding:utf-8')} />
                            <UiBtnGreen btnText="Download Tickets" block btnsm black onClick={() => this._print()} />
                            <UiBtnGreen btnText="Send data to api" block btnsm black onClick={() => this._sendToKitchen()} />
                          </div> : null}
                        {this.state.user.type == 0 || this.state.user.type == 1 ?
                          <div className="col-md-2">

                            <UiBtnGreen btnText="Print Balance" block btnsm black onClick={() => this._printKitchenList(1)} />
                            <UiBtnGreen btnText="Print Vegan  " block btnsm black onClick={() => this._printKitchenList(2)} />
                            <UiBtnGreen btnText="Print Low Carb" block btnsm black onClick={() => this._printKitchenList(3)} />
                            <UiBtnGreen btnText="Print Anti-age" block btnsm black onClick={() => this._printKitchenList(5)} />

                          </div> : null}

                        {this.state.user.type == 0 || this.state.user.type == 1 ?
                          <div className="col-md-2">

                            <UiBtnGreen btnText="Download Balance" block btnsm black onClick={() => this._printKitchen(1)} />
                            <UiBtnGreen btnText="Download Vegan  " block btnsm black onClick={() => this._printKitchen(2)} />
                            <UiBtnGreen btnText="Download Low Carb" block btnsm black onClick={() => this._printKitchen(3)} />
                            <UiBtnGreen btnText="Download Anti-age" block btnsm black onClick={() => this._printKitchen(5)} />

                          </div> : null}

                      </div>
                    </div>
                    <div className="plans">

                      {this.state.showXLS ?
                        <table className="table table-bordered" id={"xlsTableMain"} >
                          <tr>
                            <td>
                              {this.renderXLS()}
                            </td>
                          </tr>
                        </table>
                        : null
                      }


                      {this.state.showPKL ? <div className="table table-bordered" id={"xlsTableMain2"} >

                        {this.renderListXLS()}

                      </div> : null}



                      <p><strong>{global.locale.сour_chosed_day}</strong></p>
                      {this.state.excludeDaysArr.length > 0 ?
                        <DatePicker
                          excludeDates={this.state.excludeDaysArr}
                          onChange={(data) => {
                            this.setState({ currentDate: data }, () => this._load());
                          }}
                          selected={this.state.currentDate}
                        />
                        : null}

                      <hr />

                      <br />
                      <br />
                      <table className="table table-bordered food-table">
                        <tr>
                          <th>{global.locale.сour_num}</th>
                          <th>{global.locale.сour_time}</th>
                          <th>{global.locale.сour_adress}</th>
                          <th>{global.locale.сour_client}</th>
                          <th>{global.locale.сour_composition}</th>
                          <th>{global.locale.сour_cost}</th>
                          <th>{global.locale.сour_status}</th>
                          <th>{global.locale.сour_rule}</th>
                        </tr>
                        {list}

                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </section>
            <UiFooter />
            <button className="top-button" onClick={() => this._scrollToTargetAdjusted("header")}>
              <img src={require('../../assets/imgs/ui/up-arrow.svg')} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CourierOrdersPage;