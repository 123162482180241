import React, { Component } from 'react';

class UiFormInput extends Component {

  state = {
    selectValue: null,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.value != undefined && this.props.value) {
      this.setState({ selectValue: this.props.value });
    }
  }

  componentDidUpdate(nProps, nState) {
    if (nProps.clear != undefined && nProps.clear) {
      this.setState({ selectValue: null });
      this.props.clearCallBack(!nProps.clear);
    }
    if (this.props.value != undefined && this.props.value && nProps.value != this.state.selectValue) {
      this.setState({ selectValue: this.props.value });
    }
    return true;
  }


  render() {
    return (
      <div className="form-group">
        {this.props.label ? <label>{this.props.label}</label> : null}
        <input type={this.props.type} placeholder={this.props.placeholder} defaultValue={this.props.defaultValue} value={this.props.value ? this.state.selectValue : null} 
          className="form-control"
          onChange={e => {
            if (this.props.callBack) this.props.callBack(e.target.value);
            if (this.props.value) { 
              this.setState({ selectValue: e.target.value })
            }
          }
          }
          onBlur={e => {
            if (this.props.onBlur) this.props.onBlur(e.target.value);
            if (this.props.value) this.setState({ selectValue: e.target.value })
          }
          }

        />
      </div>
    );
  }
}
export default UiFormInput;