import ENV from './Env.js';


export const saveUser = (_user) => {
   localStorage.setItem("mh_user", JSON.stringify(_user) );
}

export const loadUser = (_user) => {
  let user = localStorage.getItem("mh_user");
  if(user != null && user != undefined) user = JSON.parse(user); 
  return user;
}
 
export const loginUser = (_login, _pass) => {
  return fetch(ENV.API_URL+'/users/login', {
    method: 'POST',
    headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    },
    body: JSON.stringify({
    email: _login,
    password: _pass,
    }) 
  }).then(function(response) {
    return response.json();
  });
}

export const changeDeliveryType = (_token, _order_id, _curier_id, _date, _type,  _msg ) => {
  return fetch(ENV.API_URL+'/users/delivery/set', {
    method: 'POST',
    headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    },
    body: JSON.stringify({
    order_id: _order_id,
    curier_id: _curier_id,
    date: _date,
    type: _type,
    msg: _msg,

    api_token: _token
    }) 
  }).then(function(response) {
    return response.json();
  });
}

export const changePay = (_token, _order_id,   _type ) => {
  return fetch(ENV.API_URL+'/users/payment/set', {
    method: 'POST',
    headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      order_id: _order_id,
      type: _type,
      api_token: _token
    }) 
  }).then(function(response) {
    return response.json();
  });
}



export const setUser = (_token,_uid,  _type,) => {
  return fetch(ENV.API_URL+'/users/add/type', {
    method: 'POST',
    headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    },
    body: JSON.stringify({
    type: _type,
    user_id: _uid,
    api_token: _token
    }) 
  }).then(function(response) {
    return response.json();
  });
}

export const addUser = (_type, _fio,_city, _adress) => {
  return fetch(ENV.API_URL+'/users/add/user', {
    method: 'POST',
    headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    },
    body: JSON.stringify({
    type: _type,
    fio: _fio,
    city_id: _city,
    adress: _adress,
    
    }) 
  }).then(function(response) {
    return response.json();
  });
}

export const removeUser = (_id) => {
  return fetch(ENV.API_URL+'/users/delete', {
    method: 'POST',
    headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    },
    body: JSON.stringify({
    id: _id,    
    }) 
  }).then(function(response) {
    return response.json();
  });
}

export const updateUser = (_token, _data) => {

  return fetch(ENV.API_URL+'/users/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_token: _token,
        data:  _data,
      })
    }).then(function(response) {
      return response.json();
    });
}


export const getUsers = (_token) => {
  return fetch(ENV.API_URL+'/users/all', {
    method: 'POST',
    headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    },
    body: JSON.stringify({
    api_token: _token,
    }) 
  }).then(function(response) {
    return response.json();
  });
}


export const getOrdersUsers = (_token, _date) => {
  return fetch(ENV.API_URL+'/users/orders/all', {
    method: 'POST',
    headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    },
    body: JSON.stringify({
    api_token: _token,
    date: _date
    }) 
  }).then(function(response) {
    return response.json();
  });
}



export const getUsersReview = (_token) => {
  return fetch(ENV.API_URL+'/users/order/get/reviewall', {
    method: 'POST',
    headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    },
    body: JSON.stringify({
    api_token: _token,
    }) 
  }).then(function(response) {
    return response.json();
  });
}


 

export const getUser= (_uid) => {
  return fetch(ENV.API_URL+'/users/id/'+_uid, {
    method: 'GET'
  }).then((response) => response.json());
}



export const getOrders = (_token, _id) => {
  return fetch(ENV.API_URL + '/admin/users/order/all', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _token,
      user_id: _id,
    })
  }).then(function (response) {
    return response.json();
  });
}

export const getReviews = (_token, _id ) => {
  return fetch(ENV.API_URL + '/admin/users/order/get/review', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _token,
      user_id: _id,
    })
  }).then(function (response) {
    return response.json();
  });
}


export const updateDelivery = (_token, _id, _delivery) => {
  return fetch(ENV.API_URL + '/users/order/add/delivery', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _token,
      order_id: _id,
      deliverys: _delivery
    })
  }).then(function (response) {
    return response.json();
  });
}

export const updateChnage = (_token, _uid, _id, _changes) => {
  return fetch(ENV.API_URL + '/users/order/add/change', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _token,
      user_id: _uid,
      order_id: _id,
      changes: _changes
    })
  }).then(function (response) {
    return response.json();
  });
}

