import React, { Component } from 'react';

import checkIcon from '../../../assets/imgs/ui/check.svg';

class UiCheckList extends Component {

  state = {
    activeCheck: null,
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(nProps, nState) {
    if (this.props.activeCheck != undefined && this.props.activeCheck && nProps.activeCheck != this.state.activeCheck) {
      this.setState({ activeCheck: this.props.activeCheck });
    }
    return true;
  }

  setValue = (val) => {
    this.setState({ activeCheck: val });
    this.props.callBack(val);
  }

  render() {
    var checkList = this.props.checkList.map((item, index) => {
      return (
        <button key={index} className="check-item" onClick={() => this.setValue(item.value)}>
          <div className={"check-btn" + (this.state.activeCheck == item.value ? " active" : "")}>
            {this.state.activeCheck == item.value ?
              <img src={checkIcon} /> : null
            }
          </div>
          {item.label && item.label != '' ?
            <button className="check-name"><span>{item.label}</span></button> : null
          }
        </button>
      );
    });
    return (
      <div className="check-list">
        {checkList}
      </div>
    );
  }
}
export default UiCheckList;