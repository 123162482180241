import React, { Component } from 'react';
import ReactWOW from 'react-wow';

import instagramIcon from '../../../assets/imgs/social/instagram.svg';
import facebookIcon from '../../../assets/imgs/social/facebook.svg';
import twitterIcon from '../../../assets/imgs/social/twitter.svg';

class UiFooter extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <section className="contacts pattern">
        <div className="contacts-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <ReactWOW delay="0.2s" animation="fadeInUp">
                  <div className="logo">
                    <img className="footer-logo" src={require('../../../assets/imgs/logo-img.svg')} />
                  </div>
                  <p>{global.locale.foot_text_logo}</p>
                </ReactWOW>
              </div>
              <div className="col-md-4">
                <ReactWOW delay="0.4s" animation="fadeInUp">
                  <h4>{global.locale.footer_adress}:</h4>
                  <p>{global.locale.footer_adress_text_0}</p>
                  <p>{global.locale.footer_adress_text_1}</p>
                  <p>{global.locale.footer_adress_text_2}</p>
                  <p>{global.locale.footer_adress_text_3}</p>
                  <h4>{global.locale.footer_phone}:</h4>
                  <p><a href={"tel:" + global.locale.footer_phone_num}>{global.locale.footer_phone_num}</a></p>
                  <p>{global.locale.footer_adress_text_4}</p>
                </ReactWOW>
              </div>
              <div className="col-md-4">
                <ReactWOW delay="0.6s" animation="fadeInUp">
                <div className="social-wrap">
                    <a href={"https://www.instagram.com/ikitchen.cz"} className="btn btn-icon">
                      <img src={instagramIcon} />
                    </a>
                    <a href={"https://www.facebook.com/iKitchen.cz"} className="btn btn-icon">
                      <img src={facebookIcon} />
                    </a>
                    <a className="btn btn-icon">
                      <img src={twitterIcon} />
                    </a>
                  </div>
                </ReactWOW>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container">
            <ReactWOW delay="0.2s" animation="fadeInUp">
              <p><span>iKitchen</span> © All Rights Reserved - 2020</p>
            </ReactWOW>
          </div>
        </footer>
      </section>
    );
  }
}
export default UiFooter;