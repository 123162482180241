import React, { Component } from 'react';

import leftIcon from '../../../assets/imgs/ui/left-round.svg';
import leftIconActive from '../../../assets/imgs/ui/left-round-active.svg';
import leftIconDis from '../../../assets/imgs/ui/left-round-gray.svg';
import rightIcon from '../../../assets/imgs/ui/right-round.svg';
import rightIconActive from '../../../assets/imgs/ui/right-round-active.svg';
import rightIconDis from '../../../assets/imgs/ui/right-round-gray.svg';

class UiSelectScroll extends Component {

  state = {
    selectValue: '166',
    currentIndex: 0,
  }

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.selectValue != undefined && this.props.selectValue) {
      this.setState({ selectValue: this.props.selectValue });
    }
  }
  componentDidUpdate(nProps, nState) {

    if (nProps.clear != undefined && nProps.clear) {
      this.setState({ selectValue: null });
      this.props.clearCallBack(!nProps.clear);
    }
    if (this.props.selectValue != undefined && this.props.selectValue && nProps.selectValue != this.state.selectValue) {
      this.setState({ selectValue: this.props.selectValue });
    }
    return true;
  }

  setValue = (val) => {
    this.setState({ selectValue: val });
    this.props.callBack(val);
  }

 
  render() {
    var SelectItems = this.props.selectItems.map((item, index) => {
      return (
        <button key={index} onClick={() => this.setValue(item) } className="select-list-item">{item.text}</button>
      );
    });

    return (
      <button className="delivery-first" onClick={() => this.setState({ selectOpen: !this.state.selectOpen })}>
        <div className="delivery-first-inner">
          <p>{this.props.selectedText}</p>
          <img src={require('../../../assets/imgs/ui/select-arrow.svg')} />
        </div>
        <div className={"select-list" + (this.state.selectOpen ? " active" : "")}>
          {SelectItems}
        </div>
      </button>
    );
  }
}
export default UiSelectScroll;