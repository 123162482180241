import React, { Component } from 'react';

import checkIcon from '../../../assets/imgs/ui/select-arrow.svg';

class UiDeliveryItem extends Component {

  state = {
    active: false
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(nProps, nState) {

  }

  render() {
    return (
      <div className="delivery-item">
        <div className="item-title" style={{ cursor: "pointer" }} onClick={() => {
          this.setState({ active: !this.state.active }, () => {
          });
        }}>
          <p>{this.props.day}</p>
          <img src={checkIcon} />
        </div>
        {this.state.active ?
          <div className="item-info">
            <div className="row">
              <div className="col-md-6">
                <p><span>{global.locale.delivery_item_delivery} {this.props.date}</span></p>
                <p><strong>{this.props.dayTime == 0 ? global.locale.delivery_item_day : global.locale.delivery_item_evening} с {this.props.timeRange}</strong></p>
                <h3>{global.locale.delivery_item_adress}</h3>
                <p>{this.props.adress}</p>
                <button className="delivery-btn" onClick={() => this.props.callBackInit()} >{global.locale.delivery_item_btn_change}</button>
              </div>
              <div className="col-md-6">
                <h3>{global.locale.delivery_item_wishes}</h3>
                <div className="input-group">
                  <textarea placeholder={this.props.comment} disabled={true} className="form-control"></textarea>
                </div>
              </div>
            </div>
          </div>
          : null
        }
      </div>
    );
  }
}
export default UiDeliveryItem;