import React, { Component } from 'react';

import UiSwitch from '../../../components/ui/forms/UiSwitch';
import UiSelectScroll from '../../../components/ui/forms/UiSelectScroll';
import masterIcon from '../../../assets/imgs/profile/mastercard.svg';
import visaIcon from '../../../assets/imgs/profile/visa.svg';
import UiBtnGreen from '../buttons/UiBtnGreen';

class UiModalChangeAdress extends Component {

    state = {
        activePay: 0,
        dp: 0,
        time: "7:00 - 12:00",
        comment: "",
    }
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) { }

    render() {
        return (
            <div className={"modal-wrap " + (this.props.show ? "show" : "hide")}>
                <div className="modal-payment">
                    <div className="card-title">
                        <h3> </h3>
                        <button onClick={() => this.props.closeCallBack()} className="card-title-btn"><img src={require('../../../assets/imgs/ui/close.svg')} /></button>
                    </div>
                    <div className="payment-wrap">


                        <div className="form-group">
                            <label>{global.locale.UiModalChangeAdress_street}:</label>
                            <input type="text" id="searchTextField" className="form-control" onChange={(e) => this.setState({ street: e.target.value })} />
                        </div>
                        <div className="row">
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>{global.locale.UiModalChangeAdress_city}:</label>
                                    <input type="text" id="cityTextField" className="form-control" disabled={true} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{global.locale.UiModalChangeAdress_index}:</label>
                                    <input type="text" id="codeTextField" className="form-control" disabled={true} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 justify-content-end">
                                <UiSwitch callBack={(val) => this.setState({ dp: val })} />
                            </div>
                            <div className="col-md-4">
                                <UiSelectScroll 
                                    selectedText={this.state.time} 
                                    selectItems={this.props.firstTimeArr} 
                                    callBack={(val) => {
                                        this.setState({ time: val.value });
                                    }} 
                                />
                            </div>
                            <div className="col-md-6">
                                <label>{global.locale.UiModalChangeAdress_wishes}</label>
                                <div className="input-group">
                                    <textarea defaultValue={this.props.comment} onChange={(e) => this.setState({ comment: e.target.value })} className="form-control"></textarea>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <UiBtnGreen btnText={global.locale.UiModalChangeAdress_btn_save}  onClick={() => this.props.callBack(this.state.dp, this.state.time, this.state.comment)} />
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        );
    }
}
export default UiModalChangeAdress;