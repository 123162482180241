import React, { Component } from 'react';

class UiTextArea extends Component {

  state = {
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(nProps,nState){
    if(nProps.clear != undefined && nProps.clear){
      this.setState({ selectValue: null });
      this.props.clearCallBack(!nProps.clear);
    }
    if(this.props.value != undefined && this.props.value && nProps.value != this.state.selectValue){
      this.setState({ selectValue: this.props.value });
    }
    return true;
  }


  render() {
    return (
      <div className="form-group">
        {this.props.label ? <label>{this.props.label}</label> : null}
        <textarea className="form-control" rows="3" placeholder={this.props.defaultValue}  value={this.props.value ? this.state.selectValue : null} onChange={e => {
            this.props.callBack(  e.target.value) ;
            if(this.props.value) this.setState({selectValue: e.target.value })
          }
        } ></textarea>
      </div>
    );
  }
}
export default UiTextArea;