import React, { Component } from 'react';

import addIcon from '../../../assets/imgs/profile/plus.svg';

import UiBtnGreenOutline from '../buttons/UiBtnGreenOutline.js';
import Env from '../../../services/Env';

class UiPlanCard extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <div className="plan-card">
        {this.props.addItem ?
          <div className="plan-card-add">
            <button className="add-button">
              <img src={addIcon} />
              <h3>{global.locale.plan_card_add} {this.props.lanchType}</h3>
            </button>
          </div>
          :
          <div className="plan-card-wrap">
            <div className="plan-card-img" style={{ backgroundImage: "url(" +   Env.PUBLIC_URL + this.props.image.uri  + ")" }}>
              {this.props.modalAddCard ?
                null :
                <span className="info-mark">{this.props.day}</span>
              }
            </div>
            <div className="plan-card-info">
              <div className="plan-card-info-top">
                {this.props.modalAddCard ?
                  null :
                  <p className="info-type"><strong>{this.props.lanchType}</strong></p>
                }
                {this.props.modalAddCard ?
                  null :
                  <div className="info-btns">
                    <div className="btn-bar">
                      <button className="remove-btn" onClick={() => this.props.callBackRemove()} >{global.locale.plan_card_btn_delete}</button>
                      <button className="change-btn">{global.locale.plan_card_btn_swap}</button>
                    </div>
                  </div>
                }
                <div className="plan-card-title-wrap">
                  <h3>{this.props.title}  </h3>
                  <p className="info-comp">{global.locale.plan_card_composition}: {this.props.ingredient}</p>
                </div>
                <p className="info-about">{this.props.about}</p>
                <div className="info-status">
                  <div>
                    <span>{global.locale.plan_card_in_100_g}: </span><strong>{this.props.ccal} {global.locale.plan_card_kCal} | {global.locale.plan_card_B}:{this.props.protein}, {global.locale.plan_card_J}:{this.props.fats}, {global.locale.plan_card_U}:{this.props.carbo} </strong>
                  </div>
                </div>
              </div>
              {this.props.modalAddCard && this.props.callBackSelect ?
                <div className="card-add-price">
                  <p>{global.locale.plan_card_cost}: <strong>{this.props.price} {global.locale.plan_card_currency}</strong></p>
                  <UiBtnGreenOutline btnText={global.locale.plan_card_btn_pick} onClick={() => this.props.callBackSelect()} btnsm black />
                </div>
                : null
              }
            </div>
          </div>
        }
      </div>
    );
  }
}
export default UiPlanCard;