import React, { Component } from 'react';
import ReactWOW from 'react-wow';

import checkIcon from '../../assets/imgs/ui/check.svg';

import UiAlert from '../../components/ui/modals/UiAlert.js';
import UiLoader from '../../components/ui/modals/UiLoader';
import UiBtnGreen from '../../components/ui/buttons/UiBtnGreen.js';
import UiBtnGreenOutline from '../../components/ui/buttons/UiBtnGreenOutline.js';
import UiBtnIcon from '../../components/ui/buttons/UiBtnIcon.js';
import UiBtnPink from '../../components/ui/buttons/UiBtnPink.js';
import UiBtnPinkOutline from '../../components/ui/buttons/UiBtnPinkOutline.js';
import UiCheckList from '../../components/ui/forms/UiCheckList.js';
import UiDinnerCard from '../../components/ui/cards/UiDinnerCard';
import UiFormInput from '../../components/ui/forms/UiFormInput.js';
import UiFooter from '../../components/ui/footer/UiFooter.js';
import UiHeader from '../../components/ui/header/UiHeader.js';
import UiPlansTab from '../../components/ui/tabs/UiPlansTab.js';
import UiTabPlanCard from '../../components/ui/cards/UiTabPlanCard.js';
import UiTextArea from '../../components/ui/forms/UiTextArea.js';

import { en } from '../../i18n/en.js';
import { ru } from '../../i18n/ru.js';
import { ch } from '../../i18n/ch.js';
import { translateObject } from '../../i18n/locale.js';

import { loadUser, saveUser } from '../../services/Users';
import { removeDishes, getAllDishes, updateDishes, addDishes, addFile } from '../../services/Plans';
import { retrieveData } from '../../services/Storage';
import Env from '../../services/Env';

const defaultItem = {
  title_ch: null,
  description_ch: null,
  title_ru: null,
  description_ru: null,
  title_en: null,
  description_en: null,
  calories: null,
  protein: null,
  fats: null,
  carbohydrates: null,
  weight: null,
  price: null,
};

class FoodsPage extends Component {

  state = {
    user: null,
    selected: defaultItem,
    editable: null,
    searchLine: '',
    active: 0,
    pageIndex: 10,

    dishes: [],
    dishesArchiv: [],
  }

  constructor(props) {
    super(props);

    let _lang = retrieveData("ikLocale");
    if (_lang == null) _lang = 'ch';
    if (_lang == 'ch') global.locale = ch;
    if (_lang == 'ru') global.locale = ru;
    if (_lang == 'en') global.locale = en;
    this.setState({ lang: _lang });

  }

  componentDidMount() {
    this._load();
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > 180) this.setState({ showMenuBar: true }); else this.setState({ showMenuBar: false });
     
      if (this._isBottom(document.getElementsByClassName('food-list')[0])) {
       this.setState({pageIndex: this.state.pageIndex + 10 })
      }
    });


  }

  componentWillUnmount() {
    document.removeEventListener('scroll');
  }

  _load() {
    let _user = loadUser();
    if (_user == null) this.props.history.push("/login");
    this.setState({ user: _user, loader: true });

    getAllDishes(_user.api_token).then((res) => {
      console.log(res);
      if (res.success) {
        this.setState({ dishes: res.response.reverse(), dishesArchiv: res.response.reverse(), loader: false });
      } else {
        saveUser(null);
        this.props.history.push("/login");
      }

    })
  }

  _isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight + 200;
  }

  _alert(_title = null, _text) {
    this.setState({
      showAlert: true,
      alertTitle: _title,
      alertText: _text
    })
  }

  _updateStateVal(_tt, _val) {
    let st = this.state.selected;
    if (st == null) st = {};
    st[_tt] = _val;
    this.setState({ selected: st })
  }

  _search(val) {
    this.setState({ searchLine: val })
  }

  _checkInclude(substring) {
    if (substring) {
      if (this.state.searchLine) {
        let str = this.state.searchLine;
        return substring.toString().toLowerCase().indexOf(str.toLowerCase()) !== -1;
      }
    }
  }

  upload(e) {
    this.setState({ loader: true });
    addFile(this.state.selectedFileName, 0).then((res) => {
      console.log(res);
      if (res.success) {
        this._updateStateVal('image', res.response);
        this.update();
      } else {
        this._alert(res.response);
      }
    })
  }

  add() {
    if (this.state.selected) {
      addDishes(this.state.user.api_token, this.state.selected).then((res) => {
        console.log(res);
        if (res.success) {
          this.setState({selected: defaultItem});
          this._load();
        } else {
          alert(global.locale.food_alert_pole_required);
        }

      })
    } else {

      alert(global.locale.food_alert_pole_required);
    }

  }

  update() {
    updateDishes(this.state.user.api_token, this.state.selected).then((res) => {
      console.log(res);
      this.setState({ selected: defaultItem, editable: null });
      this._load();
    })
   
  }

  remove(_id) {
    removeDishes(this.state.user.api_token, _id).then((res) => {
      console.log(res);
      this.setState({ selected: defaultItem, editable: null });
      this._load();
    })
   
  }

  _addImageToPlan() {
    this.setState({ loader: true });
    addFile(this.state.selectedFileName, 0).then((res) => {
      this.setState({ loader: false });
      console.log(res);
      if (res.success) {
        this._updateStateVal('image', res.response);

      } else {
        this._alert(res.response);
      }
    })
  }


  render() {
    var list = this.state.dishes.map((item, index) => {

      if ((this.state.searchLine == '') ||
        (this.state.searchLine != '' && (this._checkInclude(item.outer_id) || this._checkInclude(item.title_ch) || this._checkInclude(item.title_ru) || this._checkInclude(item.title_en)))
      ) {
        return (
          <tr key={index} style={{
            display: (
              (
                (this.state.searchLine == '' && this.state.pageIndex > index) ||
                (this.state.searchLine != '' && (this._checkInclude(item.outer_id) || this._checkInclude(item.title_ch) || this._checkInclude(item.title_ru) || this._checkInclude(item.title_en)))
              ) ? "" : "none")
          }} >
            <td>
              <div className="table-image-wrap">
                {this.state.editable == item.id ?
                  <div className="photo-choose">
                    <div className="photo" style={{ backgroundImage: 'url(' + Env.PUBLIC_URL + item.image + ')' }} ></div>

                    <label>
                      <div>{global.locale.food_choose_photo}
                        <input type="file" style={{ display: 'none' }} onChange={(e) => {
                          this.setState({ selectedFileName: e.target.files[0] }, () => {
                            this.upload();
                          })
                        }} />
                      </div>
                    </label>

                    <UiCheckList
                      callBack={(res) => {
                        this._updateStateVal('category_id', res)
                      }}
                      checkList={[{ value: 1, label: 'Main' }, { value: 0, label: 'Mini Eshop' },]}
                    />

                    <div>ID Admin: <UiFormInput defaultValue={item.outer_id} callBack={(val) => this._updateStateVal('outer_id', val)} /> </div>

                  </div>
                  :
                  <div className="photo-choose">
                    <div className="photo" style={{ backgroundImage: 'url(' + Env.PUBLIC_URL + item.image + ')' }} ></div>
                    {item.category_id == 0 ? <div className="check-btns"><button className="check-btn active">MiniEShop</button> </div> : null}
                  </div>
                }
              </div>
            </td>
            <td>
              {this.state.editable == item.id ?
                <div>
                  <div>Čeština: <UiFormInput defaultValue={item.title_ch} callBack={(val) => this._updateStateVal('title_ch', val)} /> </div>
                  <div>Русский: <UiFormInput defaultValue={item.title_ru} callBack={(val) => this._updateStateVal('title_ru', val)} /> </div>
                  <div>English: <UiFormInput defaultValue={item.title_en} callBack={(val) => this._updateStateVal('title_en', val)} /> </div>
                </div>
                :
                <div>
                  {item.title_ch ? <span>ch: {item.title_ch} <br /></span> : ""}
                  {item.title_ru ? <span>ru: {item.title_ru} <br /></span> : ""}
                  {item.title_en ? <span>en: {item.title_en} <br /></span> : ""}
                </div>
              }
            </td>
            <td>
              {this.state.editable == item.id ?
                <div>
                  <div>Čeština: <UiTextArea defaultValue={item.description_ch} callBack={(val) => this._updateStateVal('description_ch', val)} />  </div>
                  <div>Русский: <UiTextArea defaultValue={item.description_ru} callBack={(val) => this._updateStateVal('description_ru', val)} />  </div>
                  <div>English: <UiTextArea defaultValue={item.description_en} callBack={(val) => this._updateStateVal('description_en', val)} />  </div>
                </div>
                : <div>
                  {item.description_ch ? <span>ch: {item.description_ch} <br /></span> : ""}
                  {item.description_ru ? <span>ru: {item.description_ru} <br /></span> : ""}
                  {item.description_en ? <span>en: {item.description_en} <br /></span> : ""}
                </div>}
            </td>
            <td>
              {this.state.editable == item.id ? <UiFormInput defaultValue={item.weight} callBack={(val) => this._updateStateVal('weight', val)} /> : <span>{item.weight} {global.locale.food_g}</span>}
            </td>
            <td>
              {this.state.editable == item.id ? <UiFormInput defaultValue={item.calories} callBack={(val) => this._updateStateVal('calories', val)} /> : <span>{item.calories} {global.locale.food_kcal}</span>}
            </td>
            <td>
              {this.state.editable == item.id ? <UiFormInput defaultValue={item.protein} callBack={(val) => this._updateStateVal('protein', val)} /> : <span>{item.protein}</span>}
            </td>
            <td>
              {this.state.editable == item.id ? <UiFormInput defaultValue={item.fats} callBack={(val) => this._updateStateVal('fats', val)} /> : <span>{item.fats}</span>}
            </td>
            <td>
              {this.state.editable == item.id ? <UiFormInput defaultValue={item.carbohydrates} callBack={(val) => this._updateStateVal('carbohydrates', val)} /> : <span>{item.carbohydrates}</span>}
            </td>

            <td>
              {this.state.editable == item.id ? <UiFormInput defaultValue={item.price} callBack={(val) => this._updateStateVal('price', val)} /> : <span>{item.price} {global.locale.food_currency}</span>}
            </td>
            <td>
              <div className="table-btn-wrap">
                {this.state.editable == item.id ?
                  <UiBtnIcon onClick={() => this.update()} color="#5FB961" icon={require('../../assets/imgs/ui/technology.svg')} mr2 /> :
                  <UiBtnIcon onClick={() => this.setState({ editable: item.id, selected: item })} color="#4267B2" icon={require('../../assets/imgs/ui/edit.svg')} mr2 />
                }
                <UiBtnIcon color="#f9650b" onClick={() => this.remove(item.id)} icon={require('../../assets/imgs/ui/delete.svg')} />
              </div>
            </td>
          </tr>
        )
      }

    })
    return (
      <div ref={this.divPage} id="page">
        <UiLoader show={this.state.loader} />
        <UiAlert
          show={this.state.showAlert}
          title={this.state.alertTitle}
          text={this.state.alertText}
          callBack={() => this.setState({ showAlert: false })}
          leftBtnText="Ок"
        />
        <div className={"wrapper" + (this.state.showMenuBar ? ' scroll' : '')}>
          <div className="content">
            <UiHeader />
            <section className="section bg-gray">
              <div className="container">
                <div className="add-food-form">
                  <div className="row">
                    <div className="col-md-2">
                      <h3>{global.locale.food_add_dishes}</h3>

                      <div className="photo-choose">
                        <label class="btn-wrapper pink-button-outline btn-block">
                          <div class="btn-back">
                            <div class="btn-shadow black-shadow"></div>
                          </div>
                          { this.state.selected?.image ? <div className="photo" style={{ backgroundImage: 'url(' + Env.PUBLIC_URL + this.state.selected?.image + ')' }} ></div> : null}

                          <input type="file" style={{ display: 'none' }} onChange={(e) => {
                            this.setState({ selectedFileName: e.target.files[0] }, () => {
                              this._addImageToPlan();
                            })
                          }} />
                          <span class="btn btn-sm btn-pink-outline btn-block">{global.locale.food_choose_photo}</span>
                        </label>
                      </div>

                    </div>
                    <div className="col-md-3">
                      <div className="lang-setting">
                        <p>{global.locale.food_choose_lang}:</p>
                        <div className="check-btns">
                          <button onClick={() => this.setState({ active: 0 })} className={"check-btn" + (this.state.active == "0" ? " active" : "")}>Čeština</button>
                          <button onClick={() => this.setState({ active: 1 })} className={"check-btn" + (this.state.active == "1" ? " active" : "")}>Русский</button>
                          <button onClick={() => this.setState({ active: 2 })} className={"check-btn" + (this.state.active == "2" ? " active" : "")}>English</button>
                        </div>
                      </div>

                      <div className={this.state.active == 0 ? "show" : "hide"}>
                        <UiFormInput label={global.locale.food_name_dish + " CH"} value={this.state.selected?.title_ch} callBack={(val) => this._updateStateVal('title_ch', val)} />
                        <UiTextArea label={global.locale.food_composit_dish + " CH"} value={this.state.selected?.description_ch} callBack={(val) => this._updateStateVal('description_ch', val)} />
                      </div>

                      <div className={this.state.active == 1 ? "show" : "hide"}>
                        <UiFormInput label={global.locale.food_name_dish + " RU"} value={this.state.selected?.title_ru} callBack={(val) => this._updateStateVal('title_ru', val)} />
                        <UiTextArea label={global.locale.food_composit_dish + " RU"} value={this.state.selected?.description_ru} callBack={(val) => this._updateStateVal('description_ru', val)} />
                      </div>

                      <div className={this.state.active == 2 ? "show" : "hide"}>
                        <UiFormInput label={global.locale.food_name_dish + " EN"} value={this.state.selected?.title_en} callBack={(val) => this._updateStateVal('title_en', val)} />
                        <UiTextArea label={global.locale.food_composit_dish + " EN"} value={this.state.selected?.description_en} callBack={(val) => this._updateStateVal('description_en', val)} />
                      </div>


                    </div>
                    <div className="col-md-2">
                      <UiFormInput type="number" label={global.locale.food_kcal_in_100} value={this.state.selected?.calories}  callBack={(val) => this._updateStateVal('calories', val)} />
                      <UiFormInput type="number" label={global.locale.food_B_in_100} value={this.state.selected?.protein}  callBack={(val) => this._updateStateVal('protein', val)} />
                      <UiFormInput type="number" label={global.locale.food_J_in_100} value={this.state.selected?.fats}  callBack={(val) => this._updateStateVal('fats', val)} />
                      <UiFormInput type="number" label={global.locale.food_U_in_100} value={this.state.selected?.carbohydrates}  callBack={(val) => this._updateStateVal('carbohydrates', val)} />
                    </div>
                    <div className="col-md-3">
                      <UiFormInput type="number" label={global.locale.food_serving_weight} value={this.state.selected?.weight}  callBack={(val) => this._updateStateVal('weight', val)} />
                      <UiFormInput type="number" label={global.locale.food_price} value={this.state.selected?.price}  callBack={(val) => this._updateStateVal('price', val)} />
                    </div>
                    <div className="col-md-2">
                      <UiBtnGreen btnText={global.locale.food_btn_add} onClick={() => this.add()} btnsm block black />
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid">
                <div className="food-list">

                  <div className="search-form">
                    <UiFormInput label={global.locale.food_dishes_search + ":"} callBack={(val) => this._search(val)} />
                  </div>

                  <table className="table table-bordered">
                    <tr>
                      <th>{global.locale.food_image}</th>
                      <th>{global.locale.food_name}</th>
                      <th>{global.locale.food_composition}</th>
                      <th className="table-100">{global.locale.food_weight}</th>
                      <th className="table-100">{global.locale.food_calories}</th>
                      <th className="table-100">{global.locale.food_B}</th>
                      <th className="table-100">{global.locale.food_J}</th>
                      <th className="table-100">{global.locale.food_U}</th>
                      <th className="table-100">{global.locale.food_cost}</th>
                      <th>{global.locale.food_control}</th>
                    </tr>
                    {list}
                  </table>
                </div>
              </div>
            </section>
            <UiFooter />
            <button className="top-button" onClick={() => this._scrollToTargetAdjusted("header")}>
              <img src={require('../../assets/imgs/ui/up-arrow.svg')} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default FoodsPage;