import React, { Component } from 'react';

class UiFromTextAreaBlur extends Component {

  state = {
    selectValue: null,
    isEdit: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if ( this.props.value) { 
      this.setState({ selectValue: this.props.value });
    }
  }

  componentDidUpdate(nProps, nState) {
    if (nProps.clear != undefined && nProps.clear) {
      this.setState({ selectValue: null });
      this.props.clearCallBack(!nProps.clear);
    }

    if (  !this.state.isEdit && this.props.value && nProps.value != this.state.selectValue) {
        this.setState({ selectValue: this.props.value });
      }
 
    return true;
  }


  render() {
    return (
      
        <textarea type={this.props.type}   value={this.props.value ? this.state.selectValue : null} type={this.props.type}
         
          onChange={e => {
              this.setState({isEdit: true})
            if (this.props.callBack) this.props.callBack(e.target.value);
            if (this.props.value) {
 
              this.setState({ selectValue: e.target.value })
            }
          }
          }
          onBlur={e => {
            if (this.props.onBlur) this.props.onBlur(e.target.value);
            if (this.props.value) this.setState({ selectValue: e.target.value })
            this.setState({isEdit: false})
          }
          }

        />
      
    );
  }
}
export default UiFromTextAreaBlur;