import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { HomePage, LoginPage, FoodsPage, OrdersPage, CourierOrdersPage, UsersPage, PlansPage } from '../';

class Container extends Component {
  render() {
    return (
      <div>
        <Switch>
          <BrowserRouter basename="/">
            <Route exact path='/' component={LoginPage} />
            <Route exact path='/login' component={LoginPage} />
            <Route exact path='/home' component={HomePage} />
            <Route exact path='/foods' component={FoodsPage} />
            <Route exact path='/plans/:uid/:pid' component={PlansPage} />
            <Route exact path='/orders' component={OrdersPage} />
            <Route exact path='/courier' component={CourierOrdersPage} />
            <Route exact path='/users' component={UsersPage} />
            
          </BrowserRouter>  
        </Switch>
      </div>
    );
  }
}

export default Container;