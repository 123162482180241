import React, { Component } from 'react';

class UiPlansTab extends Component {

  state = {
    activeTab: 1,
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(nProps, nState) {
    if (this.props.activeTab != undefined && this.props.activeTab && nProps.activeTab != this.state.activeTab) {
      this.setState({ activeTab: this.props.activeTab });
    }
    return true;
  }

  setValue = (val) => {
    this.setState({ activeTab: val });
    this.props.callBack(val);
  }

  render() {
    var tabList = this.props.tabList.map((item, index) => {
      return (
        <button
          key={index}
          className={"plans-tab-button" + (this.state.activeTab == item.value ? " active" : "") + (item.green ? " green-tab" : "")}
          onClick={() => this.setValue(item.value)}
        >
          <span>{item.name}</span>
        </button>
      );
    });
    return (
      <div className="plans-tab">
        {tabList}
      </div>
    );
  }
}
export default UiPlansTab;