import React, { Component } from 'react';

import UiAlert from '../../components/ui/modals/UiAlert.js';
import UiLoader from '../../components/ui/modals/UiLoader';
import UiHeader from '../../components/ui/header/UiHeader.js';
import UiFooter from '../../components/ui/footer/UiFooter.js';
import UiBtnGreen from '../../components/ui/buttons/UiBtnGreen.js';
import UiBtnGreenOutline from '../../components/ui/buttons/UiBtnGreenOutline.js';

import { en } from '../../i18n/en.js';
import { ru } from '../../i18n/ru.js';
import { ch } from '../../i18n/ch.js';
import { translateObject } from '../../i18n/locale.js';

import { loginUser, saveUser } from '../../services/Users';
import { storeData, retrieveData } from '../../services/Storage';

const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);

class LoginPage extends Component {

  state = {
    regType: 0,
    checkActive: false,
    login: "",
    password: "",
  }

  constructor(props) {
    super(props);

    let _lang = retrieveData("ikLocale");
    console.log(_lang)
    if (_lang == null) _lang = 'ch';
    if (_lang == 'ch') global.locale = ch;
    if (_lang == 'ru') global.locale = ru;
    if (_lang == 'en') global.locale = en;
    this.setState({ lang: _lang });

  }

  changeLang(val) {
    let _lang = 'ch';
    if (val == 0) _lang = 'ru';
    if (val == 1) _lang = 'ch';
    if (val == 2) _lang = 'en';
    if (_lang == 'ch') global.locale = ch;
    if (_lang == 'ru') global.locale = ru;
    if (_lang == 'en') global.locale = en;
    this.setState({ lang: _lang });
    storeData("ikLocale", _lang);
    //this._load();
  }

  componentDidMount() {
    this._load();
  }

  _alert(_title = null, _text) {
    this.setState({
      showAlert: true,
      alertTitle: _title,
      alertText: _text
    })
  }

  _load() {

  }

  login() {
    if (this.state.login.length > 0 && this.state.password.length > 0) {
      this.setState({ loader: true });

      loginUser(this.state.login, this.state.password).then((res) => {
        console.log(res.response);
        this.setState({ loader: false });
        if (res.success) {
          if (res.response) {
            saveUser(res.response);
            if(res.response.type == 2 ) {
              this.props.history.push("/courier")
            } else  if(res.response.type == 1 || res.response.type == 0 ) {
              this.props.history.push("/home")
            }
           
          } else {
            this._alert(global.locale.login_alert_log_error);
          }
        }
      })
    } else {
      this._alert(global.locale.login_alert_filled_error);
    }
  }

  render() {
    return (
      <div ref={this.divPage} id="page" className="login-page">
        <UiLoader show={this.state.loader} />
        <UiAlert
          show={this.state.showAlert}
          title={this.state.alertTitle}
          text={this.state.alertText}
          callBack={() => this.setState({ showAlert: false })}
          leftBtnText="Ок"
        />
        <div className={"wrapper" + (this.state.showMenuBar ? ' scroll' : '')} style={{ minHeight: this.props.windowHeight + 'px' }}>
          <div className="content">
            <UiHeader isLogin menuHidden={true} langCallBack={(val) => this.changeLang(val)} />
            <section className="login-info">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-6 col-md-8">
                    <div className="card login-card">
                      <div className="form">
                        <div className="form-group">
                          <label>{global.locale.login_mail}:</label>
                          <input type="tel" className="form-control" onChange={e => this.setState({ login: e.target.value })} />
                        </div>
                        <div className="form-group">
                          <label>{global.locale.login_pass}:</label>
                          <input type="password" className="form-control" onChange={e => this.setState({ password: e.target.value })} />
                        </div>
                        <div className="form-group" style={{ display: 'flex', justifyContent: 'flex-end' }}>

                        </div>
                        <div className="form-group btn-wrap">
                          <UiBtnGreen onClick={() => this.login()} btnText={global.locale.login_btn_enter} btnsm mr2 mb2 block black />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <UiFooter />
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;