import React, { Component } from 'react';
import ReactWOW from 'react-wow';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import checkIcon from '../../assets/imgs/ui/check.svg';

import UiBtnGreen from '../../components/ui/buttons/UiBtnGreen.js';
import UiBtnGreenOutline from '../../components/ui/buttons/UiBtnGreenOutline.js';
import UiBtnPink from '../../components/ui/buttons/UiBtnPink.js';
import UiBtnPinkOutline from '../../components/ui/buttons/UiBtnPinkOutline.js';
import UiCheckList from '../../components/ui/forms/UiCheckList.js';
import UiDinnerCard from '../../components/ui/cards/UiDinnerCard';
import UiFormInput from '../../components/ui/forms/UiFormInput.js';
import UiFormInputBlur from '../../components/ui/forms/UiFormInputBlur.js';
import UiFromTextAreaBlur from '../../components/ui/forms/UiFromTextAreaBlur.js';

import UiFooter from '../../components/ui/footer/UiFooter.js';
import UiHeader from '../../components/ui/header/UiHeader.js';
import UiPlansTab from '../../components/ui/tabs/UiPlansTab.js';
import UiTabPlanCard from '../../components/ui/cards/UiTabPlanCard.js';
import UiTextArea from '../../components/ui/forms/UiTextArea.js';
import UiModalMenu from '../../components/ui/modals/UiModalMenu.js';

import UiLoader from '../../components/ui/modals/UiLoader';



import { en } from '../../i18n/en.js';
import { ru } from '../../i18n/ru.js';
import { ch } from '../../i18n/ch.js';
import { translateObject } from '../../i18n/locale.js';

import { formatDate, getWeekByNum, getDay, getFormatedCurrentDate, formatDateYMD } from '../../components/common/Date';
import { loadUser, getUsersReview, getOrdersUsers, loginUser, updateUser } from '../../services/Users';
import { getAllOrders, getAllOrdersFromDate, getDishes, editOrder, searchOrders } from '../../services/Orders';
import { getAllPlans, removeItemFromDay } from '../../services/Plans';
import { retrieveData, storeData } from '../../services/Storage';

import { renderXLS, printDelivery } from "./OrdersController";

class OrdersPage extends Component {

  state = {
    addNewPlanShow: false,
    calorieNum1Active: false,
    calorieNum2Active: false,
    calorieNum3Active: false,
    calorieNum4Active: false,
    calorieNum5Active: false,

    showDeliveryTable: false,

    loader: false,

    currentDate: getFormatedCurrentDate(),
    currentDateCurr: new Date(),
    searchLine: '',
    sortKey: '',

    tabIndex: 0,
    tabOrderId: 0,
    tabOrderCalloriesIndex: -1,
    tabOrderIndex: 0,

    excludeDaysArr: [],
    orders: [],
    currentOrders: [],
    allOrders: [],
    reviewsList: [],
    plansList: [
      { calorires: [] }
    ],
    caloriesList: [],
    ordersDishesList: [],
    ordersDishes: [],
    showedDishes: [],
  }

  constructor(props) {
    super(props);

    let _lang = retrieveData("ikLocale");
    if (_lang == null) _lang = 'ch';
    if (_lang == 'ch') global.locale = ch;
    if (_lang == 'ru') global.locale = ru;
    if (_lang == 'en') global.locale = en;
    this.setState({ lang: _lang });

  }

  componentDidMount() {

    let _tabIndex = retrieveData('ordersPageTabIndex');
    let _currentDate = retrieveData('ordersPageCurrentDate');
    console.log(_currentDate)

    this._load();

    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > 180) this.setState({ showMenuBar: true }); else this.setState({ showMenuBar: false });
    });
  }

  _getCountPlans(_id) {
    let count = 0;
    this.state.orders.map((item) => {
      if (item.plan.id == _id) count++;
    })
    return count;
  }

  _getCountCalories(_id) {
    let count = 0;
    this.state.orders.map((item) => {
      if (item.calorie.id == _id) count++;
    })
    return count;
  }

  _getUnic(_arr, _id) {
    let f = false, i = null;
    _arr.map((item, index) => {
      if (item.id == _id) {
        i = item;
        f = true;
      }
    });
    return i;
  }

  _getUnic2(_arr, _id) {
    let f = false, i = null;
    _arr.map((item, index) => {
      if (item.order == _id) {
        i = item;
        f = true;
      }
    });
    return i;
  }

  _hasChangeDelivery(_arr, _uDate) {
    let _date = formatDateYMD(_uDate);
    let f = null;
    _arr.map((item) => {
      if (item.day == _date) f = item;
    })
    return f;
  }


  _search(val) {

    this.setState({ searchLine: val }, () => {
      if (val == "") {
        this.setState({ orders: this.state.currentOrders })
      } else {
        let arr = [];
        this.state.allOrders.reverse().map((item) => {
          if (
            arr.length <= 20 && (this.state.searchLine != '' && (this._checkInclude(item.id) || (item.user ? this._checkInclude(item.user.name) : false) || this._checkInclude(item.user.lname) || this._checkInclude(item.user.mail)))
          ) {
            arr.push(item)
          }
        })
        this.setState({ orders: arr })
      }
    })

  }

  _checkInclude(substring) {
    if (substring) {
      if (this.state.searchLine) {
        let str = this.state.searchLine;
        return substring.toString().toLowerCase().indexOf(str.toLowerCase()) !== -1;
      }
    }
  }


  _showDishesByOrder(e, item) {
    console.log(item);
    e.preventDefault();
    this.state.orders.map((items) => {
      if (items.user.id == item.user) storeData('selectedUser', items.user);
    })
    storeData('ordersPageTabIndex', this.state.tabIndex);
    this.props.history.push("/plans/" + item.user + "/" + item.order);

  }

  _getCaloriesList(_plan) {
    return this.state.plansList.map((item, index) => {
      return item.calorires.map((item2, index2) => {
        return (<option key={index2} value={item2.id}>  {item.name}  - {item2.title_ch}</option>)
      })
    })
  }

  _getPlanList() {
    console.log(this.state.plansList);
    return this.state.plansList.map((item, index) => {
      return (<option key={index} value={item.value}>{item.name}</option>)
    })
  }

  _updateOrder(_index, _id, _start, _end, _size, _calories, _psize, _price, _priced, _pricee, _pricep, _exclude) {
    let isConfirm = window.confirm("Сохранить данные ?");
    if (isConfirm) {
      this.setState({ currentIndex: _index })



      editOrder(
        this.state.user.api_token, _id, _start, _end, 
        _size, _calories, _psize, _price, 
        _priced, _pricee, _pricep, _exclude
        ).then((res) => {
        console.log(res)

        if (res.response) {
          let arr = this.state.orders;
          arr.map((item) => {
            if (item.id == _id) {
              item.start_date = _start;
              item.end_date = _end;
              item.program_size = _psize;
              item.calorie.id = _calories;
              item.size = _size;
              item.price = _price;
              item.price_delivery = _priced;
              item.price_exclude = _pricee;
              item.price_promocode = _pricep;
              item.exclude = _exclude;
            }
          })
          this.setState({ orders: arr, currentIndex: null })
        }

        //this._load()
      })
    } else {

    }

  }

  _updateUserComment(_user, _exclude, _comment) {
    let isConfirm = window.confirm("Сохранить данные ?");
    if (isConfirm) {
      let data = _user;
      data['exclude'] = _exclude;
      data['comment'] = _comment;
      updateUser(this.state.user.api_token, data).then((res) => {
        console.log(res)
        //this._load()
      })
    }
  }



  _loadCurr() {
    this.setState({ loader: true });
    let _date = new Date(this.state.currentDateCurr.getTime());
    let _currentDate = this.state.currentDateCurr;
    _date.setDate(_date.getDate() + 1);
    getOrdersUsers(this.state.user.api_token, formatDate(_date)).then((res) => {

      if (res.success) {
        let _list = res.response;
        _list.map((item) => {
          item['current_date'] = formatDate(_currentDate);
          item['plan'] = item.program;
          item['calorie'] = item.calories;
        });
        _list.sort(function (a, b) {
          var x = a['id']; var y = b['id'];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
        console.log(_list)
        this.setState({ loader: false, orders: _list })

      }
    })

  }

  _loginToUser(_email, _pass) {
    loginUser(_email, _pass).then((res) => {
      console.log(res.response);
      this.setState({ loader: false });
      if (res.success) {
        if (res.response) {

          var win = window.open(`https://ikitchen.cz/login/${res.response.api_token}`, '_blank', '');


        } else {
          this._alert(global.locale.login_alert_user_miss);
        }
      } else {
        this._alert(global.locale.login_alert_user_miss);
      }
    })
  }

  _load() {
    let _user = loadUser();
    this.setState({ user: _user });
    if (_user == null) this.props.history.push("/login");

    this.setState({ loader: true });
    getAllOrders(_user.api_token).then((res) => {
      console.log(res.response)
      this.setState({ allOrders: res.response });
    })

    let _excludeDaysArr = [];
    for (let i = -30; i < 40; i++) {
      var date = new Date();
      date.setDate(date.getDate() + i);
      if ((0 == getDay(date) || 2 == getDay(date) || 4 == getDay(date))) {

      } else {
        _excludeDaysArr.push(date);
      }
    }
    this.setState({ excludeDaysArr: _excludeDaysArr });


    /*
    console.log(this.state.currentDate)
    let _date = new Date(this.state.currentDate.getTime());
    _date.setDate(_date.getDate() + 1);
    */
    getAllOrdersFromDate(_user.api_token, this.state.currentDate).then((res) => {
      console.log(res.response)
      let data = [];
      res.response.map((item) => {
        if (item.program_size == 0) {
          data.push(item);
        } else {
          let date = new Date(this.state.currentDate);

          if (date.getDay() != 6) {
            data.push(item);
          }
        }
      })

      this.setState({ orders: data, currentOrders: data, loader: false });
    })

    getUsersReview(_user.api_token).then((res) => {
      this.setState({ reviewsList: res.response });
    })


    getAllPlans().then((res) => {
      let _arr = [];
      let _arr2 = [];
      res.response.map((item, index) => {
        _arr.push({ value: item.id, name: item.title_ru, green: false, calorires: item.calories });
      })
      this.setState({
        fullPlansList: res.response,
        plansList: _arr,
      });
    })

    getDishes(_user.api_token, this.state.currentDate).then((res) => {
      console.log(this.state.currentDate, res);
      storeData('ordersPageCurrentDate', this.state.currentDate);
      let data = res.response;
      let arr = [];
      data.map((item) => {
        if (this._getUnic(arr, item.id) == null) {
          item['orders'] = [];
          item['counts'] = 0;
          arr.push(item);
        }
      })
      arr.map((item) => {
        data.map((item2) => {
          if (item.id == item2.id) {
            item['counts'] = item['counts'] + 1;
            if (this._getUnic2(item['orders'], item2.order_id) == null) item['orders'].push({ order: item2.order_id, user: item2.user_id })
          }
        });
      })

      this.setState({
        ordersDishes: res.response,
        ordersDishesList: arr,
        loader: false
      })
    })

  }

  _showInfo(e) {
    e.preventDefault();
  }


  _downloadDeliveryList() {
    printDelivery().then(() => this.setState({ showDeliveryTable: false }))
  }

  _sort(_key) {
    let arr = this.state.orders;
    arr.sort( (a, b) => {
      if(_key.split(".") > 0){
        let arr = _key.split(".");
       if(this.state.sortKey.includes("-")) return (a[arr[0]][arr[1]] ) - (b[arr[0]][arr[1]]); else return (b[arr[0]][arr[1]] ) - (a[arr[0]][arr[1]]); 
      } else if(_key.includes("date")){
        if(this.state.sortKey.includes("-"))  return ( new Date(a[_key]).getTime() ) - (new Date(b[_key]).getTime()); else return (new Date(b[_key]).getTime()) - (new Date(a[_key]).getTime());
      }else {
        if(this.state.sortKey.includes("-"))  return (a[_key]) - (b[_key]); else return (b[_key]) - (a[_key]);
      }
      
    });
    this.setState({orders: arr, sortKey: this.state.sortKey.includes("-") ?  _key : `-${_key}`});
  }


  render() {


    var listReview = this.state.reviewsList.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.id}</td>
          <td>{item.dinner.title_ru}</td>
          <td>{item.full_raiting} / 5</td>
          <td>{item.created_at}</td>
        </tr>
      )
    });


    var listOrders = this.state.orders.map((item, index) => {

      if ((this.state.searchLine == '') ||
        (this.state.searchLine != '' && (this._checkInclude(item.id) || (item.user ? this._checkInclude(item.user.name) : false) || this._checkInclude(item.user.lname) || this._checkInclude(item.user.mail)))
      ) {

        if (
          (this.state.tabOrderCalloriesIndex == -1 && (this.state.tabOrderId == item.plan.id || this.state.tabOrderId == 0))
          ||
          (this.state.tabOrderCalloriesIndex == item.calorie.id)

        ) {
          return (
            <tr key={index} onClick={() => global.selectedUser = item.user} >
              <td>{item.id}</td>
              <td>
                {item.is_pay == 0 ? <spann>{global.locale.ord_not_payed}</spann> : null}
                {item.is_pay == 1 ? <spann>{global.locale.ord_payed}</spann> : null}
              </td>
              <td>
                {item.user ? <a href='' onClick={(e) => {
                  e.preventDefault();
                  storeData('selectedUser', item.user);
                  storeData('ordersPageTabIndex', this.state.tabIndex);
                  this.props.history.push("/plans/" + item.user.id + "/-1");
                }}>{item.user.name} {item.user.lname}</a> : null}
                {' '}
                <button onClick={() => {
                  this._loginToUser(item.user.mail, item.user.password);
                }}>&#x270E;</button>

              </td>
              <td>{item.user ? item.user.phone : null}</td>
              <td>{item.user ? <a href={"mailto:" + item.user.mail} >{item.user.mail}</a> : null}</td>
              {this.state.currentIndex != index ?
                <td style={{ minWidth: '150px' }}>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    onChange={(data) => {
                      console.log(data)
                      this._updateOrder(index, item.id, formatDate(data), item.end_date, item.size, item.calorie.id, item.program_size, item.price,
                      item.price_delivery, item.price_exclude, item.price_promocode, item.exclude
                      
                      );
                    }}
                    selected={new Date(item.start_date)}
                  />

                  <br />

                  -
                  <br />

                  <DatePicker
                    dateFormat="yyyy-MM-dd"

                    onChange={(data) => {
                      this._updateOrder(index, item.id, item.start_date, formatDate(data), item.size, item.calorie.id, item.program_size, item.price,
                      item.price_delivery, item.price_exclude, item.price_promocode, item.exclude
                      );
                    }}
                    selected={new Date(item.end_date)}
                  />

                </td> : null}
              <td style={{ minWidth: "220px" }}>
                {item.user ?
                  <div>


                    {this.state[`${index}exclude`] ?
                      <div>
                         Исключение заказа:
                        <br />
                        <UiFormInputBlur
                          value={item.exclude}
                          onBlur={(val) => {
                            this._updateOrder(index, item.id, item.start_date, item.end_date, item.size, item.calorie.id, item.program_size, item.price, item.price_delivery, item.price_exclude, item.price_promocode, val );

                           
                          }}
                        />


                        Исключение:
                        <br />
                        <UiFormInputBlur
                          value={item.user.exclude}
                          onBlur={(val) => {
                            this._updateUserComment(item.user, val, item.user.comment);
                          }}
                        />
                        <button onClick={() => {
                          this.setState({
                            [`${index}exclude`]: false
                          });
                        }}>&#128473;</button>
                      </div>

                      :
                      <div>
                        Исключение заказа:
                        <br />
                        {item.exclude}

                        Исключение в профиле:
                        <br />
                        {item.user.exclude == null || item.user.exclude == "" ? "" : item.user.exclude}
                        <br />
                        <button onClick={() => {
                          this.setState({
                            [`${index}exclude`]: true
                          });
                        }}>&#x270E;</button>
                      </div>
                    }




                    <br />


                    {this.state[`${index}comment`] ?

                      <div>
                        Коммент:
                        <br />
                        <UiFromTextAreaBlur
                          value={this._hasChangeDelivery(item.deliveries, this.state.currentDate) ?
                            this._hasChangeDelivery(item.deliveries, this.state.currentDate).comment
                            :
                            item.user ? item.user.comment : null
                          }

                          onBlur={(val) => {
                            this._updateUserComment(item.user, item.user.exclude, val);
                          }}
                        />

                        <button onClick={() => {
                          this.setState({
                            [`${index}comment`]: false
                          });
                        }}>&#128473;</button>

                      </div>

                      :
                      <div>
                        Коммент:
                        <br />
                        {this._hasChangeDelivery(item.deliveries, this.state.currentDate) ?
                          this._hasChangeDelivery(item.deliveries, this.state.currentDate).comment
                          :
                          item.user ? item.user.comment : null
                        }
                        <br />
                        <button onClick={() => {
                          this.setState({
                            [`${index}comment`]: true
                          });
                        }}>&#x270E;</button>

                      </div>

                    }

                    {this._hasChangeDelivery(item.deliveries, this.state.currentDate) ?
                      this._hasChangeDelivery(item.deliveries, this.state.currentDate).comment ? item.user ? `По умолчанию: ${item.user.comment}` : null : null
                      :
                      null
                    }

                  </div>
                  : null}
              </td>
              <td>
                {item.user ?
                  <div>

                    {this._hasChangeDelivery(item.deliveries, this.state.currentDate) ?
                      this._hasChangeDelivery(item.deliveries, this.state.currentDate).city
                      :
                      item.user ? item.user.city : null
                    }

                  </div>
                  : null}
              </td>
              <td>
                {item.user ?
                  <div>
                    {this._hasChangeDelivery(item.deliveries, this.state.currentDate) ?
                      this._hasChangeDelivery(item.deliveries, this.state.currentDate).street
                      :
                      item.user ? item.user.street : null
                    }
                  </div>
                  : null}
              </td>
              <td>{item.user ? item.user.code : null}</td>

              {this.state.currentIndex != index ?
                <td>
                  {item.plan.title_ch}


                  <br />
                  <select value={item.size} onChange={(val) => {
                    this._updateOrder(index, item.id, item.start_date, item.end_date, val.target.value, item.calorie.id, item.program_size, item.price,
                      item.price_delivery, item.price_exclude, item.price_promocode, item.exclude  
                    );
                  }}>
                    <option value={0}>все порции</option>
                    <option value={1}>завтрак + обед</option>
                    <option value={2}>завтрак + ужин</option>
                    <option value={3}>обед + ужин</option>
                    <option value={4}>завтрак +  обед +  ужин </option>
                  </select>


                </td> : null}
              {this.state.currentIndex != index ?
                <td>
                  {item.calorie.title_ch}
                  <br />

                  <select value={item.calorie.id} onChange={(val) => {
                    this._updateOrder(index, item.id, item.start_date, item.end_date, item.size, val.target.value, item.program_size, item.price,  item.price_delivery, item.price_exclude, item.price_promocode, item.exclude);
                  }}>
                    {this._getCaloriesList(item.plan.id)}
                  </select>

                </td>
                : null}
              {this.state.currentIndex != index ?
                <td>

                  <select value={item.program_size} onChange={(val) => {
                    this._updateOrder(index, item.id, item.start_date, item.end_date, item.size, item.calorie.id, val.target.value, item.price,  item.price_delivery, item.price_exclude, item.price_promocode, item.exclude);
                  }}>
                    <option value={0}>{global.locale.home_food_menu_option_4_item_1}</option>
                    <option value={1}>{global.locale.home_food_menu_option_4_item_2}</option>
                  </select>
                </td> : null}
              <td>{item.count}</td>
              <td>{item.days - item.days_offset}</td>

              <td>
              
              {this.state[`${index}price_promocode`] ?
                    <div>
                      <UiFormInputBlur value={item.price_promocode} onBlur={(val) => {
                        this._updateOrder(index, item.id, item.start_date, item.end_date, item.size, item.calorie.id, item.program_size, item.price ,  item.price_delivery, item.price_exclude, val , item.exclude);
                      }} />
                      <button onClick={() => {
                        this.setState({
                          [`${index}price_promocode`]: false
                        });
                      }}>&#128473;</button>
                    </div>

                    :
                    <div>
                      {item.price_promocode}
                      <br />
                      <button onClick={() => {
                        this.setState({
                          [`${index}price_promocode`]: true
                        });
                      }}>&#x270E;</button>
                    </div>
                  }

              </td>
              <td> 
              
              {this.state[`${index}price_delivery`] ?
                    <div>
                      <UiFormInputBlur value={item.price_delivery} onBlur={(val) => {
                        this._updateOrder(index, item.id, item.start_date, item.end_date, item.size, item.calorie.id, item.program_size, item.price ,  val, item.price_exclude, item.price_promocode, item.exclude);
                      }} />
                      <button onClick={() => {
                        this.setState({
                          [`${index}price_delivery`]: false
                        });
                      }}>&#128473;</button>
                    </div>

                    :
                    <div>
                      {item.price_delivery}
                      <br />
                      <button onClick={() => {
                        this.setState({
                          [`${index}price_delivery`]: true
                        });
                      }}>&#x270E;</button>
                    </div>
                  }
              
              </td>
              <td>              
              
              {this.state[`${index}price_exclude`] ?
                    <div>
                      <UiFormInputBlur value={item.price_exclude} onBlur={(val) => {
                        this._updateOrder(index, item.id, item.start_date, item.end_date, item.size, item.calorie.id, item.program_size, item.price ,  item.price_delivery, val,  item.price_promocode, item.exclude);
                      }} />
                      <button onClick={() => {
                        this.setState({
                          [`${index}price_exclude`]: false
                        });
                      }}>&#128473;</button>
                    </div>

                    :
                    <div>
                      {item.price_exclude}
                      <br />
                      <button onClick={() => {
                        this.setState({
                          [`${index}price_exclude`]: true
                        });
                      }}>&#x270E;</button>
                    </div>
                  }


              </td>

              {this.state.currentIndex != index ?
                <td>

                  {this.state[`${index}price`] ?
                    <div>
                      <UiFormInputBlur value={item.price} onBlur={(val) => {
                        this._updateOrder(index, item.id, item.start_date, item.end_date, item.size, item.calorie.id, item.program_size, val,  item.price_delivery, item.price_exclude, item.price_promocode, item.exclude);
                      }} />
                      <button onClick={() => {
                        this.setState({
                          [`${index}price`]: false
                        });
                      }}>&#128473;</button>
                    </div>

                    :
                    <div>
                      {item.price}
                      <br />
                      <button onClick={() => {
                        this.setState({
                          [`${index}price`]: true
                        });
                      }}>&#x270E;</button>
                    </div>
                  }





                </td>
                : null}
              <td>{
                item.type == 1 ? global.locale.modal_pay_cash_to_courier :
                  item.type == 2 ? global.locale.modal_pay_pay_card_to_courier :
                    item.type == 3 ? global.locale.modal_pay_bank_transfer : null
              }</td>
            </tr>
          )
        }
      }
    });



    var ordersTabsList = this.state.plansList.map((item, index) => {
      return (
        <button
          key={index}
          className={"plans-tab-button" + (this.state.tabOrderId == item.value ? " active" : "")}
          onClick={() => {
            this.setState({ tabOrderId: item.value, tabOrderIndex: index, });
          }}
        >
          <span>{item.name} ({this._getCountPlans(item.value)})</span>
        </button>
      )
    })

    var ordersTabsCalloriesList = this.state.plansList[this.state.tabOrderIndex].calorires.map((item, index) => {
      return (

        <button
          key={index}
          className={(this.state.tabOrderCalloriesIndex == item.id ? " active" : "")}
          onClick={() => {
            this.setState({ tabOrderCalloriesIndex: item.id });
          }}
        >
          <span>{item.title_ru} ({this._getCountCalories(item.id)})</span>
        </button>
      )
    })


    var listDishes = this.state.ordersDishesList.map((item, index) => {
      var inner = item.orders.map((item2, index2) => {
        return (
          <span key={index2}>
            <a href="" onClick={(e) => this._showDishesByOrder(e, item2)} ># {item2.order}</a> {' '}
          </span>

        )
      })
      return (
        <tr key={index}>
          <td>{item.title_ru}</td>
          <td>{item.description_ru}</td>
          <td>{item.outer_id}</td>
          <td>
            {item.weight} г
          </td>
          <td>{item.counts}</td>
          <td>{inner}</td>
        </tr>
      )
    });

    return (
      <div ref={this.divPage} id="page">
        <UiLoader show={this.state.loader} />
        <UiModalMenu
          show={this.state.showMenu}
          dishes={this.state.showedDishes}
          readOnly
          showDishesOnDay
          callBackClose={() => this.setState({ showMenu: false })}
        />
        <div className={"wrapper" + (this.state.showMenuBar ? ' scroll' : '')}>
          <div className="content">
            <UiHeader />
            <section className="section bg-gray orders-list">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="btn-wrap">
                      <div className="row">
                        <div className="col-md-2">
                          {this.state.tabIndex == 0 ?
                            <UiBtnGreen btnText={global.locale.ord_btn_list_orders} block btnsm black onClick={() => this.setState({ tabIndex: 0 }, () => storeData('ordersPageTabIndex', 0))} />
                            :
                            <UiBtnGreenOutline btnText={global.locale.ord_btn_list_orders} block btnsm black onClick={() => this.setState({ tabIndex: 0 }, () => storeData('ordersPageTabIndex', 0))} />
                          }
                        </div>
                        <div className="col-md-2">
                          {this.state.tabIndex == 1 ?
                            <UiBtnGreen btnText={global.locale.ord_btn_list_dishes} block btnsm black onClick={() => this.setState({ tabIndex: 1 }, () => storeData('ordersPageTabIndex', 1))} />
                            :
                            <UiBtnGreenOutline btnText={global.locale.ord_btn_list_dishes} block btnsm black onClick={() => this.setState({ tabIndex: 1 }, () => storeData('ordersPageTabIndex', 1))} />
                          }
                        </div>
                        <div className="col-md-2">
                          {this.state.tabIndex == 2 ?
                            <UiBtnGreen btnText={global.locale.ord_btn_statist} block btnsm black onClick={() => this.setState({ tabIndex: 2 }, () => storeData('ordersPageTabIndex', 2))} />
                            :
                            <UiBtnGreenOutline btnText={global.locale.ord_btn_statist} block btnsm black onClick={() => this.setState({ tabIndex: 2 }, () => storeData('ordersPageTabIndex', 2))} />
                          }
                        </div>
                      </div>
                    </div>
                    {this.state.tabIndex == 0 ?
                      <div className="plans">
                        <Calendar
                          onChange={(val) => {
                            this.setState({ currentDate: formatDate(val) }, () => {
                              this._load();
                            })

                          }}
                        />
                        <hr />
                        Deliveries calendar:
                        {this.state.excludeDaysArr.length > 0 ?
                          <DatePicker
                            excludeDates={this.state.excludeDaysArr}
                            onChange={(data) => {
                              this.setState({
                                currentDateCurr: data,
                                currentDate: data,
                              }, () => this._loadCurr());
                            }}
                            selected={this.state.currentDateCurr}
                          />
                          : null}


                        <hr />
                        <div className="plans-tab">
                          <button className={"plans-tab-button" + (this.state.tabOrderId == -1 ? " active" : "")}
                            onClick={() => {
                              this.setState({ tabOrderId: 0, tabOrderCalloriesIndex: -1, orders: this.state.allOrders });
                            }}
                          >
                            <span>Показать Все заказы</span>
                          </button>


                        </div>

                        <div className="plans-tab">

                          <button
                            className={"plans-tab-button" + (this.state.tabOrderId == 0 ? " active" : "")}
                            onClick={() => {
                              this.setState({ tabOrderId: 0, tabOrderCalloriesIndex: -1 });
                            }}
                          >
                            <span>{global.locale.ord_all} ({this.state.orders.length} {global.locale.ord_ordera})</span>
                          </button>
                          {ordersTabsList}
                        </div>
                        <div className="orders-tab">
                          {this.state.tabOrderId == 0 ?
                            <button
                              className={(this.state.tabOrderCalloriesIndex == -1 ? " active" : "")}
                              onClick={() => {
                                this.setState({ tabOrderCalloriesIndex: -1 });
                              }}
                            >
                              <span>Любая каллорийность</span>
                            </button>
                            :
                            ordersTabsCalloriesList
                          }
                        </div>
                        <div>
                          <h3>{global.locale.ord_list_orders}</h3>
                          <div className="search-form">
                            <div><UiFormInput label={"Поиск:"} callBack={(val) => this._search(val)} /></div>
                            <div style={{ margin: '20px', width: '200px' }}><UiBtnGreen btnText={"Download Delivery List"} block btnsm black onClick={() => this._downloadDeliveryList()} /></div>

                          </div>
                          <table className="table table-bordered">
                            <tr>
                              <th onClick={() => this._sort("id")}>№</th>
                              <th onClick={() => this._sort("is_pay")}>{global.locale.ord_status}</th>
                              <th onClick={() => this._sort("user.name")}>{global.locale.ord_FIO}</th>
                              <th onClick={() => this._sort("user.phone")}>{global.locale.ord_phone}</th>
                              <th onClick={() => this._sort("user.mail")}>EMail</th>
                              <th onClick={() => this._sort("end_date")}>{global.locale.ord_data_create}</th>
                              <th onClick={() => this._sort("end_date")}>{global.locale.ord_comment}</th>
                              <th onClick={() => this._sort("user.city")}>Город</th>
                              <th onClick={() => this._sort("user.exclude")}>{global.locale.ord_delievery_adress}</th>
                              <th onClick={() => this._sort("user.code")}>Code</th>
                              <th onClick={() => this._sort("plan.title_ch")}>{global.locale.home_prog_name}</th>
                              <th  >{global.locale.home_type_calories}</th>
                              <th onClick={() => this._sort("program_size")}>Длительность</th>
                              <th onClick={() => this._sort("count")}>Кол-во</th>
                              <th onClick={() => this._sort("days")}>Дней</th>
                              <th onClick={() => this._sort("price_promocode")}>Стоимость промокода</th>
                              <th onClick={() => this._sort("price_delivery")}>Стоимость доставки</th>
                              <th onClick={() => this._sort("price_exclude")}>Стоимость исключений</th>
                              <th onClick={() => this._sort("price")}>{global.locale.food_price}</th>
                              <th >Comment</th>
                            </tr>
                            {listOrders}
                          </table>
                        </div>
                      </div>
                      : null}
                    {this.state.tabIndex == 1 ?
                      <div className="plans">
                        <Calendar
                          onChange={(val) => {
                            storeData('ordersPageCurrentDate', val);
                            this.setState({ currentDate: val }, () => {
                              this.setState({ currentDate: formatDate(val) }, () => {
                                this._load();
                              })
                            })
                          }}
                        />
                        <br />
                        <table className="table table-bordered food-table">
                          <tr>
                            <th>{global.locale.ord_name}</th>
                            <th>{global.locale.ord_composition}</th>
                            <th>Vendor code</th>
                            <th>{global.locale.ord_serving_size}</th>
                            <th>{global.locale.ord_quality}</th>
                            <th>{global.locale.ord_orders_num}</th>
                          </tr>
                          {listDishes}
                        </table>
                      </div>
                      : null
                    }
                    {this.state.tabIndex == 2 ?
                      <div className="plans">
                        <table className="table table-bordered food-table">
                          <tr>
                            <th>#</th>
                            <th>{global.locale.ord_dish}</th>
                            <th>{global.locale.ord_rate}</th>
                            <th>{global.locale.ord_data_rate}</th>
                          </tr>
                          {listReview}
                        </table>
                      </div>
                      : null
                    }

                    {/*   */}
                    <div style={{ display: "none" }}>
                      {renderXLS(this.state.orders, this.state.currentDate, this._hasChangeDelivery)}
                    </div>




                  </div>
                </div>
              </div>
            </section>
            <UiFooter />
            <button className="top-button" onClick={() => this._scrollToTargetAdjusted("header")}>
              <img src={require('../../assets/imgs/ui/up-arrow.svg')} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default OrdersPage;