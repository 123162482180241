import React, { Component } from 'react';
import ReactWOW from 'react-wow';

import UiFooter from '../../components/ui/footer/UiFooter.js';
import UiHeader from '../../components/ui/header/UiHeader.js';
import UiBtnGreen from '../../components/ui/buttons/UiBtnGreen.js';
import UiBtnGreenOutline from '../../components/ui/buttons/UiBtnGreenOutline.js';
import UiBtnPink from '../../components/ui/buttons/UiBtnPink.js';
import UiBtnPinkOutline from '../../components/ui/buttons/UiBtnPinkOutline.js';
import UiBtnIcon from '../../components/ui/buttons/UiBtnIcon.js';
import UiAlert from '../../components/ui/modals/UiAlert.js';
import UiLoader from '../../components/ui/modals/UiLoader';

import { en } from '../../i18n/en.js';
import { ru } from '../../i18n/ru.js';
import { ch } from '../../i18n/ch.js';
import { translateObject } from '../../i18n/locale.js';

import { formatDate2 } from '../../components/common/Date';
import { loadUser, getUsers, setUser } from '../../services/Users';
import { retrieveData } from '../../services/Storage';

class UsersPage extends Component {

  state = {
    users: [],
    tabOrderIndex: 0,
  }

  constructor(props) {
    super(props);

    let _lang = retrieveData("ikLocale");
    if (_lang == null) _lang = 'ch';
    if (_lang == 'ch') global.locale = ch;
    if (_lang == 'ru') global.locale = ru;
    if (_lang == 'en') global.locale = en;
    this.setState({ lang: _lang });

  }

  componentDidMount() {
    this._load();

  }

  _changeStatus(_id, _status) {
    setUser(this.state.user.api_token, _id, _status).then((res) => {
      console.log(res);
      this._load();
    })
  }



  _load() {
    let _user = loadUser();
    if (_user == null) this.props.history.push("/login");
    this.setState({ user: _user, loader: true });

    getUsers(_user.api_token).then((res) => {
      console.log(res);
      this.setState({ users: res.response, loader: false });
    })

  }

  render() {
    var list = this.state.users.map((item, index) => {
      if (this.state.tabOrderIndex == 0) {
        let orders = item.orders.map((item2, index2) => {
          return (
            <span key={index}>
              #{item2.id} {formatDate2(item2.start_date)} - {formatDate2(item2.end_date)} <br />
            </span>
          )
        })
        return (
          <tr key={index}>
            <th>{item.id}</th>
            <th>
              {item.type == 0 ? <span>{global.locale.user_admin}</span> : ""}
              {item.type == 1 ? <span>{global.locale.user_manager}</span> : ""}
              {item.type == 2 ? <span>{global.locale.user_courier}</span> : ""}
              {item.type == 3 ? <span>{global.locale.user_std_man}</span> : ""}
            </th>
            <th>{item.name}</th>
            <th>{item.phone}</th>
            <th>{item.mail}</th>
            <th>{orders}</th>
            <th>{item.created_at}</th>
            <th>{item.bonus}</th>
            {item.type != 0 ?
              <th>
                {item.type != 0 ? <UiBtnIcon onClick={() => this._changeStatus(item.id, 0)} text="Администратор" color="#fff" icon={require('../../assets/imgs/ui/star-active.svg')} mr2 /> : null}
              
                {item.type != 2 ? <UiBtnIcon onClick={() => this._changeStatus(item.id, 2)} text="Курьер" color="#fff" icon={require('../../assets/imgs/ui/plus.svg')} mr2 /> : null}
                
                {item.type !=  1 ? <UiBtnIcon onClick={() => this._changeStatus(item.id, 1)} text="Менеджер" color="#fff" icon={require('../../assets/imgs/ui/star.svg')} mr2 /> : null}

                {item.type !=  3 ? <UiBtnIcon onClick={() => this._changeStatus(item.id, 1)} text="Пользователь" color="#fff" icon={require('../../assets/imgs/ui/star.svg')} mr2 /> : null}
              
              </th>
              :
              <th></th>
            }
          </tr>
        )
      } else if (this.state.tabOrderIndex == 1) {
        if (item.type == 3) {
          let orders = item.orders.map((item2, index2) => {
            return (
              <span key={index}>
                #{item2.id} {formatDate2(item2.start_date)} - {formatDate2(item2.end_date)} <br />
              </span>
            )
          })
          return (
            <tr key={index}>
              <th>{item.id}</th>
              <th>
                {item.type == 0 ? <span>{global.locale.user_admin}</span> : ""}
                {item.type == 1 ? <span>{global.locale.user_manager}</span> : ""}
                {item.type == 2 ? <span>{global.locale.user_courier}</span> : ""}
                {item.type == 3 ? <span>{global.locale.user_std_man}</span> : ""}
              </th>
              <th>{item.name}</th>
              <th>{item.phone}</th>
              <th>{item.mail}</th>
              <th>{orders}</th>
              <th>{item.created_at}</th>
              <th>{item.bonus}</th>
              {item.type != 0 ?
                <th>
                  {item.type == 3 ? <UiBtnIcon onClick={() => this._changeStatus(item.id, 2)} text="Менеджер" color="#fff" icon={require('../../assets/imgs/ui/plus.svg')} mr2 /> : null}
                  {item.type == 2 ? <UiBtnIcon onClick={() => this._changeStatus(item.id, 3)} text="Убрать" color="#f9650b" icon={require('../../assets/imgs/ui/delete.svg')} mr2 /> : null}

                  {item.type == 3 || item.type == 2 ? <UiBtnIcon onClick={() => this._changeStatus(item.id, 1)} text="Сотрудник" color="#fff" icon={require('../../assets/imgs/ui/star.svg')} mr2 /> : null}
                  {item.type == 1 ? <UiBtnIcon onClick={() => this._changeStatus(item.id, 3)} text="Убрать" color="#f9650b" icon={require('../../assets/imgs/ui/delete.svg')} mr2 /> : null}

                </th>
                :
                <th></th>
              }
            </tr>
          )
        }

      } else if (this.state.tabOrderIndex == 2) {
        if (item.type != 3) {
          let orders = item.orders.map((item2, index2) => {
            return (
              <span key={index}>
                #{item2.id} {formatDate2(item2.start_date)} - {formatDate2(item2.end_date)} <br />
              </span>
            )
          })
          return (
            <tr key={index}>
              <th>{item.id}</th>
              <th>
                {item.type == 0 ? <span>{global.locale.user_admin}</span> : ""}
                {item.type == 1 ? <span>{global.locale.user_manager}</span> : ""}
                {item.type == 2 ? <span>{global.locale.user_courier}</span> : ""}
                {item.type == 3 ? <span>{global.locale.user_std_man}</span> : ""}
              </th>
              <th>{item.name}</th>
              <th>{item.phone}</th>
              <th>{item.mail}</th>
              <th>{orders}</th>
              <th>{item.created_at}</th>
              <th>{item.bonus}</th>
              {item.type != 0 ?
                <th>
                  {item.type == 3 ? <UiBtnIcon onClick={() => this._changeStatus(item.id, 2)} text="Менеджер" color="#fff" icon={require('../../assets/imgs/ui/plus.svg')} mr2 /> : null}
                  {item.type == 2 ? <UiBtnIcon onClick={() => this._changeStatus(item.id, 3)} text="Убрать" color="#f9650b" icon={require('../../assets/imgs/ui/delete.svg')} mr2 /> : null}

                  {item.type == 3 || item.type == 2 ? <UiBtnIcon onClick={() => this._changeStatus(item.id, 1)} text="Сотрудник" color="#fff" icon={require('../../assets/imgs/ui/star.svg')} mr2 /> : null}
                  {item.type == 1 ? <UiBtnIcon onClick={() => this._changeStatus(item.id, 3)} text="Убрать" color="#f9650b" icon={require('../../assets/imgs/ui/delete.svg')} mr2 /> : null}

                </th>
                :
                <th></th>
              }

            </tr>
          )
        }

      }

    })

    return (
      <div ref={this.divPage} id="page">
        <UiLoader show={this.state.loader} />
        <UiAlert
          show={this.state.showAlert}
          title={this.state.alertTitle}
          text={this.state.alertText}
          callBack={() => this.setState({ showAlert: false })}
          leftBtnText="Ок"
        />
        <div className={"wrapper" + (this.state.showMenuBar ? ' scroll' : '')}>
          <div className="content">
            <UiHeader />
            <section className="section bg-gray orders-list">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="plans">
                      <div className="plans-tab">
                        <button
                          className={"plans-tab-button" + (this.state.tabOrderIndex == 0 ? " active" : "")}
                          onClick={() => {
                            this.setState({ tabOrderIndex: 0 });
                          }}
                        >
                          <span>{global.locale.user_all} ({this.state.users.length})</span>
                        </button>
                        <button
                          className={"plans-tab-button" + (this.state.tabOrderIndex == 1 ? " active" : "")}
                          onClick={() => {
                            this.setState({ tabOrderIndex: 1 });
                          }}
                        >
                          <span>{global.locale.user_std_mans}</span>
                        </button>
                        <button
                          className={"plans-tab-button" + (this.state.tabOrderIndex == 2 ? " active" : "")}
                          onClick={() => {
                            this.setState({ tabOrderIndex: 2 });
                          }}
                        >
                          <span>{global.locale.user_staff}</span>
                        </button>
                      </div>

                      <table className="table table-bordered food-table">
                        <tr>
                          <th>#</th>
                          <th>{global.locale.user_type}</th>
                          <th>{global.locale.user_name_std_man}</th>
                          <th>{global.locale.user_phone}</th>
                          <th>{global.locale.user_mail}</th>
                          <th>{global.locale.user_active_programs}</th>
                          <th>{global.locale.user_reg_data}</th>
                          <th>{global.locale.user_bonus}</th>
                          <th>{global.locale.user_post}</th>
                        </tr>
                        {list}
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </section>
            <UiFooter />
          </div>
        </div>
        <button className="top-button" onClick={() => this._scrollToTargetAdjusted("header")}>
          <img src={require('../../assets/imgs/ui/up-arrow.svg')} />
        </button>
      </div>
    );
  }
}

export default UsersPage;