import React, { Component } from 'react';
import { Container } from './components';

import './styles/bootstrap.min.css';
import './styles/style.scss';
import 'animate.css';

class App extends Component {
  render() {
  return (
    <Container />
  );
  }
}

export default App;
