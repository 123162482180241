import React, { Component } from 'react';

class UiBtnIcon extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <button onClick={this.props.onClick} className={"btn-wrapper btn-icon" + (this.props.mr2 ? " mr-2" : "") + (this.props.text ? " btn-icon-text" : "")} style={{ backgroundColor: this.props.color }}>
        <img src={this.props.icon} />{this.props.text ? this.props.text : null}
      </button>
    );
  }
}
export default UiBtnIcon;