import React, { Component } from 'react';

import UiPlanCard from '../../ui/cards/UiPlanCard.js';
import UiFormInput from '../../ui/forms/UiFormInput.js';

class UiModalMenu extends Component {

  state = {
    searchLine: '',
  }
  constructor(props) {
    super(props);
  }

  _search(val) {
    this.setState({ searchLine: val })
  }

  _checkInclude(substring) {
    if (this.state.searchLine && substring) {
      let str = this.state.searchLine;
      return substring.toString().toLowerCase().indexOf(str.toLowerCase()) !== -1;
    }
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    var list = this.props.dishes.map((item, index) => {
      
      if ((this.state.searchLine == '') ||
        (this.state.searchLine != '' && (this._checkInclude(item.id) || this._checkInclude(item.outer_id) || this._checkInclude(item.title_ch) || this._checkInclude(item.title_ru) || this._checkInclude(item.title_en)))
      ) {
        return (
          <div className="col-sm-6 col-md-6 col-lg-3" key={index}>
            {this.props.readOnly ?
              <UiPlanCard
                addItem={false}
                modalAddCard={true}
                image={{ uri: item.image }}
                title={item.title_ru}
                ingredient={item.description_ru}
                about={item.calories + " " + global.locale.modal_menu_kCal + ", " + item.weight + " " + global.locale.modal_menu_g}
                ccal={item.calories} protein={item.protein} fats={item.fats} carbo={item.carbohydrates}
                price={item.price}
              />
              :
              <UiPlanCard
                addItem={false}
                image={{ uri: item.image }}
                day={global.locale.modal_menu_tuesday + " 14.04"}
                lanchType={global.locale.modal_menu_breakfast}
                title={item.title_ru}
                ingredient={item.description_ru}
                about={item.calories + " " + global.locale.modal_menu_kCal + ", " + item.weight + " " + global.locale.modal_menu_g}
                ccal={item.calories} protein={item.protein} fats={item.fats} carbo={item.carbohydrates}
                price={item.price}
                callBackSelect={() => {
                  this.props.callBack(item);
                }}
                callBackRemove={() => {
                  this.props.callBackRemove(item)
                }}
                modalAddCard={true}
              />
            }

          </div>
        )
      }
    })
    return (
      <div className={"modal-wrap " + (this.props.show ? "show" : "hide")}>
        <div className="modal-card">
          <div className="container">
            <div className="card-title">
              {this.props.showDishesOnDay ? 
                <h3>Список блюд в заказе на день </h3>
                : 
                <h3>{global.locale.modal_menu_add_dish} <br /><small>{global.locale.modal_menu_text}</small></h3>
              }
              <button className="card-title-btn" onClick={() => this.props.callBackClose()}><img src={require('../../../assets/imgs/ui/close.svg')} /> {global.locale.modal_menu_btn_close}</button>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="card-title-search">
                  <p>{global.locale.modal_menu_search}: </p>
                  <UiFormInput callBack={(val) => this._search(val)} />
                </div>
              </div>
            </div>

            <div className="row">
              {list}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UiModalMenu;