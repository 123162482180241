import React, { Component } from 'react';
import ReactWOW from 'react-wow';

import checkIcon from '../../assets/imgs/ui/check.svg';

import UiAlert from '../../components/ui/modals/UiAlert.js';
import UiBtnIcon from '../../components/ui/buttons/UiBtnIcon.js';
import UiLoader from '../../components/ui/modals/UiLoader';
import UiBtnGreen from '../../components/ui/buttons/UiBtnGreen.js';
import UiBtnGreenOutline from '../../components/ui/buttons/UiBtnGreenOutline.js';
import UiBtnPink from '../../components/ui/buttons/UiBtnPink.js';
import UiBtnPinkOutline from '../../components/ui/buttons/UiBtnPinkOutline.js';
import UiCheckList from '../../components/ui/forms/UiCheckList.js';
import UiDinnerCard from '../../components/ui/cards/UiDinnerCard';
import UiFormInput from '../../components/ui/forms/UiFormInput.js';
import UiFooter from '../../components/ui/footer/UiFooter.js';
import UiHeader from '../../components/ui/header/UiHeader.js';
import UiPlansTab from '../../components/ui/tabs/UiPlansTab.js';
import UiTabPlanCard from '../../components/ui/cards/UiTabPlanCard.js';
import UiTextArea from '../../components/ui/forms/UiTextArea.js';
import UiModalMenu from '../../components/ui/modals/UiModalMenu.js';

import { en } from '../../i18n/en.js';
import { ru } from '../../i18n/ru.js';
import { ch } from '../../i18n/ch.js';
import { translateObject } from '../../i18n/locale.js';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { formatDate, getWeekByNum, getFormatedCurrentDate } from '../../components/common/Date';
import { loadUser, saveUser } from '../../services/Users';
import { addFile, addPlan, removePlan, getDaysByCalories, getAllDishes, getAllPlans, updatePlan, updateCalories, remCalories, remDay, addDay, addCalories, removeItemFromDay, addItemDay, cloneDay, cloneDayOfWeek } from '../../services/Plans';
import { retrieveData } from '../../services/Storage';

class HomePage extends Component {

  state = {
    activeLang: 1,
    selectedCaloriesIndex: 1,
    selectedDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),

    selectedFileName: null,

    showModal: false,
    addNewDayShow: false,
    addNewPlanShow: false,

    dishesNum1Active: false,
    dishesNum2Active: false,
    dishesNum3Active: false,
    dishesNum4Active: false,
    dishesNum5Active: false,



    plansList: [],
    daysList: [],
    dishesList: [],
    caloriesList: [],
    selectedDay: {
      dinner1: null,
      dinner2: null,
      dinner3: null,
      dinner4: null,
      dinner5: null,
      calories_id: 0,
      date: formatDate(new Date()),
    },
    selectedNewCalories: {
      description_ch: null,
      description_en: null,
      description_ru: null,
      from_cal: 1200,
      to_cal: 1400,
      plan_id: 1,
      title_ch: null,
      title_en: null,
      title_ru: null,
    },
    selectedCalories: {
      description_ch: "Ztráta váhy",
      description_en: "Lose weight",
      description_ru: "Снижение веса",
      from_cal: 1200,
      id: 1,
      image: null,
      title_ch: "Ztráta váhy",
      title_en: "Lose weight",
      title_ru: "Снижение веса",
      to_cal: 1400,
    },
    selectedPlan: {
      calories: [],
      description_ch: null,
      description_en: null,
      description_ru: null,
      full_description_ch: null,
      full_description_en: null,
      full_description_ru: null,
      id: 1,
      image: null,
      title_ch: null,
      title_en: null,
      title_ru: null,
    }
  }

  constructor(props) {
    super(props);

    let _lang = retrieveData("ikLocale");
    if (_lang == null) _lang = 'ch';
    if (_lang == 'ch') global.locale = ch;
    if (_lang == 'ru') global.locale = ru;
    if (_lang == 'en') global.locale = en;
    this.setState({ lang: _lang });

  }

  componentDidMount() {
    this._load();
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > 180) this.setState({ showMenuBar: true }); else this.setState({ showMenuBar: false });
    });
  }


  _alert(_title = null, _text) {
    this.setState({
      showAlert: true,
      alertTitle: _title,
      alertText: _text
    })
  }


  _scrollToTargetAdjusted(target) {
    var element = document.getElementById(target);
    var headerOffset = 130;
    if (window.innerWidth < 770) headerOffset = 350;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }


  _load() {
    let _user = loadUser();
    this.setState({ user: _user });
    if (_user == null) this.props.history.push("/login");
    this.setState({ loader: true });

    getAllDishes(_user.api_token).then((res) => {
      console.log(res);
      this.setState({ loader: false });
      if (res.success) {
        this.setState({ dishesList: res.response });
      } else {
        saveUser(null);
        this.props.history.push("/login");
      }

    })

    getAllPlans().then((res) => {
      //console.log(res);
      let _arr = [];
      let _arr2 = [];
      res.response.map((item, index) => {
        _arr.push({ value: item.id, name: item.title_ru, green: false });
      })
      //_arr.push({ value: -1, name: 'Mini Eshop', green: true });
      this.setState({
        fullPlansList: res.response,
        plansList: _arr,
        selectedPlan: {
          calories: res.response[0].calories,
          description_en: res.response[0].description_en,
          description_ch: res.response[0].description_ch,
          description_en: res.response[0].description_en,
          description_ru: res.response[0].description_ru,
          full_description_ch: res.response[0].full_description_ch,
          full_description_en: res.response[0].full_description_en,
          full_description_ru: res.response[0].full_description_ru,
          id: res.response[0].id,
          image: res.response[0].image,
          title_ch: res.response[0].title_ch,
          title_en: res.response[0].title_en,
          title_ru: res.response[0].title_ru,
        }
      });

    })

    this._getDays(this.state.selectedCaloriesIndex);
  }

  _getAllCalories(_id) {
    this.setState({ loader: true });
    getAllPlans().then((res) => {
      let _arr = [];
      let _arr2 = [];
      res.response.map((item, index) => {
        _arr.push({ value: item.id, name: item.title_ru, green: false });
      })
      _arr.push({ value: -1, name: 'Mini Eshop', green: true });
      this.setState({
        fullPlansList: res.response,
        plansList: _arr,
        loader: false
      }, () => {
        this._getPlan(_id);
      });
    })
  }

  _getDays(_id) {
    this.setState({ loader: true });
    getDaysByCalories(_id, getFormatedCurrentDate()).then((res) => {
      console.log(res);
      this.setState({ loader: false });
      if (res.success) {
        this.setState({ daysList: res.response.reverse() })
      }
    })
  }

  _getPlan(_id) {
    this.state.fullPlansList.map((item, index) => {
      if (item.id == _id) {
        this.setState({
          selectedPlan: {
            calories: item.calories,
            description_ch: item.description_ch,
            description_en: item.description_en,
            description_ru: item.description_ru,
            full_description_ch: item.full_description_ch,
            full_description_en: item.full_description_en,
            full_description_ru: item.full_description_ru,
            id: item.id,
            image: item.image,
            title_ch: item.title_ch,
            title_en: item.title_en,
            title_ru: item.title_ru,
          }
        });
        if (item.calories.length > 0) {
          this._getDays(item.calories[0].id);
          this._updateStateDayVal("calories_id", item.calories[0].id)

          this.setState({ selectedCalories: item.calories[0], selectedCaloriesIndex: item.calories[0].id });
        }

      }
    })
  }

  _getDishesById(_id) {
    let val = null;
    this.state.dishesList.map((item) => {
      if (item.id == _id) val = item;
    })
    return val;
  }

  _updateStateDayVal(_tt, _val) {
    let st = this.state.selectedDay;
    if (st == null) st = {};
    st[_tt] = _val;
    this.setState({ selectedDay: st })
  }

  _updateStateVal(_tt, _val) {
    let st = this.state.selectedPlan;
    if (st == null) st = {};
    st[_tt] = _val;
    this.setState({ selectedPlan: st })
  }

  _updateStateCalVal(_tt, _val) {
    let st = this.state.selectedCalories;
    if (st == null) st = {};
    st[_tt] = _val;
    this.setState({ selectedCalories: st })
  }

  _updateStateCalNewVal(_tt, _val) {
    let st = this.state.selectedNewCalories;
    if (st == null) st = {};
    st[_tt] = _val;
    this.setState({ selectedNewCalories: st })
  }

  _updatePlan() {
    this.setState({ loader: true });
    updatePlan(this.state.user.api_token, this.state.selectedPlan).then((res) => {
      console.log(res);
      this.setState({ loader: false });
    })
  }

  _updateCalories() {
    this.setState({ loader: true });
    updateCalories(this.state.user.api_token, this.state.selectedCalories).then((res) => {
      console.log(res);
      this.setState({ loader: false });
    })
  }

  _addNewCalories() {
    this._updateStateCalNewVal('plan_id', this.state.selectedPlan.id);
    if ((this.state.selectedNewCalories.title_ru != "" && this.state.selectedNewCalories.description_ru != "") ||
      (this.state.selectedNewCalories.title_ch != "" && this.state.selectedNewCalories.description_ch != "") ||
      (this.state.selectedNewCalories.title_en != "" && this.state.selectedNewCalories.description_en != "")
    ) {
      addCalories(this.state.user.api_token, this.state.selectedNewCalories).then((res) => {
        console.log(res);
        this._getAllCalories(this.state.selectedPlan.id);
      })
    } else {
      this._alert(global.locale.home_alert_pole_required);
    }

  }

  _removeCalories(_id) {
    remCalories(this.state.user.api_token, _id).then((res) => {
      console.log(res);
      this._getAllCalories(this.state.selectedPlan.id);
    })
  }

  _removeItemDay(_id, _num) {

    removeItemFromDay(this.state.user.api_token, _id, _num).then((res) => {
      console.log(res);
      this._getDays(this.state.selectedCaloriesIndex);
    })
  }

  _addItemDay(_id, _did, _type) {
    addItemDay(this.state.user.api_token, _id, _did, _type).then((res) => {
      console.log(res);
      this._getDays(this.state.selectedCaloriesIndex);
    })
  }

  _addNewDay() {
    if (this.state.mayChnageD1) this._updateStateDayVal('dinner1_may_change', 1);
    if (this.state.mayChnageD2) this._updateStateDayVal('dinner2_may_change', 1);
    if (this.state.mayChnageD3) this._updateStateDayVal('dinner3_may_change', 1);
    if (this.state.mayChnageD4) this._updateStateDayVal('dinner4_may_change', 1);
    if (this.state.mayChnageD5) this._updateStateDayVal('dinner5_may_change', 1);

    addDay(this.state.user.api_token, this.state.selectedDay).then((res) => {
      console.log(res);
      this.setState({
        mayChnageD1: false,
        mayChnageD2: false,
        mayChnageD3: false,
        mayChnageD4: false,
        mayChnageD5: false,
      });
      this._getDays(this.state.selectedCaloriesIndex);
    })
  }

  _deleteDay(id) {
    if (window.confirm("Вы уверены что хотите удалить?")) {
      remDay(this.state.user.api_token, id).then((res) => {
        console.log(res);

        this._getDays(this.state.selectedCaloriesIndex);
      })
    }

  }

  _clonePeriod() {
    cloneDay(this.state.user.api_token, this.state.selectedCaloriesIndex, formatDate(this.state.startDate), formatDate(this.state.endDate)).then((res) => {
      console.log(res);
      this.setState({ clonePeriodShow: false })
      this._getDays(this.state.selectedCaloriesIndex);
    })
  }

  _cloneDayOfWeek(_item, _did, _type) {
    let arr = [];
    let current = getWeekByNum(_item.date);
    this.state.daysList.map((item, index) => {
      if (getWeekByNum(item.date) == current) {
        arr.push(item.id);
      }
    })

    cloneDayOfWeek(this.state.user.api_token, arr, _did, _type).then((res) => {
      console.log(res, arr);
      this._getDays(this.state.selectedCaloriesIndex);
      this._load();
    })
  }

  _addPlan() {
    if (this.state.newPlanTitle.length > 0) {
      addPlan(this.state.user.api_token, this.state.newPlanTitle).then((res) => {
        console.log(res);
        this.setState({ addNewPlanShow: false });
        this._load();
      })
    }
  }

  _removePlan(_id) {
    if (window.confirm("Вы уверены что хотите удалить?")) {
      removePlan(this.state.user.api_token, _id).then((res) => {
        console.log(res)
        this._load();
      })
    }
  }

  _addImageToPlan() {
    this.setState({ loader: true });
    addFile(this.state.selectedFileName, 0).then((res) => {
      this.setState({ loader: false });
      console.log(res);
      if (res.success) {
        this._updateStateVal('image', res.response);
        this._updatePlan();
      } else {
        this._alert(res.response);
      }
    })
  }

  render() {

    var caloriesList = this.state.selectedPlan.calories.map((item, index) => {
      return (
        <button
          className="calorie-item"
          key={index}
          onClick={() => {
            console.log(item);
            this._getDays(item.id);
            this._updateStateDayVal("calories_id", item.id)
            this.setState({ selectedCalories: item, selectedCaloriesIndex: item.id });
          }}
        >
          <div className={"radio-item" + (this.state.selectedCaloriesIndex == item.id ? " active" : "")}>
            {this.state.selectedCaloriesIndex == item.id ? <img src={checkIcon} /> : null}
          </div>
          <div className="item-value">
            <span>{item.title_ru} ({item.from_cal} - {item.to_cal})</span>
          </div>
        </button>
      )
    })

    var days = this.state.daysList.map((item, index) => {

      return (
        <div className="day-card active" key={index}>
          <div className="row mb-2 align-items-center justify-content-between">
            <div className="col-md-2">
              <p>
                {formatDate(item.date)}<br />{getWeekByNum(item.date)}
              </p>
            </div>
            <div className="col-md-2 d-flex justify-content-end">
              <UiBtnIcon onClick={() => this._deleteDay(item.id)} color="#f9650b" icon={require('../../assets/imgs/ui/delete.svg')} mr2 />
            </div>
          </div>
          <div className="day-card-list">
            <div className="day-dinner">
              <div className="day-card-check">
                <button
                  className={"radio-item" + ((item.dinner1) ? " active" : "")}
                  onClick={() => { }}
                >
                  {(item.dinner1) ?
                    <img src={checkIcon} /> : null
                  }
                </button>
                <p>{global.locale.home_breakfast}</p>
              </div>
              <UiDinnerCard
                day={getWeekByNum(item.date)}
                onClick={() => {
                  this.setState({
                    showModal: true,
                    dishesEditNum1Active: true,
                    selectedDayEdit: item,
                  });
                }}
                onClickRemove={() => {
                  this._removeItemDay(item.id, 1);
                }}
                onClickClone={() => {
                  this._cloneDayOfWeek(item, item.dinner1[0].id, 1);
                }}

                empty={(item.dinner1) ? false : true}

                image={{ uri: (item.dinner1) ? (item.dinner1[0]).image : null }}
                title={(item.dinner1) ? (item.dinner1[0]).title_ru : null}
                ingredient={(item.dinner1) ? (item.dinner1).description_ru : null}
                ccal={(item.dinner1) ? (item.dinner1[0]).calories : null}
                protein={(item.dinner1) ? (item.dinner1[0]).protein : null}
                fats={(item.dinner1) ? (item.dinner1[0]).fats : null}
                carbo={(item.dinner1) ? (item.dinner1[0]).carbohydrates : null}
                price={(item.dinner1) ? (item.dinner1[0]).price : null}
              />
            </div>
            <div className="day-dinner">
              <div className="day-card-check">
                <button
                  className={"radio-item" + ((item.dinner2) ? " active" : "")}
                  onClick={() => { }}
                >
                  {(item.dinner2) ?
                    <img src={checkIcon} /> : null
                  }
                </button>
                <p>{global.locale.home_lunch}</p>
              </div>
              <UiDinnerCard
                day={getWeekByNum(item.date)}
                onClick={() => {
                  this.setState({
                    showModal: true,
                    dishesEditNum2Active: true,
                    selectedDayEdit: item,
                  });
                }}
                onClickRemove={() => {
                  this._removeItemDay(item.id, 2);
                }}
                onClickClone={() => {
                  this._cloneDayOfWeek(item, item.dinner2[0].id, 2);
                }}
                empty={(item.dinner2) ? false : true}

                image={{ uri: (item.dinner2) ? (item.dinner2[0]).image : null }}
                title={(item.dinner2) ? (item.dinner2[0]).title_ru : null}
                ingredient={(item.dinner2) ? (item.dinner2[0]).description_ru : null}
                ccal={(item.dinner2) ? (item.dinner2[0]).calories : null}
                protein={(item.dinner2) ? (item.dinner2[0]).protein : null}
                fats={(item.dinner2) ? (item.dinner2[0]).fats : null}
                carbo={(item.dinner2) ? (item.dinner2[0]).carbohydrates : null}
                price={(item.dinner2) ? (item.dinner2[0]).price : null}
              />
            </div>
            <div className="day-dinner">
              <div className="day-card-check">
                <button
                  className={"radio-item" + ((item.dinner3) ? " active" : "")}
                  onClick={() => { }}
                >
                  {(item.dinner3) ?
                    <img src={checkIcon} /> : null
                  }
                </button>
                <p>{global.locale.home_brunch}</p>
              </div>

              <UiDinnerCard
                day={getWeekByNum(item.date)}
                onClick={() => {
                  this.setState({
                    showModal: true,
                    dishesEditNum3Active: true,
                    selectedDayEdit: item,
                  });
                }}
                onClickRemove={() => {
                  this._removeItemDay(item.id, 3);
                }}
                onClickClone={() => {
                  this._cloneDayOfWeek(item, item.dinner3[0].id, 3);
                }}
                empty={(item.dinner3) ? false : true}

                image={{ uri: (item.dinner3) ? (item.dinner3[0]).image : null }}
                title={(item.dinner3) ? (item.dinner3[0]).title_ru : null}
                ingredient={(item.dinner3) ? (item.dinner3[0]).description_ru : null}
                ccal={(item.dinner3) ? (item.dinner3[0]).calories : null}
                protein={(item.dinner3) ? (item.dinner3[0]).protein : null}
                fats={(item.dinner3) ? (item.dinner3[0]).fats : null}
                carbo={(item.dinner3) ? (item.dinner3[0]).carbohydrates : null}
                price={(item.dinner3) ? (item.dinner3[0]).price : null}
              />
            </div>
            <div className="day-dinner">
              <div className="day-card-check">
                <button
                  className={"radio-item" + ((item.dinner4) ? " active" : "")}
                  onClick={() => { }}
                >
                  {(item.dinner4) ?
                    <img src={checkIcon} /> : null
                  }
                </button>
                <p>{global.locale.home_afternoon}</p>
              </div>
              <UiDinnerCard
                day={getWeekByNum(item.date)}
                onClick={() => {
                  this.setState({
                    showModal: true,
                    dishesEditNum4Active: true,
                    selectedDayEdit: item,
                  });
                }}
                onClickRemove={() => {
                  this._removeItemDay(item.id, 4);
                }}
                onClickClone={() => {
                  this._cloneDayOfWeek(item, item.dinner4[0].id, 4);
                }}
                empty={(item.dinner4) ? false : true}

                image={{ uri: (item.dinner4) ? (item.dinner4[0]).image : null }}
                title={(item.dinner4) ? (item.dinner4[0]).title_ru : null}
                ingredient={(item.dinner4) ? (item.dinner4[0]).description_ru : null}
                ccal={(item.dinner4) ? (item.dinner4[0]).calories : null}
                protein={(item.dinner4) ? (item.dinner4[0]).protein : null}
                fats={(item.dinner4) ? (item.dinner4[0]).fats : null}
                carbo={(item.dinner4) ? (item.dinner4[0]).carbohydrates : null}
                price={(item.dinner4) ? (item.dinner4[0]).price : null}
              />
            </div>
            <div className="day-dinner">
              <div className="day-card-check">
                <button
                  className={"radio-item" + ((item.dinner5) ? " active" : "")}
                  onClick={() => { }}
                >
                  {(item.dinner5) ?
                    <img src={checkIcon} /> : null
                  }
                </button>
                <p>{global.locale.home_dinner}</p>
              </div>
              <UiDinnerCard
                day={getWeekByNum(item.date)}
                onClick={() => {
                  this.setState({
                    showModal: true,
                    dishesEditNum5Active: true,
                    selectedDayEdit: item,
                  });
                }}
                onClickRemove={() => {
                  this._removeItemDay(item.id, 5);
                }}
                onClickClone={() => {
                  this._cloneDayOfWeek(item, item.dinner5[0].id, 5);
                }}
                empty={!(item.dinner5)}

                image={{ uri: (item.dinner5) ? (item.dinner5[0]).image : null }}
                title={(item.dinner5) ? (item.dinner5[0]).title_ru : null}
                ingredient={(item.dinner5) ? (item.dinner5[0]).description_ru : null}
                ccal={(item.dinner5) ? (item.dinner5[0]).calories : null}
                protein={(item.dinner5) ? (item.dinner5[0]).protein : null}
                fats={(item.dinner5) ? (item.dinner5[0]).fats : null}
                carbo={(item.dinner5) ? (item.dinner5[0]).carbohydrates : null}
                price={(item.dinner5) ? (item.dinner5[0]).price : null}
              />
            </div>
          </div>
        </div>
      )
    })

    return (
      <div ref={this.divPage} id="page">
        <UiModalMenu show={this.state.showModal}
          dishes={this.state.dishesList}

          callBack={(val) => {
            if (this.state.dishesEditNum1Active) {
              this._addItemDay(this.state.selectedDayEdit.id, val.id, 1);
              this.setState({ dishesEditNum1Active: false });
            } else if (this.state.dishesEditNum2Active) {
              this._addItemDay(this.state.selectedDayEdit.id, val.id, 2);
              this.setState({ dishesEditNum2Active: false });
            } else if (this.state.dishesEditNum3Active) {
              this._addItemDay(this.state.selectedDayEdit.id, val.id, 3);
              this.setState({ dishesEditNum3Active: false });
            } else if (this.state.dishesEditNum4Active) {
              this._addItemDay(this.state.selectedDayEdit.id, val.id, 4);
              this.setState({ dishesEditNum4Active: false });
            } else if (this.state.dishesEditNum5Active) {
              this._addItemDay(this.state.selectedDayEdit.id, val.id, 5);
              this.setState({ dishesEditNum5Active: false });
            }

            if (this.state.dishesNum1Active) {
              this._updateStateDayVal("calories_id", this.state.selectedCaloriesIndex);
              this._updateStateDayVal("dinner1", val.id)
            } else if (this.state.dishesNum2Active) {
              this._updateStateDayVal("calories_id", this.state.selectedCaloriesIndex);
              this._updateStateDayVal("dinner2", val.id)
            } else if (this.state.dishesNum3Active) {
              this._updateStateDayVal("calories_id", this.state.selectedCaloriesIndex);
              this._updateStateDayVal("dinner3", val.id)
            } else if (this.state.dishesNum4Active) {
              this._updateStateDayVal("calories_id", this.state.selectedCaloriesIndex);
              this._updateStateDayVal("dinner4", val.id)
            } else if (this.state.dishesNum5Active) {
              this._updateStateDayVal("calories_id", this.state.selectedCaloriesIndex);
              this._updateStateDayVal("dinner5", val.id)
            }
            this.setState({ showModal: false, dishesNum1Active: false, dishesNum2Active: false, dishesNum3Active: false, dishesNum4Active: false, dishesNum5Active: false, });
          }}
          callBackClose={() => this.setState({ showModal: false, dishesNum1Active: false, dishesNum2Active: false, dishesNum3Active: false, dishesNum4Active: false, dishesNum5Active: false, })}
        />

        <UiLoader show={this.state.loader} />
        <UiAlert
          show={this.state.showAlert}
          title={this.state.alertTitle}
          text={this.state.alertText}
          callBack={() => this.setState({ showAlert: false })}
          leftBtnText="Ок"
        />

        <div className={"wrapper" + (this.state.showMenuBar ? ' scroll' : '')}>
          <div className="content">
            <UiHeader />
            <section className="section bg-gray">
              <div className="container">
                <div className="page-title">
                  <h2>{global.locale.home_active_plan}</h2>
                  <UiBtnPink
                    onClick={() => this.setState({ addNewPlanShow: !this.state.addNewPlanShow })}
                    btnText={global.locale.home_add_new_plan} btnsm black
                  />
                </div>
                <div className="row">
                  <div className="col-md-4">
                    {this.state.addNewPlanShow ?
                      <div className="form form-new-plan">
                        <UiFormInput label={global.locale.home_plan_name} callBack={(val) => this.setState({ newPlanTitle: val })} />
                        <UiBtnPinkOutline btnText={global.locale.home_save_plan} btnsm black onClick={() => this._addPlan()} />
                      </div>
                      : null
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="plans">
                      <UiPlansTab tabList={this.state.plansList} callBack={(val) => { this._getPlan(val) }} />

                      {/* Обычные программы */}

                      <div className="plan-info">
                        <div className="plan-info-wrap">
                          <div className="plan-info-card">
                            <UiTabPlanCard
                              planTitle={this.state.selectedPlan.title_ru}
                              planText={this.state.selectedPlan.description_ru}
                              planImage={this.state.selectedPlan.image ? { uri: this.state.selectedPlan.image } : require('../../assets/imgs/demo/keto.jpg')}
                            />
                            <div className="lang-setting">
                              <p>{global.locale.home_lang_select}:</p>
                              <div className="check-btns">
                                <button onClick={() => this.setState({ activeLang: 0 })} className={"check-btn" + (this.state.activeLang == "0" ? " active" : "")}>Čeština</button>
                                <button onClick={() => this.setState({ activeLang: 1 })} className={"check-btn" + (this.state.activeLang == "1" ? " active" : "")}>Русский</button>
                                <button onClick={() => this.setState({ activeLang: 2 })} className={"check-btn" + (this.state.activeLang == "2" ? " active" : "")}>English</button>
                              </div>
                            </div>
                          </div>
                          <div className="plan-info-form">
                            <div className="row">
                              <div className="col-md-4">

                                {this.state.activeLang == 0 ? <UiFormInput value={this.state.selectedPlan.title_ch} callBack={(val) => this._updateStateVal('title_ch', val)} label={global.locale.home_prog_name + " CH:"} /> : null}
                                {this.state.activeLang == 0 ? <UiFormInput value={this.state.selectedPlan.description_ch} callBack={(val) => this._updateStateVal('description_ch', val)} label={global.locale.home_info_on_card + ":"} /> : null}

                                {this.state.activeLang == 1 ? <UiFormInput value={this.state.selectedPlan.title_ru} callBack={(val) => this._updateStateVal('title_ru', val)} label={global.locale.home_prog_name + " RU:"} /> : null}
                                {this.state.activeLang == 1 ? <UiFormInput value={this.state.selectedPlan.description_ru} callBack={(val) => this._updateStateVal('description_ru', val)} label={global.locale.home_info_on_card + ":"} /> : null}

                                {this.state.activeLang == 2 ? <UiFormInput value={this.state.selectedPlan.title_en} callBack={(val) => this._updateStateVal('title_en', val)} label={global.locale.home_prog_name + " EN:"} /> : null}
                                {this.state.activeLang == 2 ? <UiFormInput value={this.state.selectedPlan.description_en} callBack={(val) => this._updateStateVal('description_en', val)} label={global.locale.home_info_on_card + ":"} /> : null}

                                <label className="btn-wrapper pink-button-outline btn-block">
                                  <div className="btn-back">
                                    <div className="btn-shadow black-shadow"></div>
                                  </div>
                                  <input type="file" style={{ display: 'none' }} onChange={(e) => {
                                    this.setState({ selectedFileName: e.target.files[0] }, () => {
                                      this._addImageToPlan();
                                    })
                                  }} required />
                                  <span className="btn btn-sm btn-pink-outline btn-block">{global.locale.home_change_image}</span>
                                </label>

                              </div>
                              <div className="col-md-5">
                                {this.state.activeLang == 0 ? <UiTextArea value={this.state.selectedPlan.full_description_ch} callBack={(val) => this._updateStateVal('full_description_ch', val)} label={global.locale.home_full_info_plan + ":"} /> : null}
                                {this.state.activeLang == 1 ? <UiTextArea value={this.state.selectedPlan.full_description_ru} callBack={(val) => this._updateStateVal('full_description_ru', val)} label={global.locale.home_full_info_plan + ":"} /> : null}
                                {this.state.activeLang == 2 ? <UiTextArea value={this.state.selectedPlan.full_description_en} callBack={(val) => this._updateStateVal('full_description_en', val)} label={global.locale.home_full_info_plan + ":"} /> : null}

                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <UiBtnPinkOutline btnText={global.locale.home_btn_save} onClick={() => this._updatePlan()} btnsm block black />
                                </div>
                                <div className="form-group">
                                  <UiBtnPink btnText={global.locale.home_btn_delete} onClick={() => this._removePlan(this.state.selectedPlan.id)} btnsm block black />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr />
                        <div className="row">
                          <div className="col-md-12">
                            <div className="calorie-info">
                              <div className="calorie-info-title">
                                <h3>{global.locale.home_calories_plan}{this.state.selectedPlan.title_ru}</h3>
                                <UiBtnGreen btnText={global.locale.home_btn_add} onClick={() => this.setState({ addNewCaloriesShow: !this.state.addNewCaloriesShow })} btnsm black />
                              </div>
                              {this.state.addNewCaloriesShow ?
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="form form-new-plan">
                                        <UiFormInput label={global.locale.home_named + " CH"} callBack={(val) => this._updateStateCalNewVal('title_ch', val)} />
                                        <UiTextArea label={global.locale.home_info} callBack={(val) => this._updateStateCalNewVal('description_ch', val)} />
                                        <UiBtnPinkOutline btnText={global.locale.home_save_calories} onClick={() => {
                                          this._addNewCalories();
                                        }} btnsm black />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form form-new-plan">
                                        <UiFormInput label={global.locale.home_named + " RU"} callBack={(val) => this._updateStateCalNewVal('title_ru', val)} />
                                        <UiTextArea label={global.locale.home_info} callBack={(val) => this._updateStateCalNewVal('description_ru', val)} />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form form-new-plan">
                                        <UiFormInput label={global.locale.home_named + " EN"} callBack={(val) => this._updateStateCalNewVal('title_en', val)} />
                                        <UiTextArea label={global.locale.home_info} callBack={(val) => this._updateStateCalNewVal('description_ch', val)} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                : null
                              }
                              <div className="calorie-info-list">
                                {caloriesList}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-3">
                                {this.state.activeLang == 0 ? <UiFormInput placeholder={this.state.selectedCalories.title_ch} callBack={(val) => this._updateStateCalVal('title_ch', val)} label={global.locale.home_type_calories + ":"} /> : null}
                                {this.state.activeLang == 1 ? <UiFormInput placeholder={this.state.selectedCalories.title_ru} callBack={(val) => this._updateStateCalVal('title_ru', val)} label={global.locale.home_type_calories + ":"} /> : null}
                                {this.state.activeLang == 2 ? <UiFormInput placeholder={this.state.selectedCalories.title_en} callBack={(val) => this._updateStateCalVal('title_en', val)} label={global.locale.home_type_calories + ":"} /> : null}

                                <div className="row">
                                  <div className="col-md-6">
                                    <UiFormInput placeholder={this.state.selectedCalories.from_cal} callBack={(val) => this._updateStateCalVal('from_cal', val)} />
                                  </div>
                                  <div className="col-md-6">
                                    <UiFormInput placeholder={this.state.selectedCalories.to_cal} callBack={(val) => this._updateStateCalVal('to_cal', val)} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                {this.state.activeLang == 0 ? <UiTextArea defaultValue={this.state.selectedCalories.description_ch} callBack={(val) => this._updateStateCalVal('description_ch', val)} label={global.locale.home_full_info_plan + ":"} /> : null}
                                {this.state.activeLang == 1 ? <UiTextArea defaultValue={this.state.selectedCalories.description_ru} callBack={(val) => this._updateStateCalVal('description_ru', val)} label={global.locale.home_full_info_plan + ":"} /> : null}
                                {this.state.activeLang == 2 ? <UiTextArea defaultValue={this.state.selectedCalories.description_en} callBack={(val) => this._updateStateCalVal('description_en', val)} label={global.locale.home_full_info_plan + ":"} /> : null}

                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <UiBtnPinkOutline btnText={global.locale.home_btn_save} onClick={() => this._updateCalories()} btnsm block black />
                                </div>
                                <div className="form-group">
                                  <UiBtnPink btnText={global.locale.home_btn_delete} onClick={() => this._removeCalories(this.state.selectedCaloriesIndex)} btnsm block black />
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="plan-home">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="calorie-info-title">
                                    <h3>{global.locale.home_list_dishes_day}{this.state.selectedPlan.title_ru} ({this.state.selectedCalories.title_ru})</h3>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4 col-lg-3 mb-2">
                                  <UiBtnGreen btnText={global.locale.home_btn_add_new_day} onClick={() => this.setState({ addNewDayShow: !this.state.addNewDayShow })} mr2 btnsm black block />
                                  {this.state.addNewDayShow ?
                                    <DatePicker
                                      onChange={(data) => {
                                        this.setState({ selectedDate: data });
                                        this._updateStateDayVal("date", formatDate(data))
                                        console.log(data)
                                      }}
                                      selected={this.state.selectedDate}
                                    /> : null}
                                </div>

                                <div className="col-md-4 col-lg-3 mb-2">
                                  <UiBtnGreenOutline btnText={global.locale.home_duplicate_period} onClick={() => this.setState({ clonePeriodShow: !this.state.clonePeriodShow })} btnsm black />
                                  {this.state.clonePeriodShow ?
                                    <div>
                                      <div className="calendar-wrap">
                                        <DatePicker
                                          onChange={(data) => {
                                            this.setState({ startDate: data });
                                            console.log(data)
                                          }}
                                          selected={this.state.startDate}
                                        />

                                        <DatePicker
                                          onChange={(data) => {
                                            this.setState({ endDate: data });
                                            console.log(data)
                                          }}
                                          selected={this.state.endDate}
                                        />
                                      </div>
                                      <div className="clone-btn-wrap">
                                        <UiBtnGreen btnText={global.locale.home_btn_copy} onClick={() => this._clonePeriod()} btnsm black block />
                                      </div>
                                    </div>
                                    : null}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">

                                  {this.state.addNewDayShow ?
                                    <div className="day-card">
                                      <div className="row">
                                        <div className="col-md-3">
                                          <p>{formatDate(this.state.selectedDate)}<br />{getWeekByNum(this.state.selectedDate)}</p>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="day-card-check">
                                            <p>{global.locale.home_can_change}</p>
                                            <div className="check-btns">
                                              <button onClick={() => this.setState({ mayChnageD1: !this.state.mayChnageD1 })} className={"check-btn" + (this.state.mayChnageD1 ? " active" : "")}>{global.locale.home_breakfast}</button>
                                              <button onClick={() => this.setState({ mayChnageD2: !this.state.mayChnageD2 })} className={"check-btn" + (this.state.mayChnageD2 ? " active" : "")}>{global.locale.home_lunch}</button>
                                              <button onClick={() => this.setState({ mayChnageD3: !this.state.mayChnageD3 })} className={"check-btn" + (this.state.mayChnageD3 ? " active" : "")}>{global.locale.home_brunch}</button>
                                              <button onClick={() => this.setState({ mayChnageD4: !this.state.mayChnageD4 })} className={"check-btn" + (this.state.mayChnageD4 ? " active" : "")}>{global.locale.home_afternoon}</button>
                                              <button onClick={() => this.setState({ mayChnageD5: !this.state.mayChnageD5 })} className={"check-btn" + (this.state.mayChnageD5 ? " active" : "")}>{global.locale.home_dinner}</button>

                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <UiBtnGreen btnText={global.locale.home_btn_add} onClick={() => this._addNewDay()} mr2 btnsm black block />
                                        </div>
                                      </div>

                                      <div className="day-card-list">
                                        <div className="day-dinner">
                                          <div className="day-card-check">
                                            <button
                                              className={"radio-item" + (this._getDishesById(this.state.selectedDay.dinner1) ? " active" : "")}
                                              onClick={() => { }}
                                            >
                                              {this._getDishesById(this.state.selectedDay.dinner1) ?
                                                <img src={checkIcon} /> : null
                                              }
                                            </button>
                                            <p>{global.locale.home_breakfast}</p>
                                          </div>
                                          <UiDinnerCard
                                            onClick={() => {
                                              this.setState({
                                                showModal: true,
                                                dishesNum1Active: true,
                                              });
                                            }}
                                            onClickRemove={() => {
                                              this._updateStateDayVal("dinner1", null)
                                            }}
                                            empty={this._getDishesById(this.state.selectedDay.dinner1) ? false : true}

                                            image={{ uri: this._getDishesById(this.state.selectedDay.dinner1) ? this._getDishesById(this.state.selectedDay.dinner1).image : null }}
                                            title={this._getDishesById(this.state.selectedDay.dinner1) ? this._getDishesById(this.state.selectedDay.dinner1).title_ru : null}
                                            ingredient={this._getDishesById(this.state.selectedDay.dinner1) ? this._getDishesById(this.state.selectedDay.dinner1).description_ru : null}
                                            ccal={this._getDishesById(this.state.selectedDay.dinner1) ? this._getDishesById(this.state.selectedDay.dinner1).calories : null}
                                            protein={this._getDishesById(this.state.selectedDay.dinner1) ? this._getDishesById(this.state.selectedDay.dinner1).protein : null}
                                            fats={this._getDishesById(this.state.selectedDay.dinner1) ? this._getDishesById(this.state.selectedDay.dinner1).fats : null}
                                            carbo={this._getDishesById(this.state.selectedDay.dinner1) ? this._getDishesById(this.state.selectedDay.dinner1).carbohydrates : null}
                                            price={this._getDishesById(this.state.selectedDay.dinner1) ? this._getDishesById(this.state.selectedDay.dinner1).price : null}
                                          />
                                        </div>
                                        <div className="day-dinner">
                                          <div className="day-card-check">
                                            <button
                                              className={"radio-item" + (this._getDishesById(this.state.selectedDay.dinner2) ? " active" : "")}
                                              onClick={() => { }}
                                            >
                                              {this._getDishesById(this.state.selectedDay.dinner2) ?
                                                <img src={checkIcon} /> : null
                                              }
                                            </button>
                                            <p>{global.locale.home_lunch}</p>
                                          </div>
                                          <UiDinnerCard
                                            onClick={() => {
                                              this.setState({
                                                showModal: true,
                                                dishesNum2Active: true,
                                              });
                                            }}
                                            onClickRemove={() => {
                                              this._updateStateDayVal("dinner2", null)
                                            }}
                                            empty={this._getDishesById(this.state.selectedDay.dinner2) ? false : true}

                                            image={{ uri: this._getDishesById(this.state.selectedDay.dinner2) ? this._getDishesById(this.state.selectedDay.dinner2).image : null }}
                                            title={this._getDishesById(this.state.selectedDay.dinner2) ? this._getDishesById(this.state.selectedDay.dinner2).title_ru : null}
                                            ingredient={this._getDishesById(this.state.selectedDay.dinner2) ? this._getDishesById(this.state.selectedDay.dinner2).description_ru : null}
                                            ccal={this._getDishesById(this.state.selectedDay.dinner2) ? this._getDishesById(this.state.selectedDay.dinner2).calories : null}
                                            protein={this._getDishesById(this.state.selectedDay.dinner2) ? this._getDishesById(this.state.selectedDay.dinner2).protein : null}
                                            fats={this._getDishesById(this.state.selectedDay.dinner2) ? this._getDishesById(this.state.selectedDay.dinner2).fats : null}
                                            carbo={this._getDishesById(this.state.selectedDay.dinner2) ? this._getDishesById(this.state.selectedDay.dinner2).carbohydrates : null}
                                            price={this._getDishesById(this.state.selectedDay.dinner2) ? this._getDishesById(this.state.selectedDay.dinner2).price : null}
                                          />
                                        </div>
                                        <div className="day-dinner">
                                          <div className="day-card-check">
                                            <button
                                              className={"radio-item" + (this._getDishesById(this.state.selectedDay.dinner3) ? " active" : "")}
                                              onClick={() => { }}
                                            >
                                              {this._getDishesById(this.state.selectedDay.dinner3) ?
                                                <img src={checkIcon} /> : null
                                              }
                                            </button>
                                            <p>{global.locale.home_brunch}</p>
                                          </div>

                                          <UiDinnerCard
                                            onClick={() => {
                                              this.setState({
                                                showModal: true,
                                                dishesNum3Active: true,
                                              });
                                            }}
                                            onClickRemove={() => {
                                              this._updateStateDayVal("dinner3", null)
                                            }}
                                            empty={this._getDishesById(this.state.selectedDay.dinner3) ? false : true}

                                            image={{ uri: this._getDishesById(this.state.selectedDay.dinner3) ? this._getDishesById(this.state.selectedDay.dinner3).image : null }}
                                            title={this._getDishesById(this.state.selectedDay.dinner3) ? this._getDishesById(this.state.selectedDay.dinner3).title_ru : null}
                                            ingredient={this._getDishesById(this.state.selectedDay.dinner3) ? this._getDishesById(this.state.selectedDay.dinner3).description_ru : null}
                                            ccal={this._getDishesById(this.state.selectedDay.dinner3) ? this._getDishesById(this.state.selectedDay.dinner3).calories : null}
                                            protein={this._getDishesById(this.state.selectedDay.dinner3) ? this._getDishesById(this.state.selectedDay.dinner3).protein : null}
                                            fats={this._getDishesById(this.state.selectedDay.dinner3) ? this._getDishesById(this.state.selectedDay.dinner3).fats : null}
                                            carbo={this._getDishesById(this.state.selectedDay.dinner3) ? this._getDishesById(this.state.selectedDay.dinner3).carbohydrates : null}
                                            price={this._getDishesById(this.state.selectedDay.dinner3) ? this._getDishesById(this.state.selectedDay.dinner3).price : null}
                                          />
                                        </div>
                                        <div className="day-dinner">
                                          <div className="day-card-check">
                                            <button
                                              className={"radio-item" + (this._getDishesById(this.state.selectedDay.dinner4) ? " active" : "")}
                                              onClick={() => { }}
                                            >
                                              {this._getDishesById(this.state.selectedDay.dinner4) ?
                                                <img src={checkIcon} /> : null
                                              }
                                            </button>
                                            <p>{global.locale.home_afternoon}</p>
                                          </div>
                                          <UiDinnerCard
                                            onClick={() => {
                                              this.setState({
                                                showModal: true,
                                                dishesNum4Active: true,
                                              });
                                            }}
                                            onClickRemove={() => {
                                              this._updateStateDayVal("dinner4", null)
                                            }}
                                            empty={this._getDishesById(this.state.selectedDay.dinner4) ? false : true}

                                            image={{ uri: this._getDishesById(this.state.selectedDay.dinner4) ? this._getDishesById(this.state.selectedDay.dinner4).image : null }}
                                            title={this._getDishesById(this.state.selectedDay.dinner4) ? this._getDishesById(this.state.selectedDay.dinner4).title_ru : null}
                                            ingredient={this._getDishesById(this.state.selectedDay.dinner4) ? this._getDishesById(this.state.selectedDay.dinner4).description_ru : null}
                                            ccal={this._getDishesById(this.state.selectedDay.dinner4) ? this._getDishesById(this.state.selectedDay.dinner4).calories : null}
                                            protein={this._getDishesById(this.state.selectedDay.dinner4) ? this._getDishesById(this.state.selectedDay.dinner4).protein : null}
                                            fats={this._getDishesById(this.state.selectedDay.dinner4) ? this._getDishesById(this.state.selectedDay.dinner4).fats : null}
                                            carbo={this._getDishesById(this.state.selectedDay.dinner4) ? this._getDishesById(this.state.selectedDay.dinner4).carbohydrates : null}
                                            price={this._getDishesById(this.state.selectedDay.dinner4) ? this._getDishesById(this.state.selectedDay.dinner4).price : null}
                                          />
                                        </div>
                                        <div className="day-dinner">
                                          <div className="day-card-check">
                                            <button
                                              className={"radio-item" + (this._getDishesById(this.state.selectedDay.dinner5) ? " active" : "")}
                                              onClick={() => { }}
                                            >
                                              {this._getDishesById(this.state.selectedDay.dinner5) ?
                                                <img src={checkIcon} /> : null
                                              }
                                            </button>
                                            <p>{global.locale.home_dinner}</p>
                                          </div>
                                          <UiDinnerCard
                                            onClick={() => {
                                              this.setState({
                                                showModal: true,
                                                dishesNum5Active: true,
                                              });
                                            }}
                                            onClickRemove={() => {
                                              this._updateStateDayVal("dinner5", null)
                                            }}
                                            empty={!this._getDishesById(this.state.selectedDay.dinner5)}

                                            image={{ uri: this._getDishesById(this.state.selectedDay.dinner5) ? this._getDishesById(this.state.selectedDay.dinner5).image : null }}
                                            title={this._getDishesById(this.state.selectedDay.dinner5) ? this._getDishesById(this.state.selectedDay.dinner5).title_ru : null}
                                            ingredient={this._getDishesById(this.state.selectedDay.dinner5) ? this._getDishesById(this.state.selectedDay.dinner5).description_ru : null}
                                            ccal={this._getDishesById(this.state.selectedDay.dinner5) ? this._getDishesById(this.state.selectedDay.dinner5).calories : null}
                                            protein={this._getDishesById(this.state.selectedDay.dinner5) ? this._getDishesById(this.state.selectedDay.dinner5).protein : null}
                                            fats={this._getDishesById(this.state.selectedDay.dinner5) ? this._getDishesById(this.state.selectedDay.dinner5).fats : null}
                                            carbo={this._getDishesById(this.state.selectedDay.dinner5) ? this._getDishesById(this.state.selectedDay.dinner5).carbohydrates : null}
                                            price={this._getDishesById(this.state.selectedDay.dinner5) ? this._getDishesById(this.state.selectedDay.dinner5).price : null}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    : null}

                                  {days}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <UiFooter />
            <button className="top-button" onClick={() => this._scrollToTargetAdjusted("header")}>
              <img src={require('../../assets/imgs/ui/up-arrow.svg')} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;