export const en = {
  lang: 'en',
  /* LoginPage */
  dates_day_ago: " дней назад",
  dates_day_now: "сегодня",
  dates_day_sun: "вс",
  dates_day_mon: "пн",
  dates_day_tue: "вт",
  dates_day_wed: "ср",
  dates_day_thu: "чт",
  dates_day_fri: "пт",
  dates_day_sat: "сб",
  dates_month_1: "январь",
  dates_month_2: "февраль",
  dates_month_3: "март",
  dates_month_4: "апрель",
  dates_month_5: "май",
  dates_month_6: "июнь",
  dates_month_7: "июль",
  dates_month_8: "август",
  dates_month_9: "сентябрь",
  dates_month_10: "октябрь",
  dates_month_11: "ноябрь",
  dates_month_12: "декабрь",
  dates_month_pril_1: "января",
  dates_month_pril_2: "февраля",
  dates_month_pril_3: "марта",
  dates_month_pril_4: "апреля",
  dates_month_pril_5: "мая",
  dates_month_pril_6: "июня",
  dates_month_pril_7: "июля",
  dates_month_pril_8: "августа",
  dates_month_pril_9: "сентября",
  dates_month_pril_10: "октября",
  dates_month_pril_11: "ноября",
  dates_month_pril_12: "декабря",
  /* LoginPage */
  login_alert_log_error: "Не верный логин или пароль",
  login_alert_filled_error: "Заполните логин или пароль",
  login_mail: "Е-mail",
  login_pass: "Пароль",
  login_btn_enter: "Войти",
  /* HomePage */
  home_alert_pole_required: "Заполните поля",
  home_breakfast: "Завтрак",
  home_lunch: "Второй завтрак",
  home_brunch: "Обед",
  home_afternoon: "Полдник",
  home_dinner: "Ужин",
  home_active_plan: "Активные планы",
  home_add_new_plan: "Добавить новый план",
  home_plan_name: "Название плана",
  home_save_plan: "Сохранить план",
  home_lang_select: "Выбрать язык",
  home_prog_name: "Название программы",
  home_info_on_card: "Описание на карте",
  home_change_image: "Изменить изображение",
  home_full_info_plan: "Полное описание плана",
  home_btn_save: "Сохранить",
  home_btn_delete: "Удалить",
  home_calories_plan: "Калорийность: план ",
  home_btn_add: "Добавить",
  home_named: "Название",
  home_info: "Описание",
  home_save_calories: "Сохранить калорийность",
  home_type_calories: "Тип калорийности",
  home_list_dishes_day: "Список блюд по дням: план ",
  home_btn_add_new_day: "Добавить новый день",
  home_duplicate_period: "Продублировать период",
  home_btn_copy: "Копировать",
  home_can_change: "Можно изменить",
  home_add_new_category: "Добавить новую категорию",
  home_drink: "Напитки",
  /* FoodsPage */
  food_alert_pole_required: "Заполните все поля",
  food_choose_photo: "Выбрать фото",
  food_g: "г",
  food_kcal: "ккал",
  food_currency: "Kč",
  food_add_dishes: "Добавить блюдо",
  food_choose_lang: "Выбрать язык",
  food_name_dish: "Название блюда",
  food_composit_dish: "Состав блюда / Описание",
  food_kcal_in_100: "Калорийность в 100 г",
  food_B_in_100: "Белки в 100 г",
  food_J_in_100: "Жиры в 100 г",
  food_U_in_100: "Углеводы в 100 г",
  food_serving_weight: "Вес порции",
  food_price: "Цена",
  food_btn_add: "Добавить",
  food_dishes_search: "Поиск блюд",
  food_image: "Изображение",
  food_name: "Название",
  food_composition: "Состав",
  food_weight: "Вес",
  food_calories: "Калории",
  food_B: "Белки",
  food_J: "Жиры",
  food_U: "Углеводы",
  food_cost: "Стоимость",
  food_control: "Управление",
  home_food_menu_option_4_item_1: "Пн - Сб",
  home_food_menu_option_4_item_2: "Пн - Пт",
  /* OrdersPage */
  ord_not_payed: "не оплачен",
  ord_payed: "оплачен",
  ord_btn_list_orders: "Список заказов",
  ord_btn_list_dishes: "Список блюд",
  ord_btn_statist: "Статистика",
  ord_all: "Все ",
  ord_ordera: "заказа",
  ord_order: "заказ",
  ord_orders: "заказов",
  ord_list_orders: "Список заказов",
  ord_status: "Статус",
  ord_FIO: "ФИО заказчика",
  ord_phone: "телефон",
  ord_data_create: "Дата создания",
  ord_comment: "Комментарий",
  ord_delievery_adress: "Адрес доставки",
  ord_main_dishes: "Основные блюда",
  ord_second_dishes: "Доп блюда",
  ord_drinks: "Напитки",
  ord_name: "Название",
  ord_composition: "Состав",
  ord_serving_size: "Размер порции",
  ord_quality: "Кол-во",
  ord_orders_num: "Номера заказов",
  ord_dish: "Блюдо",
  ord_rate: "Оценка",
  ord_data_rate: "Дата оценки",
  /* CourierOrdersPage */
  сour_msg_1: "Ваш заказ передан в доставку, ожидайте в назначенное время",
  сour_msg_2: "Ваш заказ успешно доставлен. Приятного аппетита.",
  сour_btn_bild_path: "Построить маршрут",
  сour_dop_dish: "Доп. блюдо",
  сour_not_payed: "Не оплачен",
  сour_btn_take_in_delivery: "Взять в доставку",
  сour_btn_delivered: "Доставлен",
  сour_btn_list_oreders: "Список заказов",
  сour_btn_delivereds: "Доставлены",
  сour_chosed_day: "Выбранный день",
  сour_num: "Номер заказа",
  сour_time: "Время ожидаемой доставки",
  сour_adress: "Адрес",
  сour_client: "Получатель",
  сour_composition: "Состав",
  сour_cost: "Сумма заказа",
  сour_status: "Статус оплаты",
  сour_rule: "Управление",
  /* PlansPage */
  plans_alert_need_pay_1: "Вам необходимо будет доплатить",
  plans_alert_need_pay_2: "курьеру за блюдо",
  plans_alert_save_err: "Не возможно сохранить без указания улицы и кода здания",
  plans_user: "Пользователь",
  plans_not_plans_for_day: "Планов на этот день нет",
  plans_btn_back: "Назад",

  plans_alert_error: "Ошибка обновления",
  plans_not_paid: "Не оплачен",
  plans_not_paid_from: "с",
  plans_not_paid_for: "по",
  plans_header: "Профиль iKitchen",
  plans_title: "Активные планы",
  plans_li_1: "Меню",
  plans_li_2: "Доставка",
  plans_li_3: "Оценки",
  plans_info: "в дни, отмеченные данной иконкой, вы можете дополнительно настроить меню.",
  plans_kkal: "ккал",
  plans_summary_in: "Всего во",
  plans_B: "Б",
  plans_J: "Ж",
  plans_U: "У",
  plans_drink: "напиток",
  plans_currency: "Kč",
  plans_gramm: "г.",
  plans_dinner: "Завтрак",
  plans_text: "Фотографии блюд на сайте являются вариантом сервировки блюда. Внешний вид блюда может отличаться от фотографии на сайте.",
  plans_empty_orders: "У вас пока не было заказов",
  plans_btn_go_to_menu: "Перейти в меню",
  /* UsersPage */
  user_admin: "Администратор",
  user_manager: "Менеджер",
  user_courier: "Курьер",
  user_std_man: "Пользователь",
  user_all: "Все",
  user_std_mans: "Пользователи",
  user_staff: "Сотрудники",
  user_type: "Тип",
  user_name_std_man: "Имя пользователя",
  user_phone: "Телефон",
  user_mail: "Почта",
  user_active_programs: "Активные программы",
  user_reg_data: "Дата регистрации",
  user_bonus: "Бонусы",
  user_post: "Должность",
  //UiHeader
  head_plans: "Планы",
  head_dishes: "Блюда",
  head_list_orders: "Список заказов",
  head_my_orders: "Мои заказы",
  head_list_clients: "Список клиентов",
  //UiFooter
  footer_logo_text: "iKitchen",
  footer_adress: "Address",
  footer_adress_text_0: "iCatering s.r.o.",
  footer_adress_text_1: "Company ID: 09092684",
  footer_adress_text_2: "VAT No.: CZ09092684",
  footer_adress_text_3: "Evropská 157a, Prague 6",
  footer_adress_text_4: "info@ikitchen.cz",
  footer_phone: "Phone",
  footer_phone_num: "+420 222 744 130",
  footer_brend: "iKitchen",
  footer_ARR: "All Rights Reserved - 2020",
  //UiDinnerCard
  dinner_сard_add: "Добавить",
  dinner_сard_btn_swap: "Заменить",
  dinner_сard_btn_delete: "Удалить",
  dinner_сard_btn_to_all: "ко всем",
  dinner_сard_composition: "Состав",
  dinner_сard_in_100_g: "в 100 г",
  dinner_сard_kCal: "ккал",
  dinner_сard_B: "Б",
  dinner_сard_J: "Ж",
  dinner_сard_U: "У",
  dinner_сard_currency: "Kč",
  //UiPlanCard
  plan_card_add: "Добавить",
  plan_card_btn_swap: "Заменить",
  plan_card_btn_delete: "Удалить",
  plan_card_composition: "Состав",
  plan_card_in_100_g: "в 100 г",
  plan_card_kCal: "ккал",
  plan_card_B: "Б",
  plan_card_J: "Ж",
  plan_card_U: "У",
  plan_card_cost: "Стоимость",
  plan_card_currency: "Kč",
  plan_card_btn_pick: "Выбрать",
  //UiSwitch
  switch_evening: "Вечером",
  switch_morning: "Утром",
  //UiModalMenu
  modal_menu_kCal: "ккал",
  modal_menu_g: "г.",
  modal_menu_tuesday: "вторник",
  modal_menu_breakfast: "завтрак",
  modal_menu_add_dish: "Добавить блюдо в выбранный день",
  modal_menu_text: "В списке отображаются все блюда, закрепленные к выбранной программе. Для изменения списка перейдите во вкладку Блюда.",
  modal_menu_btn_close: "Закрыть",
  modal_menu_search: "Поиск",
  // UiModalPay
  modal_pay_on: "on",
  modal_pay_days: "days",
  modal_pay_currency: "CZK",
  modal_pay_bonus: "Available bonuses for payment ",
  modal_pay_pay_bonus: "Pay with bonuses",
  modal_pay_promocode: "Promo Code",
  modal_pay_to_pay: "Payable",
  modal_pay_NDS: "Including VAT",
  modal_pay_pay_card: "Pay by credit card",
  modal_pay_placeholder: "CARDHOLDER NAME",
  modal_pay_cash_to_courier: "Cash on delivery ",
  modal_pay_pay_card_to_courier: "Payment by card to the courier upon delivery",
  modal_pay_bank_transfer: "Bank Transfer",
  modal_pay_btn_pay: "Pay",
  modal_pay_btn_order: "Order",
  //UiDeliveryItem
  delivery_item_delivery: "Portion delivery in",
  delivery_item_day: "morning",
  delivery_item_evening: " evening",
  delivery_item_adress: "Shipping Address",
  delivery_item_btn_change: "Edit",
  delivery_item_wishes: "Comments",
}