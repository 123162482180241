import React, { Component }  from 'react';
import { formatDateDots } from '../../components/common/Date';

export const renderXLS = (_orders, _date, _change) => {
    let list = _orders.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.id}</td>
          <td>
            {item.is_pay == 0 ? <spann>{global.locale.ord_not_payed}</spann> : null}
            {item.is_pay == 1 ? <spann>{global.locale.ord_payed}</spann> : null}
          </td>
          <td>
            {item.user ? `${item.user.name} ${item.user.lname}` : null}
          </td>
          <td>{item.user ? item.user.phone : null}</td>
          <td>{item.user ? item.user.mail : null}</td>

          <td style={{ minWidth: '70px' }}> {formatDateDots(item.start_date)} </td>
          <td style={{ minWidth: '70px' }}> {formatDateDots(item.end_date)} </td>
          <td>
            {item.user ? item.user.exclude : null}
           
          </td>
          <td> 
            {item.user ? item.user.comment : null}
          </td>
          <td>
            {item.user ?
              <div>

                {_change(item.deliveries, _date) ?
                  _change(item.deliveries, _date).city
                  :
                  item.user ? item.user.city : null
                }

              </div>
              : null}
          </td>
          <td>
            {item.user ?
              <div>
                {_change(item.deliveries, _date) ?
                  _change(item.deliveries, _date).street
                  :
                  item.user ? item.user.street : null
                }
              </div>
              : null}
          </td>
          <td>{item.user ? item.user.code : null}</td>
          <td>
            {item.plan.title_ch}
          
          </td>

          <td>
            
            {item.size == 0 ? <span> все порции </span> : null}
            {item.size == 1 ? <span> завтрак + обед </span> : null}
            {item.size == 2 ? <span> завтрак + ужин </span> : null}
            {item.size == 3 ? <span> обед + ужин </span> : null}
            {item.size == 4 ? <span> завтрак +  обед +  ужин </span> : null}

          </td>
          <td>
            {item.calorie.title_ch}

          </td>

          <td>

            {item.program_size == 0 ? global.locale.home_food_menu_option_4_item_1 : global.locale.home_food_menu_option_4_item_2}
          </td>
          <td>{item.count}</td>
          <td>{item.days}</td>


          <td>

            {item.price}
          </td>

          <td>{
            item.type == 1 ? global.locale.modal_pay_cash_to_courier :
              item.type == 2 ? global.locale.modal_pay_pay_card_to_courier :
                item.type == 3 ? global.locale.modal_pay_bank_transfer : null
          }</td>
        </tr>
      )


    });


    return (
      <table id="xlsRiRoTableMain" className="table table-bordered">
        <tr>
          <th>№</th>
          <th>{global.locale.ord_status}</th>
          <th>{global.locale.ord_FIO}</th>
          <th>{global.locale.ord_phone}</th>
          <th>EMail</th>
          <th>Дата старта</th>
          <th>Дата окончания</th>
          <th>Исключение</th>
          <th>{global.locale.ord_comment}</th>
          <th>Город</th>
          <th>{global.locale.ord_delievery_adress}</th>
          <th>Code</th>
          <th>Колорийность</th>
          <th>{global.locale.home_prog_name}</th>
          <th>{global.locale.home_type_calories}</th>
          <th>Длительность</th>
          <th>Count</th>
          <th>Days</th>
          <th>{global.locale.food_price}</th>
          <th>Comment</th>
        </tr>
        {list}
      </table>
    )
  }

  export const  printDelivery = async () => {

 
     return  setTimeout(() => {

        let table = "xlsRiRoTableMain", name = "xlsRiRoTableMain", filename = "RiRo";
        let uri = 'data:application/vnd.ms-excel;base64,',
          template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><title></title><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
          base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }, format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }

        if (!table.nodeType) table = document.getElementById(table)
        var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML.replace(/<span>/g, '<span style="mso-rotate:90;">') }

        var link = document.createElement('a');
        link.download = filename;
        link.href = uri + base64(format(template, ctx));
        link.click();

 
      }, 1000);

 

    //new window.TableExport(document.getElementById("xlsTable"));


  }