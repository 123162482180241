import React, { Component } from 'react';

import docIcon from '../../../assets/imgs/profile/document.svg';

class UiTabPlanCard extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <button className="tab-card">
        <div className="tab-card-img" style={{ backgroundImage: "url(" + this.props.planImage + ")" }}></div>
        <div className="tab-card-text">
          <h4>{this.props.planTitle}</h4>
          <p>{this.props.planText}</p>
        </div>
      </button>
    );
  }
}
export default UiTabPlanCard;