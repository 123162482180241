
export const getFormatedCurrentDate = () => {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  return [year, month, day].join('-');
}

export const formatDate2 = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month].join('.');
}


export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export const formatDateDdMm = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day,month].join('.');
}


export const formatTime = (time) => {
  //console.log(time);
  let l = time.split(":");
  return l[0] + ":" + l[1];
}

export const formatPointTime = (time) => {
  return time.hour + ':' + time.minutes;
}

export const getTimeArrFromString = (time) => {
  let l = time.split(":");
  return l;
}

export const subtractTime1FromTime2 = (startTime, endTime) => {
  var s = startTime.split(':');
  var e = endTime.split(':');

  var end = new Date(0, 0, 0, parseInt(e[0], 10), parseInt(e[1], 10), 0);
  var start = new Date(0, 0, 0, parseInt(s[0], 10), parseInt(s[1], 10), 0);

  var elapsedMs = end - start;
  var elapsedMinutes = elapsedMs / 1000 / 60;

  return elapsedMinutes;
}

export const addTimeToCurrent = (time) => {
  let d = new Date();
  let date = new Date(d.getTime() + time * 60000);
  let hours = date.getHours();
  let minutes = date.getMinutes();

  if (hours < 10) hours = '0' + hours;
  if (minutes < 10) minutes = '0' + minutes;
  return hours + ":" + minutes;
}

export const getTimeAgo = (endTime) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const oneHour = 60 * 60 * 1000;
  const oneMinute = 60 * 1000;
  const firstDate = new Date();
  const secondDate = new Date(endTime);
  var diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  var diffDaysH = Math.round(Math.abs((firstDate - secondDate) / oneHour));
  var diffDaysM = Math.round(Math.abs((firstDate - secondDate) / oneMinute));



  if (diffDays > 30) {
    diffDays = formatDateDots(endTime);
  } else {
    if (diffDays > 0) {
      diffDays = diffDays + " " + global.locale.dates_day_ago;
    } else if (diffDays == 0) {
      diffDays = global.locale.dates_day_now;
    }
  }

  return diffDays;
}

export const getTimeRemaingMS = (startTime, endTime) => {
  let date1 = new Date(endTime);
  let date2 = new Date(startTime);
  let diffTime = Math.abs(date2 - date1);
  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

export const isInRange = (startTime, endTime, dayOffset) => {
  var s = startTime.split(':');
  var e = endTime.split(':');
  var current_time = new Date();
  var DD = 5 - 1;
  var offDay = 5 + parseInt(dayOffset) - 1;
  var offDay2 = 5 - parseInt(dayOffset) - 1;
  var MM = 0;
  var HH = parseInt(current_time.getHours());
  if (HH < 10) HH = "0" + HH;
  if (DD < 10) DD = "0" + DD;
  if (offDay < 10) offDay = "0" + offDay;
  if (offDay2 < 10) offDay2 = "0" + offDay2;
  if (parseInt(current_time.getMinutes()) < 10) MM = "0" + MM; else MM = parseInt(current_time.getMinutes());

  var open_time = Date.parse("2019-09-" + offDay2 + "T" + startTime);
  var close_time = Date.parse("2019-09-" + (offDay) + "T" + endTime);
  var check_val = Date.parse("2019-09-" + (DD) + "T" + HH + ":" + MM + ":00");

  // console.log( "2019-09-"+offDay2+"T"+startTime ,"2019-09-"+( offDay )+"T"+endTime , "2019-09-"+( DD )+"T"+HH+":"+MM+":00",  check_val > open_time && check_val < close_time )
  if (check_val > open_time && check_val < close_time) {
    return true;
  } else {
    return false;
  }
}

export const diffDateWithCurrent = (t) => {
  let current_time = new Date();
  let l = t.split(":");

  if (parseInt(l[0]) > current_time.getHours()) {
    return true;
  } else if (parseInt(l[0]) == current_time.getHours() && parseInt(l[1]) > current_time.getMinutes()) {
    return true;
  } else {
    return false;
  }
}

export const convertDayTitle = (dd) => {
  let arr = [
    { en: 'SUNDAY', ru: global.locale.dates_day_sun },
    { en: 'MONDAY', ru: global.locale.dates_day_mon },
    { en: 'TUESDAY', ru: global.locale.dates_day_tue },
    { en: 'WEDNESDAY', ru: global.locale.dates_day_wed },
    { en: 'THURSDAY', ru: global.locale.dates_day_thu },
    { en: 'FRIDAY', ru: global.locale.dates_day_fri },
    { en: 'SATURDAY', ru: global.locale.dates_day_sat },
  ];
  let val = "";
  arr.map((item) => {
    if (item.en == dd) val = item.ru;
  });
  return val;
}

export const convertToSchedule = (_schedule) => {


  let arr = [[]];
  let arrIndex = 0;

  _schedule.sort(function (a, b) { return (getWeekNumberRus(a.dayOfWeek) > getWeekNumberRus(b.dayOfWeek)) ? 1 : ((getWeekNumberRus(b.dayOfWeek) > getWeekNumberRus(a.dayOfWeek)) ? -1 : 0); });
  console.log(_schedule);

  _schedule.map((item, index) => {
    if (_schedule[index + 1]) {
      if (item.startTime == _schedule[index + 1].startTime && item.endTime == _schedule[index + 1].endTime && getWeekNumberRus(item.dayOfWeek) == getWeekNumberRus(_schedule[index + 1].dayOfWeek) - 1) {
        arr[arrIndex].push(item);
      } else {

        arr[arrIndex].push(item);
        arrIndex++;
        arr[arrIndex] = [];
      }
    } else {
      //console.log("SDSDSD ", index , _schedule.length - 1);
      if (index = _schedule.length - 1) {
        if (item.startTime == _schedule[index - 1].startTime && item.endTime == _schedule[index - 1].endTime) {
          arr[arrIndex].push(item);
        } else {
          arrIndex++;
          arr[arrIndex] = [];
          arr[arrIndex].push(item);

        }

      } else {
        arrIndex++;
        arr[arrIndex] = [];
        arr[arrIndex].push(item);

      }

    }
  })

  let str = "";
  let strArr = [];
  arr.map((item, index) => {
    if (item.length > 1) {
      strArr.push(convertDayTitle(item[0].dayOfWeek) + "-" + convertDayTitle(item[item.length - 1].dayOfWeek) + ": " + formatTime(item[0].startTime) + "-" + formatTime(item[item.length - 1].endTime));
    }
    if (item.length == 1) {
      strArr.push(convertDayTitle(item[0].dayOfWeek) + ": " + formatTime(item[0].startTime) + "-" + formatTime(item[0].endTime));
    }
  })
  console.log(arr, strArr)
  return strArr.join('\n');
}


export const getWeekArr = () => {
  return ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
}

export const getWeekByNum = (dateString) => {
  var d = new Date(dateString);

  let arr = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
  return arr[d.getDay()];
}

export const getWeekNumber = (title) => {
  let fl = 0;
  let arr = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
  arr.map((item, index) => { if (title.toUpperCase() == item) fl = index; })
  return fl;
}

export const getWeekNumberRus = (title) => {
  let fl = 0;
  let arr = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
  arr.map((item, index) => { if (title.toUpperCase() == item) fl = index; })
  return fl;
}

export const getWeekTitleByNum = (num) => {
  let arr = [global.locale.dates_day_sun, global.locale.dates_day_mon, global.locale.dates_day_tue, global.locale.dates_day_wed, global.locale.dates_day_thu, global.locale.dates_day_fri, global.locale.dates_day_sat];
  return arr[num];
}



export const formatDateDotsCurr = () => {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('.');
}

export const formatDateDots = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('.');
}

export const formatDateTimeDots = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hours = d.getHours(),
    minutes = d.getMinutes();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hours < 10) hours = '0' + hours;
  if (minutes < 10) minutes = '0' + minutes;

  return [day, month, year].join('.') + ", " + [hours, minutes].join(':');;
}


export const formatDateString = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth()),
    day = '' + d.getDate(),
    year = d.getFullYear();



  var months = [global.locale.dates_month_pril_1, global.locale.dates_month_pril_2, global.locale.dates_month_pril_3, global.locale.dates_month_pril_4, global.locale.dates_month_pril_5, global.locale.dates_month_pril_6, global.locale.dates_month_pril_7, global.locale.dates_month_pril_8, global.locale.dates_month_pril_9, global.locale.dates_month_pril_10, global.locale.dates_month_pril_11, global.locale.dates_month_pril_12];
  var week = [global.locale.dates_day_mon, global.locale.dates_day_tue, global.locale.dates_day_wed, global.locale.dates_day_thu, global.locale.dates_day_fri, global.locale.dates_day_sat, global.locale.dates_day_sun];

  return day + ' ' + months[month] + ', ' + week[d.getDay()];
}


export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}



export const getCurrentDateDots = () => {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('.');
}


export const formatDateArr = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth()),
    day = '' + d.getDate(),
    year = d.getFullYear();

  var months = [global.locale.dates_month_1, global.locale.dates_month_2, global.locale.dates_month_3, global.locale.dates_month_4, global.locale.dates_month_5, global.locale.dates_month_6, global.locale.dates_month_7, global.locale.dates_month_8, global.locale.dates_month_9, global.locale.dates_month_10, global.locale.dates_month_11, global.locale.dates_month_12];

  return [day, months[month], month, year];
}


export const formatDateToLocal = (date) => {
  var d = new Date(date);
  var millis = (d.getTime() - (Math.abs(d.getTimezoneOffset()) * 60000));

  d.setTime(millis);

  return d;
}


export const getMonthNumber = (month) => {
  var months = [global.locale.dates_month_1, global.locale.dates_month_2, global.locale.dates_month_3, global.locale.dates_month_4, global.locale.dates_month_5, global.locale.dates_month_6, global.locale.dates_month_7, global.locale.dates_month_8, global.locale.dates_month_9, global.locale.dates_month_10, global.locale.dates_month_11, global.locale.dates_month_12];
  var _index = 0;
  months.map((item, index) => {
    if (item == month) _index = index;
  });
  return _index;
}

export const formatDate2Plus = (date, _v) => {
  var today = new Date(date);
  var d = new Date(today);
  d.setDate(d.getDate() + _v);

  var month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month].join('.');
}

export const getDay = (date) => {
  var d = new Date(date),
      day = d.getDay();

  return day;
}

export const formatDateYMD = (_date) => {
  var d = new Date(_date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');

}


export const getLastDeliveredDay = (_date) => {

  let _bfday = new Date(_date);
  _bfday.setDate(_bfday.getDate() - 1);
  let f = false;
  while(!f){
      if(_bfday.getDay() != 0 && _bfday.getDay() != 2 && _bfday.getDay() != 4 ){
          _bfday.setDate(_bfday.getDate() - 1);
      } else {
          f = true;
      }
  }
 
  return _bfday;
}

export const generateDatesIntervalArray = (start, end) => {

  for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
      arr.push(new Date(dt));
  }
  return arr;
  

}
