import React, { Component } from 'react';

import leftIcon from '../../../assets/imgs/ui/left-round.svg';
import leftIconActive from '../../../assets/imgs/ui/left-round-active.svg';
import leftIconDis from '../../../assets/imgs/ui/left-round-gray.svg';
import rightIcon from '../../../assets/imgs/ui/right-round.svg';
import rightIconActive from '../../../assets/imgs/ui/right-round-active.svg';
import rightIconDis from '../../../assets/imgs/ui/right-round-gray.svg';

class UiSwitch extends Component {

  state = {
    checkActive: false,
  }

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.selectValue != undefined && this.props.selectValue) {
      this.setState({ selectValue: this.props.selectValue });
    }
  }
  componentDidUpdate(nProps, nState) {
    if (nProps.clear != undefined && nProps.clear) {
      this.setState({ selectValue: null });
      this.props.clearCallBack(!nProps.clear);
    }
    if (this.props.selectValue != undefined && this.props.selectValue && nProps.selectValue != this.state.selectValue) {
      this.setState({ selectValue: this.props.selectValue });
    }
    return true;
  }

  setValue = (val) => {
    this.setState({ selectValue: val });
    this.props.callBack(val);
  }

  _nextClick() {
    console.log('000');
    if (this.state.currentIndex < (this.props.optionList.length - 1)) {
      this.setState({ currentIndex: this.state.currentIndex + 1 });
    }
  }
  _prevClick() {
    if (this.state.currentIndex > 0) {
      this.setState({ currentIndex: this.state.currentIndex - 1 });
    }
  }

  render() {
    return (
      <button className={"switch" + (this.state.checkActive ? " active" : "")} onClick={() => this.setState({ checkActive: !this.state.checkActive })}>
        <div className="switch-check"></div>
        {this.state.checkActive ?
          <span className="switch-label">{global.locale.switch_evening}</span> :
          <span className="switch-label">{global.locale.switch_morning}</span>
        }
      </button>
    );
  }
}
export default UiSwitch;