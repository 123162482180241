
export const getCashBack = (_price, _procent) => {
    return Math.round( (_price * _procent) / 100 );
}


export const getPriceFromArray = (_list, _dayCount, _type, _people) => {
    //console.log(_list,  _dayCount, _type, _people);
    let _fprice = 0;
    _list.map((item, index) => {
        if (index + 1 < _dayCount) {
            if (_type == 0) {
                _fprice = _fprice + (item.dinner1 ? item.dinner1[0].price : 0) + (item.dinner2 ? item.dinner2[0].price : 0) + (item.dinner3 ? item.dinner3[0].price : 0) + (item.dinner4 ? item.dinner4[0].price : 0) + (item.dinner5 ? item.dinner5[0].price : 0);
            } else if (_type == 1) {
                _fprice = (_fprice + item.dinner1 ? item.dinner1[0].price : 0) + (item.dinner3 ? item.dinner3[0].price : 0);
            } else if (_type == 2) {
                _fprice = (_fprice + item.dinner1 ? item.dinner1[0].price : 0) + (item.dinner5 ? item.dinner5[0].price : 0);
            }
        }

    })
    if (_type != 0) {
        _fprice = _fprice + _fprice * 0.02;
    }

    return _fprice * _people;
}


export const getPriceFromArraySales = (_list, _dayCount, _type, _people, _sales) => {
    let _fprice = 0;
    _list.map((item, index) => {
        if (index + 1 < _dayCount) {
            if (_type == 0) {
                _fprice = _fprice + (item.dinner1 ? item.dinner1[0].price : 0) + (item.dinner2 ? item.dinner2[0].price : 0) + (item.dinner3 ? item.dinner3[0].price : 0) + (item.dinner4 ? item.dinner4[0].price : 0) + (item.dinner5 ? item.dinner5[0].price : 0);
            } else if (_type == 1) {
                _fprice = (_fprice + item.dinner1 ? item.dinner1[0].price : 0) + (item.dinner3 ? item.dinner3[0].price : 0);
            } else if (_type == 2) {
                _fprice = (_fprice + item.dinner1 ? item.dinner1[0].price : 0) + (item.dinner5 ? item.dinner5[0].price : 0);
            }
        }

    })
   
    if (_type != 0) {
        _fprice = _fprice + _fprice * 0.02;
    }


    return _fprice * _people - ((_sales / 100) * _fprice * _people);
}


export const getPriceFromArrayEs = (_list, _eslist, _dayCount, _type, _people) => {
    //console.log(_list, _eslist, _dayCount, _type, _people);
    let _fprice = 0;
    _list.map((item, index) => {
        if (index + 1 < _dayCount) {
            if (_type == 0) {
                _fprice = _fprice + (item.dinner1 ? item.dinner1[0].price : 0) + (item.dinner2 ? item.dinner2[0].price : 0) + (item.dinner3 ? item.dinner3[0].price : 0) + (item.dinner4 ? item.dinner4[0].price : 0) + (item.dinner5 ? item.dinner5[0].price : 0);
            } else if (_type == 1) {
                _fprice = (_fprice + item.dinner1 ? item.dinner1[0].price : 0) + (item.dinner3 ? item.dinner3[0].price : 0);
            } else if (_type == 2) {
                _fprice = (_fprice + item.dinner1 ? item.dinner1[0].price : 0) + (item.dinner5 ? item.dinner5[0].price : 0);
            }
        }

    })

    _eslist.map((item) => {
        _fprice = _fprice + (item.item.price);
    })

    if(_type != 0){
        _fprice = _fprice + _fprice*0.02; 
    }

    
    return _fprice * _people;
}


export const getPriceFromArraySalesEs = (_list, _eslist, _dayCount, _type, _people, _sales) => {
    let _fprice = 0;
    _list.map((item, index) => {
        if (index + 1 < _dayCount) {
            if (_type == 0) {
                _fprice = _fprice + (item.dinner1 ? item.dinner1[0].price : 0) + (item.dinner2 ? item.dinner2[0].price : 0) + (item.dinner3 ? item.dinner3[0].price : 0) + (item.dinner4 ? item.dinner4[0].price : 0) + (item.dinner5 ? item.dinner5[0].price : 0);
            } else if (_type == 1) {
                _fprice = (_fprice + item.dinner1 ? item.dinner1[0].price : 0) + (item.dinner3 ? item.dinner3[0].price : 0);
            } else if (_type == 2) {
                _fprice = (_fprice + item.dinner1 ? item.dinner1[0].price : 0) + (item.dinner5 ? item.dinner5[0].price : 0);
            }
        }

    })
    
    _eslist.map((item) => {
        _fprice = _fprice + (item.item.price);
    })

    if(_type != 0){
        _fprice = _fprice + _fprice*0.02; 
    }
 

    return _fprice * _people - ((_sales / 100) * _fprice * _people);
}

