import React, { Component } from 'react';

import addIcon from '../../../assets/imgs/ui/plus.svg';
import Env from '../../../services/Env';
import UiBtnGreenOutline from '../../../components/ui/buttons/UiBtnGreenOutline.js';

class UiDinnerCard extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <div className="dinner-card-wrap">
        {this.props.empty ?
          <button className="dinner-card" onClick={this.props.onClick }>
            <img src={addIcon} className="dinner-card-add" />
          </button>
          :
          <div className="plan-card">
            {this.props.addItem ?
              <div className="plan-card-add">
                <button className="add-button">
                  <img src={addIcon} />
                  <h3>{global.locale.dinner_сard_add} {this.props.lanchType}</h3>
                </button>
              </div>
              :
              <div className="plan-card-wrap">
                <div className="plan-card-img" style={{ backgroundImage: "url(" + Env.PUBLIC_URL + this.props.image.uri + ")" }}>
                </div>
                <div className="plan-card-info">
                  {this.props.modalAddCard ?
                    null :
                    <div className="info-btns">
                      <div className="btn-bar">
                        <button className="remove-btn" onClick={() => this.props.onClickRemove() }>{global.locale.dinner_сard_btn_delete}</button>
                        {/*<button className="change-btn">{global.locale.dinner_сard_btn_swap}</button> */}
                        <button className="change-btn" onClick={() => this.props.onClickClone() }>{global.locale.dinner_сard_btn_to_all} {this.props.day}</button>
                      </div>
                    </div>
                  }
                  <div className="plan-card-title-wrap">
                    <h3>{this.props.title}</h3>
                    <p className="info-comp">{global.locale.dinner_сard_composition}: {this.props.ingredient}</p>
                  </div>
                <p className="info-about">{this.props.about} {this.props.price ? this.props.price+" "+ global.locale.plans_currency : ""}</p>
                  <div className="info-status">
                    <div>
                      <span>{global.locale.dinner_сard_in_100_g}: </span><strong>{this.props.ccal} {global.locale.dinner_сard_kCal} | {global.locale.dinner_сard_B}:{this.props.protein}, {global.locale.dinner_сard_J}:{this.props.fats}, {global.locale.dinner_сard_U}:{this.props.carbo} </strong>
                    </div>
                  </div>
                  {this.props.modalAddCard ?
                    <div className="card-add-price">
                      <UiBtnGreenOutline btnText="Выбрать" btnsm black />
                      <span>130 {global.locale.dinner_сard_currency}</span>
                    </div>
                    : null
                  }
                </div>
              </div>
            }
          </div>
        }
      </div>
    );
  }
}
export default UiDinnerCard;