import ENV from './Env.js';


export const searchOrders = (_token, _line) => {

  return fetch(ENV.API_URL + '/users/orders/search', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _token,
      line: _line
    })
  }).then(function (response) {
    return response.json();
  });
}


export const getAllOrders = (_token) => {

  return fetch(ENV.API_URL + '/orders/all', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _token
    })
  }).then(function (response) {
    return response.json();
  });
}

export const getAllOrdersFromDate = (_token, _date) => {

  return fetch(ENV.API_URL + '/orders/all/date', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _token,
      date: _date
    })
  }).then(function (response) {
    return response.json();
  });
}


export const getDishes = (_token, _date) => {

  return fetch(ENV.API_URL + '/orders/dishes/list', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _token,
      date: _date
    })
  }).then(function (response) {
    return response.json();
  });
}

export const sendKitchen = (_data) => {
  return fetch(ENV.API_URL + '/orders/dishes/sendapi', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data: _data })
  }).then(function (response) {
    return response.json();
  });
}

export const editOrder = (
  _token, _id, _start, _end, _size, _calories, _program_size, _price,
  _priced, _pricee, _pricep, _exclude
  ) => {

  return fetch(ENV.API_URL + '/orders/edit', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _token,
      id: _id,
      start: _start,
      end: _end,
      size: _size,
      calories: _calories,
      program_size: _program_size,
      price: _price,
      price_delivery:_priced,
      price_exclude: _pricee,
      price_promocode: _pricep,
      exclude: _exclude ? _exclude : "-"
    })
  }).then(function (response) {
    return response.json();
  });
}

export const editUserComment = (_token, _id, _exclude, _comment) => {

  return fetch(ENV.API_URL + '/orders/edit', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _token,
      id: _id,
      exclude: _exclude,
      comment: _comment,
 
    })
  }).then(function (response) {
    return response.json();
  });
}

