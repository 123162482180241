
import ENV from './Env.js';



export const addFile = (file,  _type) => {

 
  let formData = new FormData();
  formData.append('type', _type);
  formData.append('file',file);

  return fetch(ENV.API_URL + '/files/add', {
  method: 'POST',
  
  body: formData
  }).then(function (response) {
  return response.json();
  });
}

export const getAllDishes = (_token) => {

  return fetch(ENV.API_URL + '/dinners/dishes/all', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token
  })
  }).then(function (response) {
  return response.json();
  });
}

export const updateDishes = (_token, _data) => {

  return fetch(ENV.API_URL + '/dinners/dishes/update', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token,
    data: _data
  })
  }).then(function (response) {
  return response.json();
  });
}

export const removeDishes = (_token, _data) => {

  return fetch(ENV.API_URL + '/dinners/dishes/remove', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token,
    id: _data
  })
  }).then(function (response) {
  return response.json();
  });
}


export const addDishes = (_token, _data) => {

  return fetch(ENV.API_URL + '/dinners/dishes/add', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token,
    data: _data
  })
  }).then(function (response) {
  return response.json();
  });
}

export const addDay = (_token, _data) => {
  return fetch(ENV.API_URL + '/dinners/day/add/full', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token,
    data: _data
  })
  }).then(function (response) {
  return response.json();
  });
}

export const remDay = (_token, _id) => {
return fetch(ENV.API_URL + '/dinners/day/remove', {
  method: 'POST',
  headers: {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  },
  body: JSON.stringify({
  api_token: _token,
  id: _id
  })
}).then(function (response) {
  return response.json();
});
}


export const cloneDay = (_token, _id, _start, _end) => {
  return fetch(ENV.API_URL + '/dinners/day/clone', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token,
    calories_id: _id,
    start: _start,
    end: _end
  })
  }).then(function (response) {
  return response.json();
  });
}

export const cloneDayOfWeek = (_token, _arr, _id, _type) => {
  return fetch(ENV.API_URL + '/dinners/day/clonedinner', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token,
    arr: _arr,
    id: _id,
    type: _type
  })
  }).then(function (response) {
  return response.json();
  });
}

export const addCalories = (_token, _data) => {
  return fetch(ENV.API_URL + '/plans/calories/add', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token,
    data: _data
  })
  }).then(function (response) {
  return response.json();
  });
}

export const remCalories = (_token, _data) => {
  return fetch(ENV.API_URL + '/plans/calories/remove', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token,
    id: _data
  })
  }).then(function (response) {
  return response.json();
  });
}



export const removeItemFromDay = (_token, _id, _type) => {
  return fetch(ENV.API_URL + '/dinners/day/remove/item', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token,
    id: _id,
    type: _type
  })
  }).then(function (response) {
  return response.json();
  });
}

export const addItemDay = (_token, _id, _did , _type ) => {
  return fetch(ENV.API_URL + '/dinners/day/add/item', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token,
    id: _id,
    did: _did,
    type: _type
  })
  }).then(function (response) {
  return response.json();
  });
}

export const updatePlan = (_token, _data) => {
  return fetch(ENV.API_URL + '/plans/update', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token,
    data: _data
  })
  }).then(function (response) {
  return response.json();
  });
}


export const updateCalories = (_token, _data) => {
  return fetch(ENV.API_URL + '/plans/calories/update', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token,
    data: _data
  })
  }).then(function (response) {
  return response.json();
  });
}

export const addPlan = (_token, _data) => {
  return fetch(ENV.API_URL + '/plans/add', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token,
    title: _data
  })
  }).then(function (response) {
  return response.json();
  });
}

export const removePlan = (_token, _id) => {
  return fetch(ENV.API_URL + '/plans/remove', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    api_token: _token,
    id: _id
  })
  }).then(function (response) {
  return response.json();
  });
}


export const getAllPlans = () => {
  return fetch(ENV.API_URL + '/plans/get/all', {
  method: 'GET'
  }).then((response) => response.json());
}

export const getDaysByCalories = (_id, _date) => {
  console.log(ENV.API_URL + '/dinners/day/get/calories/' + _id + '/' + _date)
  return fetch(ENV.API_URL + '/dinners/day/get/calories/' + _id + '/' + _date, {
  method: 'GET'
  }).then((response) => response.json());
}

export const getAdditionalDinner = (_id, _date) => {
  return fetch(ENV.API_URL + '/dinners/day/get/additional/' + _id, {
    method: 'GET'
  }).then((response) => response.json());
}

export const getEShop = () => {
  return fetch(ENV.API_URL + '/dinners/dishes/eshop/all', {
    method: 'GET'
  }).then((response) => response.json());
}



