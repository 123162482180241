import React, { Component } from 'react';
import ReactWOW from 'react-wow';

import placeIcon from '../../../assets/imgs/ui/header/pin.svg';
import downIcon from '../../../assets/imgs/ui/select-arrow.svg';
import phoneIcon from '../../../assets/imgs/ui/header/call.svg';
import maleIcon from '../../../assets/imgs/ui/header/email.svg';
import menuIcon from '../../../assets/imgs/ui/header/menu.svg';
import userIcon from '../../../assets/imgs/ui/header/user.svg';
import userIconWhite from '../../../assets/imgs/ui/header/user-white.svg';
import basketIcon from '../../../assets/imgs/ui/header/online-shopping.svg';
import basketIconWhite from '../../../assets/imgs/ui/header/online-shopping-white.svg';
import UiBtnGreen from '../buttons/UiBtnGreen';

import { retrieveData } from '../../../services/Storage';
import { loadUser } from '../../../services/Users';

class UiHeader extends Component {

  state = {
    userType: 0,
    isCurier: false,
    activeItem: 0,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let _user = loadUser();
    if (_user) {
      this.setState({ userType: _user.type })
      if (_user.type == 2) this.setState({ isCurier: true })
    }

    let _lang = retrieveData("ikLocale");
    if (_lang == null) _lang = 'ch';
    if (_lang == 'ch') this.setState({ active: 0 });
    if (_lang == 'ru') this.setState({ active: 1 });
    if (_lang == 'en') this.setState({ active: 2 });
  }

  componentDidUpdate(prevProps, prevState) { }

  _scrollToTargetAdjusted(target) {
    var element = document.getElementById(target);
    var headerOffset = 130;
    if (window.innerWidth < 770) headerOffset = 350;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  render() {
    return (
      <header>
        <div className="header-wrap" id="header">
          <div className="header-full">
            <div className="menu-wrap">
              <div className="container">
                <div className="menu">
                  <div className="logo">
                    <div className="logo-text">
                      {this.props.isLogin ?
                        <a href="/login">
                          <img src={require('../../../assets/imgs/logo.svg')} />
                        </a>
                        :
                        <a href="/home">
                          <img src={require('../../../assets/imgs/logo.svg')} />
                        </a>
                      }

                    </div>
                  </div>
                  {!this.props.menuHidden ?
                    <ul className="menu-list">
                      {!this.state.isCurier ? <li className="menu-item"><a href="/home">{global.locale.head_plans}</a></li> : null}
                      {!this.state.isCurier ? <li className="menu-item"><a href="/foods">{global.locale.head_dishes}</a></li> : null}
                      {!this.state.isCurier ? <li className="menu-item"><a href="/orders">{global.locale.head_list_orders}</a></li> : null}
                      {this.state.userType == 0 || this.state.userType == 1 ? <li className="menu-item"><a href="/courier">{global.locale.head_my_orders}</a></li> : null}
                      <li className="menu-item"><a href="/users">{global.locale.head_list_clients}</a></li>
                    </ul>
                    :
                    null
                  }
                  <div className="check-btns">
                    <button onClick={() => this.setState({ active: 0 }, () => { if (this.props.langCallBack) this.props.langCallBack(1) })} className={"check-btn" + (this.state.active == "0" ? " active" : "")}>Čeština</button>
                    <button onClick={() => this.setState({ active: 1 }, () => { if (this.props.langCallBack) this.props.langCallBack(0) })} className={"check-btn" + (this.state.active == "1" ? " active" : "")}>Русский</button>
                    <button onClick={() => this.setState({ active: 2 }, () => { if (this.props.langCallBack) this.props.langCallBack(2) })} className={"check-btn" + (this.state.active == "2" ? " active" : "")}>English</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-mobile">
            <div className="header-left">
              <div className="logo">
                <a href="/">
                  <div className="logo-text">
                    <img className="img" src={require('../../../assets/imgs/logo-img.svg')} />
                    <img src={require('../../../assets/imgs/logo-white.svg')} />
                  </div>
                </a>
              </div>
            </div>
            <div className="header-right" onClick={() => this.setState({ rightMenuOpen: !this.state.rightMenuOpen })}>
              <button className="menu-btn">
                <img className="basket-icon" src={menuIcon} />
              </button>
            </div>

            <div className={"menu-mobile-wrap" + (this.state.rightMenuOpen ? " active" : "")} style={{ height: this.props.menuHeight }} style={{ height: window.innerHeight }} >

              <div className="profile-menu">

                {!this.state.isCurier ? <a href="/home" onClick={() => this.setState({ activeItem: 0 })} className={"menu-item" + (this.state.activeItem == 0 ? " active" : "")}>
                  <h4>{global.locale.head_plans}</h4>
                </a> : null}

                {!this.state.isCurier ? <a href="/foods" onClick={() => this.setState({ activeItem: 1 })} className={"menu-item" + (this.state.activeItem == 1 ? " active" : "")}>
                  <h4>{global.locale.head_dishes}</h4>
                </a> : null}

                {!this.state.isCurier ? <a href="/orders" onClick={() => this.setState({ activeItem: 2 })} className={"menu-item" + (this.state.activeItem == 2 ? " active" : "")}>
                  <h4>{global.locale.head_list_orders}</h4>
                </a> : null}
                {this.state.userType == 0 || this.state.userType == 1 ?
                  <a href="/courier" onClick={() => this.setState({ activeItem: 3 })} className={"menu-item" + (this.state.activeItem == 3 ? " active" : "")}>
                    <h4>{global.locale.head_my_orders}</h4>
                  </a> : null}

                <a href="/users" onClick={() => this.setState({ activeItem: 4 })} className={"menu-item" + (this.state.activeItem == 4 ? " active" : "")}>
                  <h4>{global.locale.head_list_clients}</h4>
                </a>



              </div>

            </div>


          </div>
        </div>
      </header>
    );
  }
}
export default UiHeader;